import { create } from 'zustand'

export const useFiles = create((set, get) => {
    return {
        //

        aceeptedFiles: [],

        diamondRings: []

        //
    }
})

export const cacheURL = new Map()

// function getFilesFor90450R() {
//     function requireAll(r) {
//         let files = []
//         r.keys().forEach((key) => {
//             let data = r(key)

//             if (!files.some((r) => r.uuid === data.uuid)) {
//                 files.push(data)
//             }
//         })

//         files = files.slice().sort((a, b) => {
//             if (b.ts > a.ts) {
//                 return 1
//             } else if (b.ts < a.ts) {
//                 return -1
//             }
//             return 0
//         })

//         return files
//     }

//     let files = requireAll(require.context('../../public/diamonds/FancyCut-90450R', true, /meta\.json$/))

//     console.log(files)
//     return files
// }
