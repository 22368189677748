import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
    OrbitControls,
    PerspectiveCamera,
    Sphere,
    useGLTF,
    useTexture,
    RandomizedLight,
    AccumulativeShadows,
    Environment,
    SoftShadows,
    ContactShadows,
    SpotLight,
    Shadow,
    Caustics,
    Box,
    Decal,
    RenderTexture,
    Text,
    useFBO
} from '@react-three/drei'
// import { RefractionMaterial } from './RefractionMaterial'
// import { useTweaks, makeButton } from 'use-tweaks'
import {
    Bloom,
    brightnessContrast,
    DepthOfField,
    EffectComposer,
    HueSaturation,
    SSR,
    SelectiveBloom,
    Vignette,
    BrightnessContrast,
    SSAO
} from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import {
    CanvasTexture,
    EquirectangularReflectionMapping,
    Euler,
    HalfFloatType,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    PlaneGeometry,
    Quaternion,
    RepeatWrapping,
    SRGBColorSpace,
    UVMapping,
    Vector3
} from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'
// import { DiamondMaterialCustomKey, RefractionMaterialCustom } from './RefractionMaterialCustom'
import { create } from 'zustand'
import { DiamondMaterialCustomCircleKey, RefractionMaterialCustomCircle } from './RefractionMaterialCustomCircle'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { TextureLoader, sRGBEncoding, PerspectiveCamera as PerspectiveCamera3 } from 'three'
import { Leva, useControls } from 'leva'
import { useTweaks } from 'use-tweaks'
import Pane from 'tweakpane'
import copyToClipboard from 'copy-to-clipboard'
import { clone } from 'three/examples/jsm/utils/SkeletonUtils.js'
// import { MeshBasicMaterial } from 'three140'
import { unzip, setOptions } from 'unzipit'

let presets = [
    {
        id: 'gold',
        name: 'Gold',
        data: {
            // 2023-08-17
            ...{
                textColor: '#8c6e5e',
                textContent: '樂樂 LokLok',
                textFont: `Arial`,
                textSize: 100.0,
                metalRotY: 0,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/studio18b.png',
                metalMatCapURL: '/matcap2k/rose-gold@2x.png',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#fad26b',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 2,
                brightness: 0.05,
                contrast: 1.2,
                saturation: 1,
                hue: 0
            }
        }
    },
    {
        id: 'red',
        name: 'Red Gold',
        data: {
            // 2023-08-17
            ...{
                textColor: '#8c6e5e',
                textContent: '樂樂 LokLok',
                textFont: `Arial`,
                textSize: 100.0,
                metalRotY: 0,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/studio18b.png',
                metalMatCapURL: '/matcap2k/rose-gold@2x.png',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#f8b188',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 2,
                brightness: 0.05,
                contrast: 1.2,
                saturation: 1,
                hue: 0
            }
        }
    },
    {
        id: 'white',
        name: 'White Gold',
        data: {
            ...{
                textColor: '#8c8c8c',
                textContent: '樂樂 LokLok',
                textFont: `Arial`,
                textSize: 100.0,
                metalRotY: 3.5,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/hdri_29.jpg',
                metalMatCapURL: '/Hdri_reflection/hdri-active.jpeg',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#ffffff',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 1.47,
                brightness: 0,
                contrast: 1,
                saturation: 1,
                hue: 0
            }
        }
    }
]

/*
 */

let rings = [
    // { id: `rr1`, name: `94010R(#13-1.00ct)`, url: `/s100/94010R(id13-1.00ct)s100.obj` },
    // { id: `rr1`, name: `94010R(#11-0.50ct)`, url: `/s100/94010R(id11-0.50ct)s100.obj` },
    // { id: `rr1`, name: `94010R(#15-0.35ct)`, url: `/s100/94010R(id15-0.35ct)s100.obj` },
    // { id: `rr1`, name: `94010R(#17-0.30ct)`, url: `/s100/94010R(id17-0.30ct)s100.obj` },

    //
    // { id: 'fafa1', hh: `#11`, ct: `1.00ct`, url: `/2023-08-22-alex4/94010R(h11 0.50ct)v4.glb` },
    // { id: 'fafa3', hh: `#11`, ct: `0.50ct`, url: `/2023-08-22-alex4/94010R(h11 0.50ct)v1.glb` },
    // { id: 'fafa2', hh: `#11`, ct: `0.35ct`, url: `/2023-08-22-alex4/94010R(h11 0.50ct)v2.glb` },
    // { id: 'fafa4', hh: `#11`, ct: `0.30ct`, url: `/2023-08-22-alex4/94010R(h11 0.50ct)v3.glb` },
    // //
    // { id: 'fafa5', hh: `#13`, ct: `1.00ct`, url: `/2023-08-22-alex4/94010R(h13 1.00ct)v1.glb` },
    // { id: 'fafa6', hh: `#13`, ct: `0.50ct`, url: `/2023-08-22-alex4/94010R(h13 1.00ct)v4.glb` },
    // { id: 'fafa7', hh: `#13`, ct: `0.30ct`, url: `/2023-08-22-alex4/94010R(h13 1.00ct)v2.glb` },
    // { id: 'fafa8', hh: `#13`, ct: `0.35ct`, url: `/2023-08-22-alex4/94010R(h13 1.00ct)v3.glb` },
    // //
    // { id: 'fafa9', hh: `#15`, ct: `1.00ct`, url: `/2023-08-22-alex4/94010R(h15 0.35ct)v3.glb` },
    // { id: 'fafa10', hh: `#15`, ct: `0.50ct`, url: `/2023-08-22-alex4/94010R(h15 0.35ct)v4.glb` },
    // { id: 'fafa11', hh: `#15`, ct: `0.35ct`, url: `/2023-08-22-alex4/94010R(h15 0.35ct)v1.glb` },
    // { id: 'fafa12', hh: `#15`, ct: `0.30ct`, url: `/2023-08-22-alex4/94010R(h15 0.35ct)v2.glb` },
    // //
    // { id: 'fafa13', hh: `#17`, ct: `0.30ct`, url: `/2023-08-22-alex4/94010R(h17 0.30ct)v1.glb` },
    // { id: 'fafa14', hh: `#17`, ct: `0.35ct`, url: `/2023-08-22-alex4/94010R(h17 0.30ct)v2.glb` },
    // { id: 'fafa15', hh: `#17`, ct: `0.50ct`, url: `/2023-08-22-alex4/94010R(h17 0.30ct)v4.glb` },
    // { id: 'fafa16', hh: `#17`, ct: `1.00ct`, url: `/2023-08-22-alex4/94010R(h17 0.30ct)v3.glb` },

    //

    // { id: `gl`, hh: `#11`, ct: `1.00ct`, url: `/2023-08-23-cena/dia-h11-ct-100.glb` },
    // { id: 'fa1-z', hh: `#11`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h11 1ct.zip` },
    { id: 'fa1', hh: `#11`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h11 1ct.zip` },
    { id: 'fa2', hh: `#11`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h11 050ct.zip` },
    { id: 'fa3', hh: `#11`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h11 035ct.zip` },
    { id: 'fa4', hh: `#11`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h11 030ct.zip` },

    { id: 'fa5', hh: `#13`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h13 1ct.zip` },
    { id: 'fa6', hh: `#13`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h13 050ct.zip` },
    { id: 'fa7', hh: `#13`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h13 035ct.zip` },
    { id: 'fa8', hh: `#13`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h13 030ct.zip` },

    { id: 'fa9', hh: `#15`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h15 1ct.zip` },
    { id: 'fa10', hh: `#15`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h15 050ct.zip` },
    { id: 'fa11', hh: `#15`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h15 035ct.zip` },
    { id: 'fa12', hh: `#15`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h15 030ct.zip` },

    { id: 'fa13', hh: `#17`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h17 1ct.zip` },
    { id: 'fa14', hh: `#17`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h17 050ct.zip` },
    { id: 'fa15', hh: `#17`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h17 035ct.zip` },
    { id: 'fa16', hh: `#17`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h17 030ct.zip` }

    // { id: 'fafa', s100: true, name: `(#11 0.50ct)v1`, url: `/2023-08-22-alex/94010R(h11-0.50ct)v1.glb` },
    // { id: 'fafa', s100: true, name: `(#11 0.50ct)v2`, url: `/2023-08-22-alex/94010R(h11-0.50ct)v2.glb` },
    // { id: 'fafa', s100: true, name: `(#11 0.50ct)v3`, url: `/2023-08-22-alex/94010R(h11-0.50ct)v3.glb` },
    // { id: 'fafa', s100: true, name: `(#11 0.50ct)v4`, url: `/2023-08-22-alex/94010R(h11-0.50ct)v4.glb` },
    // { id: 'fafa', s100: true, name: `(#13 1.00ct)v1`, url: `/2023-08-22-alex/94010R(h13-1.00ct)v1.glb` },
    // { id: 'fafa', s100: true, name: `(#13 1.00ct)v2`, url: `/2023-08-22-alex/94010R(h13-1.00ct)v2.glb` },
    // { id: 'fafa', s100: true, name: `(#13 1.00ct)v3`, url: `/2023-08-22-alex/94010R(h13-1.00ct)v3.glb` },
    // { id: 'fafa', s100: true, name: `(#13 1.00ct)v4`, url: `/2023-08-22-alex/94010R(h13-1.00ct)v4.glb` },
    // { id: 'fafa', s100: true, name: `(#15 0.35ct)v1`, url: `/2023-08-22-alex/94010R(h15-0.35ct)v1.glb` },
    // { id: 'fafa', s100: true, name: `(#15 0.35ct)v2`, url: `/2023-08-22-alex/94010R(h15-0.35ct)v2.glb` },
    // { id: 'fafa', s100: true, name: `(#15 0.35ct)v3`, url: `/2023-08-22-alex/94010R(h15-0.35ct)v3.glb` },
    // { id: 'fafa', s100: true, name: `(#15 0.35ct)v4`, url: `/2023-08-22-alex/94010R(h15-0.35ct)v4.glb` },
    // { id: 'fafa', s100: true, name: `(#17 0.30ct)v1`, url: `/2023-08-22-alex/94010R(h17-0.30ct)v1.glb` },
    // { id: 'fafa', s100: true, name: `(#17 0.30ct)v2`, url: `/2023-08-22-alex/94010R(h17-0.30ct)v2.glb` },
    // { id: 'fafa', s100: true, name: `(#17 0.30ct)v3`, url: `/2023-08-22-alex/94010R(h17-0.30ct)v3.glb` },
    // { id: 'fafa', s100: true, name: `(#17 0.30ct)v4`, url: `/2023-08-22-alex/94010R(h17-0.30ct)v4.glb` }
    //
    // { id: `r2`, url: `/glb100/94010R(id13-1.00ct)s100.glb`, name: `94010R(#13-1.00ct)` },
    // { id: `r1`, url: `/glb100/94010R(id11-0.50ct)s100.glb`, name: `94010R(#11-0.50ct)` },
    // { id: `r3`, url: `/glb100/94010R(id15-0.35ct)s100.glb`, name: `94010R(#15-0.35ct)` },
    // { id: `r4`, url: `/glb100/94010R(id17-0.30ct)s100.glb`, name: `94010R(#17-0.30ct)` }
]

/*
obj (unoptimised) average file size = 59MB / 16  = 3.87MB avg per ring
obj (optimised ziplib) average file size = 12MB / 16 = 0.75 MB avg per ring
*/

let useRender = create((set, get) => {
    return {
        //
        ct: rings[0].ct,
        hh: rings[0].hh,
        ringURL: `${rings[0].url}`,

        lights: [],
        meshes: [],

        // ///!SECTION
        // metalRotY: 0,

        // metalEnvMapIntensity: 1.79,
        // metalHDRURL: `/Hdri_reflection/studio018.hdr`,
        // metalMatCapURL: `/Hdri_reflection/studio018.hdr`,
        // metalHdrMatCapRatio: 0.5,
        // metalTint: '#ffda89',
        // metalRoughness: 0.1,

        // diamondHDRURL: '/brown_photostudio_07-bw.exr',
        // diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',

        // diamondEnvMapIntensity: 1.0,
        // diamondTint: '#ffffff',
        // diamondLighntess: 3.75,
        // diamondRainbow: 0.0043478261,
        // diamondContrast: 3.3152173913,

        // smoothGlow: true,
        // glowDetect: 0.95,
        // intensity: 1.47,

        // // ringURL: `/ring100/1847467(2023-8-11)-100x.obj`,

        // //
        // //
        // ...{
        //   metalRotY: 0,

        //   metalEnvMapIntensity: 3,
        //   metalHDRURL: '/Hdri_reflection/studio18b.png',
        //   metalMatCapURL: '/matcap2k/rose-gold@2x.png',
        //   metalHdrMatCapRatio: 0.8,
        //   metalTint: '#f8b188',
        //   metalRoughness: 0.1,
        //   diamondHDRURL: '/brown_photostudio_07-bw.exr',
        //   diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
        //   diamondEnvMapIntensity: 1.2,
        //   diamondTint: '#ffffff',
        //   diamondLighntess: 3.75,
        //   diamondRainbow: 0.0043478261,
        //   diamondContrast: 3,
        //   smoothGlow: true,
        //   glowDetect: 0.95,
        //   intensity: 1.47,

        //   brightness: 0,
        //   saturation: 0,
        //   contrast: 0,
        //   hue: 0,

        //   ...presets[1].data
        // },

        ...{
            ...presets[1].data
        },
        matID: presets[1].id
    }
})

/**
   lightness: { value: 3.75, min: 0, max: 5 },
    rainbow: { value: 0.0043478261, min: 0, max: 0.1 },
    contrast: { value: 3.3152173913, min: 0, max: 10 }
*/

export function Engraving() {
    let desktop = {
        mobile: false,
        parent: { height: `100%`, width: `calc(100%)`, display: 'flex' },
        left: { height: `100%`, width: `calc(100% - 300px)` },
        right: { height: `100%`, width: `calc(300px)` }
    }
    let [config, setConfig] = useState(desktop)

    useEffect(() => {
        let resizer = () => {
            if (window.innerWidth <= 500) {
                setConfig({
                    mobile: true,
                    parent: { height: `100%`, width: `calc(100%)` },
                    left: { height: `60%`, width: `calc(100%)` },
                    right: { height: `40%`, width: `calc(100%)` }
                })
            } else {
                setConfig(desktop)
            }
        }
        resizer()
    }, [])

    //
    return (
        <>
            <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />

            <div style={config.parent}>
                <Canvas gl={{ antialias: false, useLegacyLights: true }} shadows style={config.left}>
                    <RenderContent></RenderContent>
                </Canvas>

                <style
                    dangerouslySetInnerHTML={{
                        __html: `
        @font-face {
          font-family: AlexBrush;
          src: url('/font/alex-brush/AlexBrush-Regular.ttf');
        }

        @font-face {
          font-family: Edwardian;
          src: url('/font/itc-font/EdwardianScriptITC.ttf');
        }

        #preloadFont {
          font-family: Edwardian;
        }
        
        
        `
                    }}></style>

                <div id="preloadFont" style={{ display: 'none' }}>
                    YOLO
                </div>

                <div style={config.right} className=" overflow-scroll">
                    <div className="w-full p-2 bg-gray-100 h-full ">
                        {!config.mobile && (
                            <>
                                <div
                                    onClick={() => {
                                        //

                                        let dat = {
                                            ...useRender.getState()
                                        }

                                        for (let key in dat) {
                                            if (typeof dat[key] === 'object' && dat[key] !== null) {
                                                delete dat[key]
                                            }
                                        }

                                        copyToClipboard(JSON.stringify(dat, null, 2))
                                    }}
                                    className="text-center cursor-pointer underline mb-3 p-2 bg-blue-500 text-white">
                                    Copy Settings To Clipboard
                                </div>
                            </>
                        )}
                        {/*
            <div className="">Ring</div>
            <div className="">
              <RingConf></RingConf>
            </div> */}

                        <div className="">Ring Metal</div>
                        <SimpleMetalConf></SimpleMetalConf>
                        {/* {config.mobile && (
              <>

              </>
            )} */}

                        <div className="">Ring Dimensions</div>
                        <div className="">
                            <SimpleRingConf></SimpleRingConf>
                        </div>

                        <div className="">Engraving</div>
                        <div className="">
                            <SimpleEngraving></SimpleEngraving>
                        </div>
                        {!config.mobile && (
                            <>
                                <div className="">Environment</div>
                                <div className="">
                                    <EnvironmentConf></EnvironmentConf>
                                </div>

                                <div className="">Metal</div>
                                <div className="">
                                    <MetalConf></MetalConf>
                                </div>

                                <div className="">Diamond</div>
                                <div className="">
                                    <DiamondConf></DiamondConf>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function RenderContent() {
    // let s100 = useRender((r) => r.s100) || false
    let ringURL = useRender((r) => r.ringURL) || ''
    let r = '_' + (Math.random() * 10000000).toFixed(0)

    return (
        <>
            {/*  */}
            <Suspense fallback={null}>
                <group rotation={[Math.PI * 0.0, 0, 0]} castShadow>
                    <RingLoader key={r + ringURL}></RingLoader>
                </group>
                {/* <Caustics intensity={1} lightSource={[0, 5, 5]}>
        </Caustics> */}
            </Suspense>

            <EnvironmentRunner></EnvironmentRunner>
            <MetalEnv></MetalEnv>
            <DiamondEnv></DiamondEnv>
            <PP></PP>

            <PerspectiveCamera near={0.05} far={100} makeDefault></PerspectiveCamera>
            {/* <OrbitControls minDistance={4} object-position={[-3, 3, 3]} target={[0, 1.1, 0]}></OrbitControls> */}
            <OrbitControls minDistance={0.5} object-position={[-3, 3, 3]} target={[0, 1.1, 0]}></OrbitControls>
        </>
    )
}
function DiamondEnv() {
    let [matcap, setMatCap] = useState(null)
    let [hdr, setHDR] = useState(null)

    let diamondMatCapURL = useRender((r) => r.diamondMatCapURL)
    useEffect(() => {
        dynamicLoad(diamondMatCapURL, UVMapping).then((texure) => {
            setMatCap(texure)
        })
    }, [diamondMatCapURL])

    let diamondHDRURL = useRender((r) => r.diamondHDRURL)
    useEffect(() => {
        dynamicLoad(diamondHDRURL, EquirectangularReflectionMapping).then((texure) => {
            setHDR(texure)
        })
    }, [diamondHDRURL])

    let diamondLighntess = useRender((r) => r.diamondLighntess)
    let diamondRainbow = useRender((r) => r.diamondRainbow)
    let diamondContrast = useRender((r) => r.diamondContrast)

    let diamondEnvMapIntensity = useRender((r) => r.diamondEnvMapIntensity)
    let { envMap: computedRainbowEnv } = useComputeEnvMap(
        `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  // float pattern (vec2 p, float time) {
  //   float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
  //   return abs(vout);
  // }


  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm4(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;
  uniform sampler2D matcap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }

  uniform float diamondEnvMapIntensity;
  vec4 mainImage ()  {


    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, uvMC );

    vec4 hdrV4 = matcapColor;// texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 3.3, 0.1), 2.0);

    outColor.rgb = vec3(
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 + ${diamondRainbow.toFixed(3)}, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 - ${diamondRainbow.toFixed(3)}, time * 0.05)
    );

    outColor.r = pow(outColor.r * 0.4, ${diamondContrast.toFixed(3)});
    outColor.g = pow(outColor.g * 0.4, ${diamondContrast.toFixed(3)});
    outColor.b = pow(outColor.b * 0.4, ${diamondContrast.toFixed(3)});

    outColor.rgb = mix(hdrV4.rgb , outColor.rgb, 0.3);
    outColor.rgb  = czm_saturation(outColor.rgb, 0.1);

    return outColor * diamondEnvMapIntensity;
  }

  `,
        {
            time: { value: 0 },
            matcap: { value: matcap },
            hdrTexture: { value: hdr },
            diamondEnvMapIntensity: { value: diamondEnvMapIntensity }
        },
        128,
        true
    )

    useEffect(() => {
        if (computedRainbowEnv) {
            useRender.setState({ computedRainbowEnv: computedRainbowEnv })
        }
    }, [computedRainbowEnv])

    return null
}

function MetalEnv() {
    let [matcap, setMatCap] = useState(null)
    let [hdr, setHDR] = useState(null)

    let metalMatCapURL = useRender((r) => r.metalMatCapURL)
    useEffect(() => {
        dynamicLoad(metalMatCapURL, UVMapping).then((texure) => {
            setMatCap(texure)
        })
    }, [metalMatCapURL])

    let metalHDRURL = useRender((r) => r.metalHDRURL)
    useEffect(() => {
        dynamicLoad(metalHDRURL, EquirectangularReflectionMapping).then((texure) => {
            setHDR(texure)
        })
    }, [metalHDRURL])

    let metalRotY = useRender((r) => r.metalRotY)
    let metalHdrMatCapRatio = useRender((r) => r.metalHdrMatCapRatio)

    let brightness = useRender((r) => r.brightness)
    let contrast = useRender((r) => r.contrast)
    let saturation = useRender((r) => r.saturation)

    let { envMap: computedMetalEnv } = useComputeEnvMap(
        `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;
  uniform float brightness;
  uniform float contrast;
  uniform float saturation;

  uniform sampler2D matcap;

  uniform sampler2D metalEnvMap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }
  mat4 brightnessMatrix( float brightness )
  {
      return mat4( 1, 0, 0, 0,
                  0, 1, 0, 0,
                  0, 0, 1, 0,
                  brightness, brightness, brightness, 1 );
  }

  mat4 contrastMatrix( float contrast )
  {
    float t = ( 1.0 - contrast ) / 2.0;

      return mat4( contrast, 0, 0, 0,
                  0, contrast, 0, 0,
                  0, 0, contrast, 0,
                  t, t, t, 1 );

  }

  mat4 saturationMatrix( float saturation )
  {
      vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );

      float oneMinusSat = 1.0 - saturation;

      vec3 red = vec3( luminance.x * oneMinusSat );
      red+= vec3( saturation, 0, 0 );

      vec3 green = vec3( luminance.y * oneMinusSat );
      green += vec3( 0, saturation, 0 );

      vec3 blue = vec3( luminance.z * oneMinusSat );
      blue += vec3( 0, 0, saturation );

      return mat4( red,     0,
                  green,   0,
                  blue,    0,
                  0, 0, 0, 1 );
  }

  uniform float metalHdrMatCapRatio;
  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }
  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;


    vec4 outColor;
    outColor.a = 1.0;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, vec2(uvMC.x, uvMC.y) );
    // vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2( uvMC.y, 1.0 - uvMC.x));
    vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2(uv));


    outColor.rgb = mix(matcapColor.rgb, hdrTextureC4.rgb, metalHdrMatCapRatio);

    outColor = brightnessMatrix( brightness ) *
        contrastMatrix( contrast ) *
        saturationMatrix( saturation ) *
        outColor;

    return outColor;
  }

  `,
        {
            metalHdrMatCapRatio: { value: metalHdrMatCapRatio },
            time: { value: 0 },
            matcap: { value: matcap },
            metalEnvMap: { value: hdr },
            envLightIntensity: { value: 1 },
            rotY: { value: metalRotY },
            brightness: { value: brightness },
            contrast: { value: contrast },
            saturation: { value: saturation }
        },
        128,
        true
    )
    useEffect(() => {
        useRender.setState({ computedMetalEnv: computedMetalEnv })
    }, [computedMetalEnv])

    return null
}

let dynamicLoad = async (texURL, mapping = EquirectangularReflectionMapping) => {
    if (!texURL) {
        return
    }
    if (texURL.includes('.hdr')) {
        let hdr = new RGBELoader()
        let texture = await hdr.loadAsync(texURL)
        texture.mapping = mapping
        texture.flipY = true

        return texture
    } else if (texURL.includes('.exr')) {
        let exr = new EXRLoader()
        let texture = await exr.loadAsync(texURL)
        texture.mapping = mapping
        texture.flipY = true

        return texture
    } else {
        let load = new TextureLoader()
        let texture = await load.loadAsync(texURL)
        texture.encoding = sRGBEncoding
        texture.colorSpace = SRGBColorSpace
        texture.mapping = mapping
        texture.flipY = true

        return texture
    }
}

function EnvironmentRunner() {
    // let environmentMapURL = useRender((r) => r.environmentMapURL) || ''
    // let scene = useThree((r) => r.scene)
    // useEffect(() => {
    //   dynamicLoad(environmentMapURL).then((texure) => {
    //     if (texure) {
    //       scene.environment = texure
    //     }
    //   })
    // }, [scene, environmentMapURL])

    return (
        <>
            <color attach="background" args={['#f0f0f0']}></color>
        </>
    )
}

let cacheURL = new Map()
function RingLoader() {
    let ringURL = useRender((r) => r.ringURL) || ''
    let [url, setURL] = useState(false)

    useEffect(() => {
        //
        if (ringURL.includes('.zip')) {
            if (cacheURL.has(ringURL)) {
                setURL(cacheURL.get(ringURL))
                return
            }

            fetch(ringURL)
                .then((res) => {
                    return res.arrayBuffer()
                })
                .then((file) => {
                    //
                    unzip(file).then(async (r) => {
                        let entries = r.entries
                        let values = Object.values(entries)
                        // let obj = values.find((it) => it.name.includes('.obj'))
                        let obj = values.find((it) => (it?.name || '').includes('.obj') || (it?.name || '').includes('.glb'))
                        let myblob = await obj.blob()
                        let link = URL.createObjectURL(myblob) + `#t=.obj`

                        cacheURL.set(ringURL, link)
                        setURL(link)
                    })
                })

            setURL(false)
        } else {
            setURL(ringURL)
        }
    }, [ringURL])
    return url ? (
        <>
            <AccumulativeShadows frames={200} scale={20} position={[0, -0.01, 0]}>
                <RandomizedLight frames={200} position={[0, 5, 5]}></RandomizedLight>
            </AccumulativeShadows>
            <RingLoaderInside ringURL={url}></RingLoaderInside>
        </>
    ) : null
}

function getRotationFromNormalVector(normal) {
    // Normalize the normal vector
    const surfaceNormal = normal.clone().normalize()

    // Calculate the rotation matrix to align the cube with the surface normal
    const targetVector = new Vector3(0, 0, 1) // The desired facing direction of the cube
    const axis = new Vector3().crossVectors(targetVector, surfaceNormal).normalize()
    const angle = Math.acos(targetVector.dot(surfaceNormal))

    // Create a Quaternion representing the rotation
    const quaternion = new Quaternion().setFromAxisAngle(axis, angle)

    // Convert the Quaternion to Euler angles
    const euler = new Euler().setFromQuaternion(quaternion)

    // Return the rotation as Euler angles
    return { euler, quaternion }
}

function RingLoaderInside({ ringURL }) {
    let sceneLoaded = false

    if (ringURL.includes('.obj')) {
        sceneLoaded = useLoader(OBJLoader, ringURL)
    } else if (ringURL.includes('.glb')) {
        let gltf = useGLTF(ringURL)
        sceneLoaded = gltf.scene.clone(true)
        sceneLoaded = clone(sceneLoaded)
        sceneLoaded.traverse((it) => {
            if (it.geometry) {
                it.geometry.computeVertexNormals()
            }
        })
    }

    let diamondName = false
    let others = []

    let computedRainbowEnv = useRender((r) => r.computedRainbowEnv)
    let computedMetalEnv = useRender((r) => r.computedMetalEnv)

    {
        sceneLoaded.traverse((it) => {
            if (it.isMesh) {
                if (!it.geometry.scaled) {
                    it.geometry.scale(0.1, 0.1, 0.1)
                    it.geometry.scaled = true
                }
                it.castShadow = true
                it.receiveShadow = true
                //Diamond
                if (it?.material?.name?.includes('Diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it?.material?.name?.toLowerCase()?.includes('diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it?.material?.name?.includes('Diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it.name.toLowerCase().includes('diamond') && !diamondName) {
                    diamondName = it.name
                } else {
                    others.push(it)
                }
            }
        })
    }

    console.log(diamondName)

    let items = []

    let metalEnvMapIntensity = useRender((r) => r.metalEnvMapIntensity)
    let metalTint = useRender((r) => r.metalTint)
    let metalRoughness = useRender((r) => r.metalRoughness)

    let addedEngrave = 0

    function MyDecal({}) {
        let [canvasTexture, setCT] = useState(false)
        let ref = useRef()
        let textColor = useRender((r) => r.textColor)
        let textContent = useRender((r) => r.textContent)
        let textFont = useRender((r) => r.textFont)
        let textSize = useRender((r) => r.textSize)
        useEffect(() => {
            //
            let render = async () => {
                let canvas = document.createElement('canvas')
                canvas.width = 1024
                canvas.height = 128
                let ctx = canvas.getContext('2d')
                ctx.fillStyle = 'rgba(0,0,0,0.0)'

                ctx.fillRect(0, 0, 1024, 128)
                ctx.font = `${textSize}px ${textFont}`
                ctx.fillStyle = 'white'
                ctx.textBaseline = 'middle'
                ctx.textAlign = 'center'
                ctx.fillText(textContent, 1024 / 2, 128 / 2, 1024)

                let ct = new CanvasTexture(canvas)
                ct.anisotropy = 16
                ct.colorSpace = SRGBColorSpace
                ct.needsUpdate = true
                setCT(ct)
            }
            Promise.resolve().then(async () => {
                render()
                await new FontFace('Edwardian', 'url(/font/itc-font/EdwardianScriptITC.ttf)').load().then(() => {
                    return new Promise((r) => setTimeout(r, 250))
                })
                render()
            })
        }, [textColor, textSize, textContent, textFont])

        useEffect(() => {
            if (ref.current && ref.current.geometry && !ref.current.geometry.translated) {
                ref.current.geometry.translated = true
                ref.current.geometry.translate(0, 0.005, 0)
            }
        }, [canvasTexture])

        let noiseTexture = useTexture(`/T_NoiseNormal_76.png`)
        noiseTexture.wrapS = RepeatWrapping
        noiseTexture.wrapT = RepeatWrapping
        noiseTexture.repeat.set(1 * 50, 0.1 * 50)
        noiseTexture.needsUpdate = true
        return canvasTexture ? (
            <Decal
                ref={ref}
                debug={false}
                scale={[0.5, 0.06, 1]}
                position={[-0.3782187519173923, 0.30294158784580594, 0.004640271897898174].map((r) => r)}
                rotation={[-1.5707963267948966, 0.49283400008919465, 0.0]}>
                <meshStandardMaterial
                    color={textColor}
                    envMap={computedMetalEnv}
                    envMapIntensity={metalEnvMapIntensity}
                    roughness={metalRoughness}
                    metalness={1}
                    map={canvasTexture}
                    normalScale={[0.15, 0.15]}
                    normalMap={noiseTexture}
                    metalnessMap={noiseTexture}
                    transparent></meshStandardMaterial>
            </Decal>
        ) : null
    }

    {
        computedMetalEnv &&
            sceneLoaded.traverse((it) => {
                if (it.isMesh) {
                    if (it.name !== diamondName) {
                        console.log(it.name)

                        if (it.geometry) {
                            console.log(addedEngrave)

                            items.push(
                                <group key={it.uuid}>
                                    <mesh
                                        onClick={(ev) => {
                                            if (ev.normal) {
                                                let { euler } = getRotationFromNormalVector(ev.normal)

                                                console.log(ev.point.toArray(), euler.toArray())
                                            }
                                        }}
                                        geometry={it.geometry}
                                        position={it.position.toArray()}
                                        scale={it.scale.toArray()}
                                        rotation={it.rotation}>
                                        <meshStandardMaterial
                                            // matcap={matcap || computedMetalEnv}
                                            envMap={computedMetalEnv}
                                            envMapIntensity={metalEnvMapIntensity}
                                            color={metalTint}
                                            roughness={metalRoughness}
                                            metalness={1}
                                        />

                                        {addedEngrave == 5 && <MyDecal></MyDecal>}
                                    </mesh>

                                    {/* {createPortal(
                        <meshStandardMaterial
                          // matcap={matcap || computedMetalEnv}
                          envMap={computedMetalEnv}
                          envMapIntensity={metalEnvMapIntensity}
                          color={metalTint}
                          roughness={metalRoughness}
                          metalness={1}
                        />,
                        it
                      )} */}
                                </group>
                            )

                            addedEngrave++
                        }
                    }
                }
            })
    }

    const diamondConfig = {
        diamondColor: '#ffffff',
        lightColor: '#ffeecc',
        roughness: 0.1,
        aberrationStrength: 0.01,
        ior: 2.418,
        metalness: 1
    }

    let light1 = useRef()
    let light2 = useRef()

    let diamondTint = useRender((r) => r.diamondTint)
    useEffect(() => {
        useRender.setState({ meshes: [sceneLoaded.getObjectByName(diamondName)], lights: [light1.current, light2.current] })
    }, [])
    let r = useMemo(() => {
        return '_' + (Math.random() * 10000000).toFixed(0)
    }, [])

    // if (sceneLoaded.getObjectByName(diamondName)) {
    //   sceneLoaded.getObjectByName(diamondName).material = new MeshBasicMaterial({})
    // }

    return (
        <>
            {computedRainbowEnv &&
                createPortal(
                    <RefractionMaterialCustomCircle
                        uniq={ringURL + r}
                        key={DiamondMaterialCustomCircleKey + r + ringURL}
                        envMap={computedRainbowEnv}
                        {...diamondConfig}
                        color={diamondTint}
                        fastChroma={false}
                        bounces={1}
                        fresnel={0}
                        toneMapped={false}
                    />,
                    sceneLoaded.getObjectByName(diamondName)
                )}

            <directionalLight
                ref={light1}
                intensity={1}
                color={diamondConfig.lightColor}
                target-position={[0, 0, 0]}
                position={[5, 5, -5]}></directionalLight>
            <directionalLight
                ref={light2}
                intensity={1}
                color={diamondConfig.lightColor}
                target-position={[0, 0, 0]}
                position={[-5, 5, -5]}></directionalLight>
            {items}
            {sceneLoaded && <primitive key={sceneLoaded.uuid} object={sceneLoaded}></primitive>}
        </>
    )
}

let links = [
    `/Hdri_reflection/studio18b.png`,
    `/matcap2k/bw.png`,
    `/matcap2k/rose-gold@2x.png`,
    `/matcap2k/yellow@2x.png `,

    `/Hdri_reflection/059.hdr`,
    `/Hdri_reflection/background_D001.jpg`,
    `/Hdri_reflection/background_D002.jpg`,
    `/Hdri_reflection/background_D003.jpg`,
    `/Hdri_reflection/background_D004.jpg`,
    `/Hdri_reflection/Flourecent-Lights.hdr`,
    `/Hdri_reflection/hdri_1.jpg`,
    `/Hdri_reflection/hdri_2.jpg`,
    `/Hdri_reflection/hdri_3_1.jpg`,
    `/Hdri_reflection/hdri_3_2.jpg`,
    `/Hdri_reflection/hdri_3.jpg`,
    `/Hdri_reflection/hdri_4_1.jpg`,
    `/Hdri_reflection/hdri_4.jpg`,
    `/Hdri_reflection/hdri_5.jpg`,
    `/Hdri_reflection/hdri_6.jpg`,
    `/Hdri_reflection/hdri_7.jpg`,
    `/Hdri_reflection/hdri_8.jpg`,
    `/Hdri_reflection/hdri_9.jpg`,
    `/Hdri_reflection/hdri_10.jpg`,
    `/Hdri_reflection/hdri_11.jpg`,
    `/Hdri_reflection/hdri_12.jpg`,
    `/Hdri_reflection/hdri_13.jpg`,
    `/Hdri_reflection/hdri_14.jpg`,
    `/Hdri_reflection/hdri_15.jpg`,
    `/Hdri_reflection/hdri_16.jpg`,
    `/Hdri_reflection/hdri_17.jpg`,
    `/Hdri_reflection/hdri_18.jpg`,
    `/Hdri_reflection/hdri_19.jpg`,
    `/Hdri_reflection/hdri_20.jpg`,
    `/Hdri_reflection/hdri_21.jpg`,
    `/Hdri_reflection/hdri_22.jpg`,
    `/Hdri_reflection/hdri_23.jpg`,
    `/Hdri_reflection/hdri_24.jpg`,
    `/Hdri_reflection/hdri_25.jpg`,
    `/Hdri_reflection/hdri_26.jpg`,
    `/Hdri_reflection/hdri_27.jpg`,
    `/Hdri_reflection/hdri_28.jpg`,
    `/Hdri_reflection/hdri_29.jpg`,
    `/Hdri_reflection/hdri_30.jpg`,
    `/Hdri_reflection/hdri_31.jpg`,
    `/Hdri_reflection/hdri_32.jpg`,
    `/Hdri_reflection/hdri_33.jpg`,
    `/Hdri_reflection/hdri_slotroom01d_sml2.hdr`,
    `/Hdri_reflection/hdri-active.jpeg`,
    `/Hdri_reflection/sky0031.hdr`,
    `/Hdri_reflection/studio001.hdr`,
    `/Hdri_reflection/studio004.hdr`,
    `/Hdri_reflection/studio007.hdr`,
    `/Hdri_reflection/studio008.hdr`,
    `/Hdri_reflection/studio016.hdr`,
    `/Hdri_reflection/studio018.hdr`,
    `/Hdri_reflection/studio022.hdr`,
    `/Hdri_reflection/studio025.hdr`,
    `/Hdri_reflection/studio028.hdr`
]

let hdrOptions = {}
links.forEach((link) => {
    hdrOptions[link] = link
})

function EnvironmentConf() {
    let refDiv = useRef()

    /*

  let brightness = useRender((r) => r.brightness)
  let contrast = useRender((r) => r.contrast)

  let hue = useRender((r) => r.hue)
  let saturation = useRender((r) => r.saturation)
  */
    useEffect(() => {
        //
        const PARAMS = useRender.getState()
        //
        const pane = new Pane({
            container: refDiv.current
        })

        pane.addInput(PARAMS, 'smoothGlow', {}).on('change', (v) => {
            useRender.setState({
                smoothGlow: PARAMS.smoothGlow,
                //
                brightness: PARAMS.brightness,
                contrast: PARAMS.contrast,
                hue: PARAMS.hue,
                saturation: PARAMS.saturation
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'glowDetect', {
            min: 0,
            max: 1
        }).on('change', (v) => {
            useRender.setState({
                glowDetect: PARAMS.glowDetect
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'intensity', { min: 0, max: 5 }).on('change', (v) => {
            useRender.setState({
                intensity: PARAMS.intensity
            })
            pane.refresh()
        })

        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

// function RingConf() {
//   let refDiv = useRef()
//   let keyName = `ringURL`

//   useEffect(() => {
//     const PARAMS = useRender.getState()
//     PARAMS[keyName] = PARAMS[keyName] || `/ring100/1847467(2023-8-11)-100x.obj`

//     const pane = new Pane({
//       container: refDiv.current
//     })
//     pane
//       .addInput(PARAMS, keyName, {
//         options: {
//           [`94010R(11-8-2023)`]: `/ring100/1847467(2023-8-11)-100x.obj`,
//           [`01257R(27-7-2023)`]: `/ring100/01257R(27-7-2023).obj`,
//           [`79426R(27-7-2023)`]: `/ring100/79426R(27-7-2023).obj`,
//           [`90147R(27-7-2023)`]: `/ring100/90147R(27-7-2023).obj`,
//           [`90257R(27-7-2023)`]: `/ring100/90257R(27-7-2023).obj`
//         }
//       })
//       .on('change', (v) => {
//         useRender.setState({
//           [keyName]: v
//         })
//         pane.refresh()
//       })

//     return () => {
//       pane.dispose()
//     }
//   }, [])
//   return (
//     <>
//       <div ref={refDiv}></div>
//     </>
//   )
// }

function SimpleEngraving() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        const pane = new Pane({
            container: refDiv.current
        })

        let st = { ...useRender.getState() }

        pane.addInput(st, 'textColor', {}).on('change', (ev) => {
            useRender.setState({ textColor: st.textColor })
        })
        pane.addInput(st, 'textFont', {
            options: {
                'Arial / 楷書': `Arial, Times New Roman`,
                'Times New Roman / 楷書': `Times New Roman, "DFKai-sb"`,
                'Edwardian Script / 楷書': `Edwardian`
            }
        }).on('change', (ev) => {
            useRender.setState({ textFont: st.textFont })
        })
        // pane.addInput(st, 'textSize', {
        //   value: 128,
        //   max: 130,
        //   min: 100,
        // }).on('change', (ev) => {
        //   useRender.setState({ textSize: st.textSize })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])

    let textContent = useRender((r) => r.textContent)

    let inputRef = useRef()

    // function getCursor () {
    //   if ('selectionStart' in input) {
    //     // Standard-compliant browsers
    //     return input.selectionStart;
    // } else if (document.selection) {
    //     // IE
    //     input.focus();
    //     var sel = document.selection.createRange();
    //     var selLen = document.selection.createRange().text.length;
    //     sel.moveStart('character', -input.value.length);
    //     return sel.text.length - selLen;
    // }
    // }

    let [csr, setCSR] = useState({ start: 0, end: 0 })
    return (
        <>
            <div className=" m-2">
                <input
                    ref={inputRef}
                    className="text-center border rounded-lg p-2 w-full"
                    value={textContent}
                    onInput={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}
                    onChange={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}
                    onKeyDown={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}></input>
            </div>

            <div>Add Symbol</div>
            <div className="mb-3">
                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `♡` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    ♡
                </button>

                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `♥` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    ♥
                </button>
                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `&` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    &
                </button>
            </div>
            <div ref={refDiv}></div>
        </>
    )
}

function SimpleRingConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        const pane = new Pane({
            container: refDiv.current
        })

        let diamondSize = {}

        rings.forEach((ring) => {
            diamondSize[`${ring.ct}`] = ring.ct
        })

        pane.addInput(useRender.getState(), 'ct', {
            options: {
                ...diamondSize
            },
            label: 'DiamondSize'
        }).on('change', (v) => {
            useRender.setState({
                ['ct']: v
            })

            // pane.refresh()

            setTimeout(() => {
                useRender.setState({
                    ringURL: rings.find((r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`)?.url
                })
            })
        })

        //

        let fingerSize = {}

        rings.forEach((ring) => {
            fingerSize[`${ring.hh}`] = ring.hh
        })

        pane.addInput(useRender.getState(), 'hh', {
            options: {
                ...fingerSize
            },
            label: 'FingerSize'
        }).on('change', (v) => {
            useRender.setState({
                ['hh']: v
            })

            // pane.refresh()

            useRender.setState({
                ringURL: rings.find((r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`)?.url
            })

            // console.log(v)

            // console.log(v)
            // useRender.setState({
            //   ['ringURL']: v
            // })
        })

        // rings.forEach((ring) => {
        //   pane
        //     .addButton({
        //       title: ring.name
        //     })
        //     .on('click', () => {
        //       useRender.setState({
        //         s100: ring.s100 || false,
        //         ringURL: ring.url
        //       })
        //     })
        // })

        // pane
        //   .addButton({
        //     title: ''
        //   })
        //   .on('click', () => {
        //     useRender.setState({})
        //   })
        // pane
        // .addButton({
        //   title: 'platinum'
        // })
        // .on('click', () => {
        //   useRender.setState({ metalTint: '#ffffff' })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

function SimpleMetalConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        //

        const pane = new Pane({
            container: refDiv.current
        })

        pane.addButton({
            title: 'Yellow Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[0].data, matID: presets[0]?.id })
        })

        pane.addButton({
            title: 'Rose Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[1].data, matID: presets[1]?.id })
        })

        pane.addButton({
            title: 'White Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[2].data, matID: presets[2]?.id })
        })
        // pane
        // .addButton({
        //   title: 'platinum'
        // })
        // .on('click', () => {
        //   useRender.setState({ metalTint: '#ffffff' })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

function MetalConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)
    let ringURL = useRender((r) => r.ringURL)
    useEffect(() => {
        //
        const PARAMS = useRender.getState()
        // {
        //   metalMatCapURL: useRender.getState().metalMatCapURL,
        //   metalHDRURL: useRender.getState().metalHDRURL,
        //   metalHdrMatCapRatio: useRender.getState().metalHdrMatCapRatio,
        //   metalTint: useRender.getState().metalTint,
        //   metalEnvMapIntensity: useRender.getState().metalEnvMapIntensity,
        //   metalRotY: useRender.getState().metalRotY,
        //   //
        //   brightness: useRender.getState().brightness,
        //   contrast: useRender.getState().contrast,
        //   saturation: useRender.getState().saturation
        // }

        hdrOptions[PARAMS.metalHDRURL] = PARAMS.metalHDRURL
        hdrOptions[PARAMS.metalMatCapURL] = PARAMS.metalMatCapURL

        const pane = new Pane({
            container: refDiv.current
        })

        pane.addInput(PARAMS, 'brightness', {
            min: -1,
            max: 1
        }).on('change', (v) => {
            useRender.setState({
                brightness: PARAMS.brightness
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'contrast', {
            min: -5,
            max: 5
        }).on('change', (v) => {
            useRender.setState({
                contrast: PARAMS.contrast
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'saturation', {
            min: -5,
            max: 5
        }).on('change', (v) => {
            useRender.setState({
                saturation: PARAMS.saturation
            })
            pane.refresh()
        })

        pane.addSeparator()

        // pane
        //   .addInput(PARAMS, 'hue', {
        //     min: -1,
        //     max: 1
        //   })
        //   .on('change', (v) => {
        //     useRender.setState({
        //       hue: PARAMS.hue
        //     })
        //     pane.refresh()
        //   })

        pane.addInput(PARAMS, 'metalMatCapURL', {
            options: hdrOptions
        }).on('change', () => {
            //
            useRender.setState({ metalMatCapURL: PARAMS.metalMatCapURL })
        })

        pane.addInput(PARAMS, 'metalHDRURL', {
            options: hdrOptions
        }).on('change', () => {
            //
            useRender.setState({ metalHDRURL: PARAMS.metalHDRURL })
        })

        pane.addInput(PARAMS, 'metalHdrMatCapRatio', {
            min: 0,
            max: 1
        }).on('change', () => {
            //
            useRender.setState({ metalHdrMatCapRatio: PARAMS.metalHdrMatCapRatio })
        })

        pane.addInput(PARAMS, 'metalTint', {}).on('change', () => {
            //
            useRender.setState({ metalTint: PARAMS.metalTint })
        })

        pane.addInput(PARAMS, 'metalEnvMapIntensity', {
            min: 0,
            max: 5
        }).on('change', () => {
            //
            useRender.setState({ metalEnvMapIntensity: PARAMS.metalEnvMapIntensity })
        })

        pane.addInput(PARAMS, 'metalRotY', {
            min: 0,
            max: 3.1415926 * 2
        }).on('change', () => {
            //
            useRender.setState({ metalRotY: PARAMS.metalRotY })
        })

        setInterval(() => {
            let st = useRender.getState()

            for (let kn in st) {
                if (typeof st[kn] !== 'object' && st[kn] !== null) {
                    PARAMS[kn] = st[kn]
                }
            }
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [ringURL])

    let metalMatCapURL = useRender((r) => r.metalMatCapURL)
    let metalHDRURL = useRender((r) => r.metalHDRURL)
    return (
        <>
            <div ref={refDiv}></div>
            Layer1
            {metalMatCapURL && <Preview url={metalMatCapURL} />}
            Layer2
            {metalHDRURL && <Preview url={metalHDRURL} />}
        </>
    )
}

function Preview({ url }) {
    return (
        <Canvas gl={{}}>
            <PreviewContnet url={url}></PreviewContnet>
        </Canvas>
    )
}
function PreviewContnet({ url }) {
    let scene = useThree((r) => r.scene)
    useEffect(() => {
        if (!url) {
            return
        }
        // console.log(url)
        dynamicLoad(url, EquirectangularReflectionMapping).then((texure) => {
            if (texure) {
                scene.environment = texure
                scene.background = texure
            }
        })
    }, [scene, url])

    return (
        <>
            <Sphere scale={2.5}>
                <meshStandardMaterial roughness={0} metalness={1}></meshStandardMaterial>
            </Sphere>
            <OrbitControls></OrbitControls>
        </>
    )
}

function DiamondConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        //
        const PARAMS = {
            diamondMatCapURL: useRender.getState().diamondMatCapURL || '/Hdri_reflection/hdri_15.jpg',
            diamondHDRURL: useRender.getState().diamondHDRURL || '/Hdri_reflection/hdri_15.jpg',
            diamondTint: useRender.getState().diamondTint,
            diamondEnvMapIntensity: useRender.getState().diamondEnvMapIntensity,
            diamondLighntess: useRender.getState().diamondLighntess,
            diamondRainbow: useRender.getState().diamondRainbow,
            diamondContrast: useRender.getState().diamondContrast
        }

        const pane = new Pane({
            container: refDiv.current
        })

        // pane
        //   .addInput(PARAMS, 'diamondMatCapURL', {
        //     options: hdrOptions
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondMatCapURL: PARAMS.diamondMatCapURL })
        //   })

        // pane
        //   .addInput(PARAMS, 'diamondHDRURL', {
        //     options: hdrOptions
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondHDRURL: PARAMS.diamondHDRURL })
        //   })

        pane.addInput(PARAMS, 'diamondTint', {}).on('change', () => {
            useRender.setState({ diamondTint: PARAMS.diamondTint })
        })

        pane.addInput(PARAMS, 'diamondEnvMapIntensity', {
            min: 0,
            max: 3
        }).on('change', () => {
            //
            useRender.setState({ diamondEnvMapIntensity: PARAMS.diamondEnvMapIntensity })
        })

        pane.addInput(PARAMS, 'diamondLighntess', {
            min: 0,
            max: 5
        }).on('change', () => {
            //
            useRender.setState({ diamondLighntess: PARAMS.diamondLighntess })
        })

        // pane
        //   .addInput(PARAMS, 'diamondRainbow', {
        //     min: 0,
        //     max: 0.1
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondRainbow: PARAMS.diamondRainbow })
        //   })

        pane.addInput(PARAMS, 'diamondContrast', {
            min: 0,
            max: 10
        }).on('change', () => {
            //
            useRender.setState({ diamondContrast: PARAMS.diamondContrast })
        })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

const useFlow = (title, config) => {
    let output = {}
    for (let kn in config) {
        output[kn] = config[kn]?.value || config[kn]
    }
    return {
        ...output
    }
}

//

function PP() {
    // let { glowDetect, intensity, smoothGlow } = useFlow(
    //   'flare',
    //   {
    //     smoothGlow: true,
    //     glowDetect: { value: 0.95, min: 0, max: 1 },
    //     intensity: { value: 1.47, min: 0, max: 15 }
    //   },

    //   {
    //     // container: document.querySelector('#tweak')
    //   }
    // )

    let intensity = useRender((r) => r.intensity)
    let glowDetect = useRender((r) => r.glowDetect)
    let smoothGlow = useRender((r) => r.smoothGlow)

    let lights = useRender((r) => r.lights)
    let meshes = useRender((r) => r.meshes)

    // let brightness = useRender((r) => r.brightness)
    // let contrast = useRender((r) => r.contrast)

    // let hue = useRender((r) => r.hue)
    // let saturation = useRender((r) => r.saturation)

    return (
        <>
            <EffectComposer multisampling={4} disableNormalPass>
                <SelectiveBloom
                    lights={lights}
                    selection={meshes}
                    selectionLayer={10}
                    luminanceThreshold={glowDetect}
                    intensity={intensity}
                    mipmapBlur={smoothGlow}
                />

                {/* <BrightnessContrast brightness={brightness} contrast={contrast}></BrightnessContrast>
        <HueSaturation hue={hue} saturation={saturation}></HueSaturation> */}
                {/* <DepthOfField focusDistance={1} focalLength={0.3} bokehScale={1} height={480} /> */}
                {/* <Vignette></Vignette> */}
            </EffectComposer>
        </>
    )
}
