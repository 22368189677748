import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import { OrbitControls, CubeCamera, Box, useEnvironment, PerspectiveCamera, Sphere, useTexture } from '@react-three/drei'
import { RefractionMaterial } from './RefractionMaterial'
import { useTweaks, makeButton } from 'use-tweaks'
import { Bloom, DepthOfField, EffectComposer, SSR, SelectiveBloom, Vignette } from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Color, EquirectangularReflectionMapping, HalfFloatType, SRGBColorSpace } from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'
// import { DiamondMaterialCustomKey, RefractionMaterialCustom } from './RefractionMaterialCustom'
import { create } from 'zustand'
import { DiamondMaterialCustomCircleKey, RefractionMaterialCustomCircle } from './RefractionMaterialCustomCircle'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { TextureLoader, sRGBEncoding } from 'three140'
function Diamond({ url, computedMetalEnv, computedRainbowEnv }) {
  let scene = useThree((r) => r.scene)
  scene.environment = computedMetalEnv

  const obj = useLoader(OBJLoader, url)

  const config = useTweaks('raytrace', {
    // bounces: { value: 1, min: 0, max: 8, step: 1 },
    // aberrationStrength: { value: 0.01, min: 0, max: 0.1, step: 0.01 },
    // ior: { value: 2.418, min: 0, max: 10 },
    // fresnel: { value: 0, min: 0, max: 1 },
    diamondColor: '#ffffff',
    // fastChroma: false,
    metalColor: '#ffda89',
    lightColor: '#ffeecc',
    //

    roughness: { value: 0.1, min: 0, max: 1 }
    // metalness: { value: 1, min: 0, max: 1 }
  })
  config.aberrationStrength = 0.01
  config.ior = 2.418
  config.metalness = 1

  let diamondName = false

  let others = []
  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name.toLowerCase().includes('diamond') && !diamondName) {
        diamondName = it.name
      } else {
        others.push(it)
      }
    }
  })

  let items = []

  useEffect(() => {
    useRender.setState({ meshes: [obj.getObjectByName(diamondName)] })
  })

  {
    obj.traverse((it) => {
      if (it.isMesh) {
        if (it.name !== diamondName) {
          items.push(
            <group key={it.uuid}>
              {createPortal(
                <meshStandardMaterial
                  // matcap={matcap || computedMetalEnv}
                  envMap={computedMetalEnv}
                  color={config.metalColor}
                  roughness={config.roughness}
                  metalness={config.metalness}
                />,
                it
              )}
            </group>
          )
        }
      }
    })
  }

  return (
    <>
      <directionalLight intensity={1} color={config.lightColor} position={[2, 2, -2]}></directionalLight>
      <directionalLight intensity={1} color={config.lightColor} position={[-2, 2, -2]}></directionalLight>

      {/*  */}

      {createPortal(
        <RefractionMaterialCustomCircle
          key={DiamondMaterialCustomCircleKey}
          envMap={computedRainbowEnv}
          {...config}
          color={config.diamondColor}
          fastChroma={false}
          bounces={1}
          fresnel={0}
          toneMapped={false}
        />,
        obj.getObjectByName(diamondName)
      )}

      {/* <CubeCamera resolution={64} position={[0, 2, 0]} frames={Infinity}>
        {(texture) => {
          return <></>
        }}
      </CubeCamera> */}

      {items}

      {<primitive object={obj}></primitive>}
    </>
  )
}

function Picker() {
  let { ring } = useTweaks(
    'choose-ring',
    {
      ring: {
        value: `/ring100/79426R(27-7-2023).obj`,
        options: {
          [`01257R(27-7-2023)`]: `/ring100/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring100/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring100/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring100/90257R(27-7-2023).obj`
        }
      }
    },
    {
      // container: document.querySelector('#tweak')
    }
  )

  let hdriMetal = useLoader(RGBELoader, `/metal_01.hdr`)
  hdriMetal.mapping = EquirectangularReflectionMapping

  // let bwhdri = useTexture(`/matcap/ring5bw-refine.png`)
  // bwhdri.colorSpace = SRGBColorSpace

  // let hdri8 = useTexture(`/Hdri_reflection/hdri_8.jpg`)
  // hdri8.mapping = EquirectangularReflectionMapping
  // hdri8.colorSpace = SRGBColorSpace

  // let brown_photostudio_01_1k = useLoader(RGBELoader, `/brown_photostudio_01_1k.hdr`)
  // brown_photostudio_01_1k.mapping = EquirectangularReflectionMapping

  // let metal512EXR = useLoader(EXRLoader, `/css-diamond-env/metal-512.exr`, (loader) => {
  //   loader.setDataType(HalfFloatType)
  // })
  // metal512EXR.mapping = EquirectangularReflectionMapping

  let exr = useLoader(EXRLoader, '/brown_photostudio_07-bw.exr', (loader) => {
    loader.setDataType(HalfFloatType)
  })
  exr.mapping = EquirectangularReflectionMapping

  // let exr = useEnvironment({ files: `/hdr/modern_buildings_1k.hdr` })

  let diamondEXR = useLoader(EXRLoader, '/css-diamond-env/exr-diamond-512.exr', (loader) => {
    loader.setDataType(HalfFloatType)
  })
  diamondEXR.mapping = EquirectangularReflectionMapping

  let [matcap, setMatCap] = useState(null)
  // useTweaks('matcap', {
  //   ...makeButton('upload', () => {
  //     let file = document.createElement('input')
  //     file.type = 'file'
  //     file.onchange = (e) => {
  //       if (e.target?.files[0]) {
  //         //
  //         let reader = new FileReader()
  //         reader.readAsDataURL(e.target.files[0])

  //         reader.onload = (e) => {
  //           let textureLoader = new TextureLoader()
  //           textureLoader.load(reader.result, (texture) => {
  //             texture.encoding = sRGBEncoding
  //             texture.colorSpace = SRGBColorSpace
  //             setMatCap(texture)
  //           })
  //         }
  //       }
  //     }
  //     file.click()
  //   }),
  //   ...makeButton('rose-gold', () => {
  //     let textureLoader = new TextureLoader()
  //     textureLoader.load(`/matcap2k/rose-gold@2x.png`, (texture) => {
  //       texture.encoding = sRGBEncoding
  //       texture.colorSpace = SRGBColorSpace
  //       setMatCap(texture)
  //     })
  //   }),
  //   ...makeButton('gold', () => {
  //     let textureLoader = new TextureLoader()
  //     textureLoader.load(`/matcap2k/yellow@2x.png`, (texture) => {
  //       texture.encoding = sRGBEncoding
  //       texture.colorSpace = SRGBColorSpace
  //       setMatCap(texture)
  //     })
  //   }),
  //   ...makeButton('platinum', () => {
  //     let textureLoader = new TextureLoader()
  //     textureLoader.load(`/matcap2k/bw.png`, (texture) => {
  //       texture.encoding = sRGBEncoding
  //       texture.colorSpace = SRGBColorSpace
  //       setMatCap(texture)
  //     })
  //   })
  // })

  useEffect(() => {
    let textureLoader = new TextureLoader()
    textureLoader.load(`/Hdri_reflection/hdri-active.jpeg`, (texture) => {
      texture.encoding = sRGBEncoding
      texture.colorSpace = SRGBColorSpace
      setMatCap(texture)
    })
  }, [])

  //

  let { envMap: computedMetalEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  uniform sampler2D matcap;

  uniform sampler2D metalEnvMap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }


  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }
  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec4 hdrTextureC4 = texture2D(metalEnvMap, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 2.3, 0.1), 2.0);

    // outColor.rgb = vec3(
    //   hdrTextureC4.r,
    //   hdrTextureC4.g,
    //   hdrTextureC4.b
    // );

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, uvMC );

    // // outColor.r = pow(outColor.r * 1.5, 1.5);
    // // outColor.g = pow(outColor.g * 1.5, 1.5);
    // // outColor.b = pow(outColor.b * 1.5, 1.5);

    // // float avgColor = (outColor.r + outColor.g + outColor.b) / 3.0;
    // // outColor.rgb = vec3(avgColor);


    // outColor.rgb = czm_saturation(hdrTextureC4.rgb, 0.3);


    outColor.rgb = mix(matcapColor.rgb, hdrTextureC4.rgb, 0.0);

    return outColor;
  }

  `,
    { time: { value: 0 }, matcap: { value: matcap }, metalEnvMap: { value: hdriMetal }, envLightIntensity: { value: 1 } },
    32,
    true
  )

  let { lightness, rainbow, contrast } = useTweaks('diamond hdr', {
    lightness: { value: 3.75, min: 0, max: 5 },
    rainbow: { value: 0.0043478261, min: 0, max: 0.1 },
    contrast: { value: 3.3152173913, min: 0, max: 10 }
  })

  let { envMap: computedRainbowEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  // float pattern (vec2 p, float time) {
  //   float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
  //   return abs(vout);
  // }


  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm4(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;
  uniform sampler2D matcap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }

  vec4 mainImage ()  {


    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, uvMC );

    vec4 hdrV4 = matcapColor;// texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 3.3, 0.1), 2.0);

    outColor.rgb = vec3(
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 + ${rainbow.toFixed(3)}, time * 0.05),
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0, time * 0.05),
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 - ${rainbow.toFixed(3)}, time * 0.05)
    );

    outColor.r = pow(outColor.r * 0.4, ${contrast.toFixed(3)});
    outColor.g = pow(outColor.g * 0.4, ${contrast.toFixed(3)});
    outColor.b = pow(outColor.b * 0.4, ${contrast.toFixed(3)});

    outColor.rgb = mix(hdrV4.rgb , outColor.rgb, 0.3);
    outColor.rgb  = czm_saturation(outColor.rgb, 0.1);

    return outColor;
  }

  `,
    { time: { value: 0 }, matcap: { value: diamondEXR }, hdrTexture: { value: exr }, envLightIntensity: { value: 1 } },
    512,
    true
  )

  let scene = useThree((r) => r.scene)
  scene.background = new Color('#f0f0f0')
  // scene.background = computedRainbowEnv // new Color('#f0f0f0')

  useEffect(() => {
    return () => {
      computedRainbowEnv.dispose()
    }
  }, [computedRainbowEnv])

  return (
    <>
      <Suspense fallback={null}>
        <group scale={1}>
          <Diamond computedRainbowEnv={computedRainbowEnv} computedMetalEnv={computedMetalEnv} url={ring} key={ring} />
        </group>
      </Suspense>
    </>
  )
}
function ShardContent() {
  return (
    <>
      <Picker></Picker>

      {/*  */}
      <ambientLight intensity={1} />

      <pointLight intensity={1} position={[7, 7, -7]} />
      <pointLight intensity={1} position={[-7, 7, -7]} />

      {/* <Environment files={} background></Environment> */}
      <PP></PP>

      <PerspectiveCamera near={0.05} far={100} makeDefault></PerspectiveCamera>
      <OrbitControls minDistance={0.015} object-position={[0, 0.045 * 100.0, 0.03 * 100.0]} target={[0, 0.02 * 100.0, 0]}></OrbitControls>
    </>
  )
}

export function Shard5() {
  return (
    <>
      <Canvas gl={{ antialias: true }}>
        <ShardContent></ShardContent>
      </Canvas>
    </>
  )
}

let useRender = create((set, get) => {
  return {
    //
    lights: [],
    meshes: []
    //
  }
})

function PP() {
  let { glowDetect, intensity, smoothGlow } = useTweaks(
    'flare',
    {
      smoothGlow: true,
      glowDetect: { value: 0.98, min: 0, max: 1 },
      intensity: { value: 1.47, min: 0, max: 15 }
    },

    {
      // container: document.querySelector('#tweak')
    }
  )

  let lights = useRender((r) => r.lights)
  let meshes = useRender((r) => r.meshes)
  return (
    <EffectComposer multisampling={4} disableNormalPass>
      <SelectiveBloom
        lights={lights}
        selection={meshes}
        selectionLayer={10}
        luminanceThreshold={glowDetect}
        intensity={intensity}
        mipmapBlur={smoothGlow}
      />
      {/* <DepthOfField focusDistance={0.003} focalLength={0.02} bokehScale={2} height={480} /> */}
      <Vignette></Vignette>
    </EffectComposer>
  )
}
