import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
    OrbitControls,
    PerspectiveCamera,
    Sphere,
    useGLTF,
    useTexture,
    RandomizedLight,
    AccumulativeShadows,
    Environment,
    SoftShadows,
    ContactShadows,
    SpotLight,
    Shadow,
    Caustics,
    Box,
    Decal,
    RenderTexture,
    Text,
    useFBO
} from '@react-three/drei'
// import { RefractionMaterial } from './RefractionMaterial'
// import { useTweaks, makeButton } from 'use-tweaks'
import {
    Bloom,
    brightnessContrast,
    DepthOfField,
    HueSaturation,
    SSR,
    Vignette,
    BrightnessContrast,
    SSAO,
    EffectComposer,
    SelectiveBloom
} from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import {
    CanvasTexture,
    EquirectangularReflectionMapping,
    Euler,
    FileLoader,
    HalfFloatType,
    LoadingManager,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    PlaneGeometry,
    Quaternion,
    RepeatWrapping,
    SRGBColorSpace,
    UVMapping,
    Vector3,
    Cache as TCache,
    Color
} from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'
// import { DiamondMaterialCustomKey, RefractionMaterialCustom } from './RefractionMaterialCustom'
// import { create } from 'zustand'
import { DiamondMaterialCustomCircleKey, RefractionMaterialCustomCircle } from './RefractionMaterialCustomCircle'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { TextureLoader, sRGBEncoding, PerspectiveCamera as PerspectiveCamera3 } from 'three'
// import { Leva, useControls } from 'leva'
// import { useTweaks } from 'use-tweaks'
import Pane from 'tweakpane'
// import copyToClipboard from 'copy-to-clipboard'
import { clone } from 'three/examples/jsm/utils/SkeletonUtils.js'
// import { MeshBasicMaterial } from 'three140'
import { unzip, setOptions } from 'unzipit'
import { useRender, presets, rings } from './useRender'
import html2canvas from 'html2canvas'

TCache.enabled = true

export function Shard12GUI() {
    return (
        <>
            <link href="/gui/inter-web/inter.css" rel="stylesheet" />
            <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />
            <style
                dangerouslySetInnerHTML={{
                    __html: /* css */ `
        
                body {
                    font-family: Inter;
                    background: linear-gradient(189.32deg, #FEEAE1 5.26%, #FEDACA 95.71%);
                }
                
                .desktop-steps{
                    height: 45px;
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    text-align: left;
                    color: rgba(114, 114, 114, 1);
                }
                .desktop-steps.active{
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    text-align: left;
                    color: #cb7d74;
                }
                .desktop-steps.active .desktop-line{
                    border-bottom: solid 1px #cb7d74;
                    height:1px;
                    width: 40px;
                }
                .desktop-steps .desktop-line{
                    height:1px;
                    width: 40px;
                }
                `
                }}></style>
            <Shard11GUIInside></Shard11GUIInside>{' '}
        </>
    )
}

let preloadSkipper = new Set()
let preloadRing = (all = []) => {
    return all.map((r) => {
        if (preloadSkipper.has(r.url)) {
            return
        }
        preloadSkipper.add(r.url)

        let ringURL = r.url

        if (RingCacheURL.has(ringURL)) {
            return Promise.resolve('done')
        }

        let prom = fetch(ringURL)
            .then((res) => {
                return res.arrayBuffer()
            })
            .then((file) => {
                //
                return unzip(file).then(async (r) => {
                    let entries = r.entries
                    let values = Object.values(entries)
                    // let obj = values.find((it) => it.name.includes('.obj'))
                    let obj = values.find((it) => (it?.name || '').includes('.obj') || (it?.name || '').includes('.glb'))
                    let myblob = await obj.blob()
                    let link = URL.createObjectURL(myblob) + `#t=.obj`

                    RingCacheURL.set(ringURL, link)

                    if (obj.name.includes('.obj')) {
                        useLoader.preload(OBJLoader, link)
                    } else {
                        useGLTF.preload(link)
                    }

                    console.log('preload', link)

                    await new Promise((resolve) => {
                        setTimeout(resolve, 100)
                    })

                    return [ringURL, link]
                })
            })

        return prom
    })
}

function Preloader() {
    let step = useRender((r) => r.step)

    useEffect(() => {
        if (step === 'ringSize') {
            let set1 = rings.filter((r) => r.ct === useRender.getState().ct)
            preloadRing(set1)
        }
        if (step === 'diamondSize') {
            let set1 = rings.filter((r) => r.hh === useRender.getState().hh)
            preloadRing(set1)
        }
    }, [step])
    return (
        <>
            {/*  */}

            {/*  */}

            {/*  */}
        </>
    )
}

function DesktopLayout() {
    let ref = useRef()
    return (
        <div className="w-full h-full relative hidden sm:block">
            <div className="hidden sm:block pointer-events-none" style={{ height: `120px`, backgroundColor: 'white' }}>
                <div className="flex items-center justify-center w-full h-full  touch-manipulation">
                    {/* <img src={`/gui/logo/logo.svg`} alt={'Chow Sang Sang'}></img> */}
                    <img src={`/gui/logo/logo-desk.svg`} alt="Chow Sang Sang"></img>
                </div>
            </div>

            <div className="h-full relative w-full hidden sm:flex overflow-hidden" style={{ height: `calc(100% - 120px)` }}>
                <div className="h-full absolute top-0 left-0 w-full flex">
                    <Canvas
                        onCreated={(st) => {
                            let tt = setInterval(() => {
                                if (ref.current) {
                                    clearInterval(tt)
                                    st.events.connect(ref.current)
                                }
                            })
                        }}
                        gl={{ antialias: false, useLegacyLights: true }}
                        shadows>
                        <RenderContent></RenderContent>
                    </Canvas>
                </div>
                <div ref={ref} className="h-full absolute top-0 left-0 w-full flex">
                    <div
                        style={{
                            width: `calc(390px)`,
                            height: `100%`,
                            background: `rgba(254, 230, 218, 0.8)`
                        }}
                        onPointerMoveCapture={(ev) => {
                            ev.stopPropagation()
                        }}
                        onTouchMoveCapture={(ev) => {
                            ev.stopPropagation()
                        }}
                        className=" touch-manipulation">
                        {/* Choices */}

                        <DesktopChoices isMobile={false}></DesktopChoices>
                    </div>
                    <div
                        style={{
                            width: `calc(100% - 390px - 390px)`,
                            height: `100%`
                        }}
                        className=" touch-manipulation">
                        {/* <Canvas gl={{ alpha: true, antialias: false, useLegacyLights: true }} shadows>
                            <RenderContent></RenderContent>
                        </Canvas> */}
                    </div>

                    <div
                        style={{
                            width: `calc(390px)`,
                            height: `100%`,
                            background: `rgba(254, 230, 218, 0.8)`
                        }}
                        onPointerMoveCapture={(ev) => {
                            ev.stopPropagation()
                        }}
                        onTouchMoveCapture={(ev) => {
                            ev.stopPropagation()
                        }}
                        className="pl-5  touch-manipulation">
                        {/* Steps */}

                        <DesktopSteps></DesktopSteps>
                    </div>
                </div>
            </div>

            <div className=" fixed top-0 right-0 flex cursor-pointer items-center p-3" style={{ height: `120px` }}>
                <a href={'/main-gui-14'}>
                    <button className="text-xs text-gray-500 underline">Change Diamond Ring</button>
                </a>
            </div>
        </div>
    )
}

function DesktopChoices({ isMobile = false }) {
    let step = useRender((r) => r.step)
    return (
        <>
            {/*  */}
            {step === 'metal' && <MetalChoicesDesktop isMobile={isMobile}></MetalChoicesDesktop>}
            {step === 'ringSize' && <RingSizeChoicesDesktop isMobile={isMobile}></RingSizeChoicesDesktop>}
            {step === 'diamondSize' && <DiamondSizeSizeChoicesDesktop isMobile={isMobile}></DiamondSizeSizeChoicesDesktop>}
            {step === 'engrave' && <EngraveChoicesDesktop isMobile={isMobile}></EngraveChoicesDesktop>}
        </>
    )
}

function EngraveChoicesDesktop({ isMobile }) {
    let textContent = useRender((r) => r.textContent)
    let textContent2 = useRender((r) => r.textContent2)
    let textContent3 = useRender((r) => r.textContent3)
    let textFont = useRender((r) => r.textFont)
    let textFontChoices = useRender((r) => r.textFontChoices)
    let textLogosChoices = useRender((r) => r.textLogosChoices)

    let choices = textFontChoices

    let inputRef = useRef()

    // function getCursor () {
    //   if ('selectionStart' in input) {
    //     // Standard-compliant browsers
    //     return input.selectionStart;
    // } else if (document.selection) {
    //     // IE
    //     input.focus();
    //     var sel = document.selection.createRange();
    //     var selLen = document.selection.createRange().text.length;
    //     sel.moveStart('character', -input.value.length);
    //     return sel.text.length - selLen;
    // }
    // }

    //       pane.addInput(st, 'textFont', {
    //           options:
    //       }).on('change', (ev) => {
    //           useRender.setState({ textFont: st.textFont })
    //       })
    // {
    //               'Arial / 楷書': `Arial, Times New Roman`,
    //               'Times New Roman / 楷書': `Times New Roman, "DFKai-sb"`,
    //               'Edwardian Script / 楷書': `Edwardian`
    //           }

    let [csr, setCSR] = useState({ start: 0, end: 0 })
    return (
        <>
            {!isMobile && (
                <div className="text-center mt-12 mb-5" style={{ color: `rgba(203, 129, 118, 1)` }}>
                    Engrave
                </div>
            )}
            <div className=" m-5">
                <div className="">
                    <input
                        ref={inputRef}
                        className="text-center border rounded-lg p-2 w-full bg-transparent  border-black"
                        maxLength={10}
                        value={textContent}
                        onInput={(event) => {
                            useRender.setState({ textContent: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onChange={(event) => {
                            useRender.setState({ textContent: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onKeyDown={(event) => {
                            useRender.setState({ textContent: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}></input>
                </div>

                {/* <div>Add Symbol</div> */}
                <div className="mb-3 mt-3">
                    {/* <button
                        className="p-2 mr-2 px-4 rounded-xl border border-black"
                        onClick={() => {
                            useRender.setState((r) => {
                                let newText = r.textContent.slice(0, csr.start) + `♡` + r.textContent.slice(csr.end)
                                return {
                                    ...r,
                                    textContent: newText
                                }
                            })
                            inputRef.current.focus()
                        }}>
                        ♡
                    </button>

                    <button
                        className="p-2 mr-2 px-4 rounded-xl border border-black"
                        onClick={() => {
                            useRender.setState((r) => {
                                let newText = r.textContent.slice(0, csr.start) + `♥` + r.textContent.slice(csr.end)
                                return {
                                    ...r,
                                    textContent: newText
                                }
                            })
                            inputRef.current.focus()
                        }}>
                        ♥
                    </button>
                    <button
                        className="p-2 mr-2 px-4 rounded-xl border border-black"
                        onClick={() => {
                            useRender.setState((r) => {
                                let newText = r.textContent.slice(0, csr.start) + `&` + r.textContent.slice(csr.end)
                                return {
                                    ...r,
                                    textContent: newText
                                }
                            })
                            inputRef.current.focus()
                        }}>
                        &
                    </button> */}

                    <div className="flex items-center justify-center mb-3">
                        {textLogosChoices.map((r) => (
                            <button
                                key={r.id + 'url'}
                                onClick={() => {
                                    //
                                    useRender.setState({ textLogoURL: r.renderURL })
                                }}
                                className="p-1 mx-1 px-1 rounded-xl border border-black ">
                                <img src={`${r.buttonURL}`} alt={`Chow Sang Sang`} className="h-8 w-8" />
                            </button>
                        ))}
                        <button
                            key={'urlno'}
                            onClick={() => {
                                //
                                useRender.setState({
                                    textLogoURL: `hidden`
                                })
                            }}
                            className="p-1 mx-1 px-1 rounded-xl border border-black ">
                            <div className="h-8 w-8" />
                        </button>
                    </div>
                </div>
                <div className="mb-3">
                    <input
                        ref={inputRef}
                        className="text-center border rounded-lg p-2 w-full bg-transparent  border-black"
                        maxLength={10}
                        value={textContent2}
                        onInput={(event) => {
                            useRender.setState({ textContent2: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onChange={(event) => {
                            useRender.setState({ textContent2: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onKeyDown={(event) => {
                            useRender.setState({ textContent2: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}></input>
                </div>
                <div className="mb-3">
                    <input
                        ref={inputRef}
                        className="text-center border rounded-lg p-2 w-full bg-transparent  border-black"
                        maxLength={10}
                        value={textContent3}
                        onInput={(event) => {
                            useRender.setState({ textContent3: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onChange={(event) => {
                            useRender.setState({ textContent3: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}
                        onKeyDown={(event) => {
                            useRender.setState({ textContent3: event.target.value })
                            let input = event.target
                            setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                        }}></input>
                </div>

                {/*  */}
                <select
                    className="p-2 rounded-lg bg-transparent border border-black"
                    onChange={(ev) => {
                        useRender.setState({ textFont: ev.target.value })
                    }}
                    defaultValue={textFont}>
                    {choices.map((c) => (
                        <option key={c.id} value={c.textFont}>
                            {c.displayName}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

function DiamondSizeSizeChoicesDesktop({ isMobile }) {
    let diamondSize = {}
    rings.forEach((ring) => {
        diamondSize[`${ring.ct}`] = ring.ct
    })

    let diamondSizes = Object.keys(diamondSize).slice().reverse()

    let ct = useRender((r) => r.ct)

    let className = `mr-4 mb-4 h-16 w-16 text-sm border flex items-center justify-center rounded-full border-black `

    return (
        <>
            {!isMobile && (
                <div className="text-center mt-12 mb-5" style={{ color: `rgba(203, 129, 118, 1)` }}>
                    Diamond Size
                </div>
            )}

            <div className="flex flex-row justify-center">
                <div className="w-10/12 flex flex-wrap">
                    {diamondSizes.map((r) => {
                        // console.log(r, hh)
                        let classNameYo = `${className} `

                        return (
                            <div
                                style={{
                                    backgroundColor: `${ct === r ? '#ffc7b1' : 'transparent'}`
                                }}
                                onClick={() => {
                                    useRender.setState({ ct: r })
                                    setTimeout(() => {
                                        useRender.setState({
                                            ringURL: rings.find(
                                                (r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`
                                            )?.url
                                        })
                                    })
                                }}
                                className={classNameYo}
                                key={'_' + r + 'size'}>
                                {r.replace('#', '')}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

function RingSizeChoicesDesktop({ isMobile }) {
    let fingerSize = {}
    rings.forEach((ring) => {
        fingerSize[`${ring.hh}`] = ring.hh
    })

    let ringSizes = Object.keys(fingerSize)

    let hh = useRender((r) => r.hh)

    let className = `mr-4 mb-4 h-16 w-16 text-sm border flex items-center justify-center rounded-full border-black `

    return (
        <>
            {!isMobile && (
                <div className="text-center mt-12 mb-5" style={{ color: `rgba(203, 129, 118, 1)` }}>
                    Ring Size
                </div>
            )}

            <div className="flex flex-row justify-center">
                <div className="w-10/12 flex flex-wrap">
                    {ringSizes.map((r) => {
                        console.log(r, hh)
                        let classNameYo = `${className} `

                        return (
                            <div
                                style={{
                                    backgroundColor: `${hh === r ? '#ffc7b1' : 'transparent'}`
                                }}
                                onClick={() => {
                                    useRender.setState({ hh: r })
                                    setTimeout(() => {
                                        useRender.setState({
                                            ringURL: rings.find(
                                                (r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`
                                            )?.url
                                        })

                                        // setTimeout(() => {
                                        //     let set1 = rings.filter((r) => r.ct === useRender.getState().ct)
                                        //     let set2 = rings.filter((r) => r.hh === useRender.getState().hh)

                                        //     let all = [...set1, ...set2]

                                        //     preloadRing(all)
                                        // })
                                    })
                                }}
                                className={classNameYo}
                                key={'_' + r + 'size'}>
                                {r.replace('#', '')}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
function MetalChoicesDesktop({ isMobile }) {
    return (
        <>
            <style
                __html={{
                    dangerouslySetInnerHTML: /* css */ `
        
        .metalChoice.active{
            background: white;
        }
        `
                }}></style>

            {!isMobile && (
                <div className="text-center mt-12 mb-5" style={{ color: `rgba(203, 129, 118, 1)` }}>
                    Metal
                </div>
            )}

            {!isMobile && (
                <div className="flex flex-col items-center">
                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[1].data, matID: presets[1]?.id })
                        }}
                        className="flex mb-4 items-center text-left bg-white rounded-full"
                        style={{ width: `300px`, padding: `10px` }}>
                        <div className="h-10 w-10 bg-gray-200 mr-4 rounded-full" style={{ backgroundColor: `#E9BF9C` }}></div> 18K Rose Gold
                    </button>

                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[0].data, matID: presets[0]?.id })
                        }}
                        className="flex mb-4 items-center text-left bg-white rounded-full"
                        style={{ width: `300px`, padding: `10px` }}>
                        <div className="h-10 w-10 bg-gray-200 mr-4 rounded-full" style={{ backgroundColor: `#E5D584` }}></div> 18K Yellow
                        Gold
                    </button>

                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[2].data, matID: presets[2]?.id })
                        }}
                        className="flex mb-4 items-center text-left bg-white rounded-full"
                        style={{ width: `300px`, padding: `10px` }}>
                        <div className="h-10 w-10 bg-gray-200 mr-4 rounded-full" style={{ backgroundColor: `#DFDDDA` }}></div> 18K White
                        Gold
                    </button>
                </div>
            )}

            {isMobile && (
                <div className="flex flex-wrap flex-col items-center">
                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[1].data, matID: presets[1]?.id })
                        }}
                        className="flex mb-3 mx-2 items-center text-left bg-white rounded-full"
                        style={{ width: `230px`, padding: `7px` }}>
                        <div className="h-5 w-5 bg-gray-200 mx-2 rounded-full" style={{ backgroundColor: `#E9BF9C` }}></div>
                        <div className="w-full text-center">18K Rose Gold</div>
                    </button>

                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[0].data, matID: presets[0]?.id })
                        }}
                        className="flex mb-3 mx-2 items-center text-left bg-white rounded-full"
                        style={{ width: `230px`, padding: `7px` }}>
                        <div className="h-5 w-5 bg-gray-200 mx-2 rounded-full" style={{ backgroundColor: `#E5D584` }}></div>
                        <div className="w-full text-center">18K Yellow Gold</div>
                    </button>

                    <button
                        onClick={() => {
                            useRender.setState({ ...presets[2].data, matID: presets[2]?.id })
                        }}
                        className="flex mb-3 mx-2 items-center text-left bg-white rounded-full"
                        style={{ width: `230px`, padding: `7px` }}>
                        <div className="h-5 w-5 bg-gray-200 mx-2 rounded-full" style={{ backgroundColor: `#DFDDDA` }}></div>
                        <div className="w-full text-center">18K White Gold</div>
                    </button>
                </div>
            )}
        </>
    )
}

function DesktopSteps() {
    let step = useRender((r) => r.step)
    let steps = useRender((r) => r.steps)

    return (
        <div style={{ width: `calc(430px)`, height: `100%` }}>
            <div className="h-8"></div>
            {steps.map((s) => {
                return (
                    <div
                        key={s.id + 'step'}
                        className={'desktop-steps flex items-center justify-start  ' + `${step === s.name ? 'active' : ''}`}
                        onClick={() => {
                            useRender.setState({ step: s.name })
                        }}>
                        <div className="desktop-line"></div>
                        <span className="ml-3">{s.displayName}</span>
                    </div>
                )
            })}
        </div>
    )
}

function MobileCanvas() {
    return (
        <>
            <Canvas gl={{ alpha: true, antialias: false, useLegacyLights: true }} shadows>
                <RenderContent></RenderContent>
            </Canvas>
        </>
    )
}
function MobileLayout() {
    let step = useRender((r) => r.step)
    let steps = useRender((r) => r.steps)
    let stepCurrent = steps.find((s) => s.name === step)
    let stepCurrentIdx = steps.findIndex((s) => s.name === step)
    let currentDisplayName = stepCurrent?.displayName

    let back = steps[stepCurrentIdx - 1]
    let forth = steps[stepCurrentIdx + 1]

    //  DesktopChoices
    return (
        <>
            <div className="block sm:hidden" style={{ height: `58px`, backgroundColor: 'white' }}>
                <div className="flex items-center justify-center w-full h-full">
                    <img src={`/gui/logo/logo.svg`} alt={'Chow Sang Sang'}></img>
                    {/* <img src={`/gui/logo/logo-desk.svg`} alt="Chow Sang Sang"></img> */}
                </div>
            </div>

            <div className="relative block sm:hidden " style={{ height: `25rem` }}>
                <MobileCanvas></MobileCanvas>

                <div className=" absolute bottom-0 left-0 w-full">
                    <div className="w-full">
                        <div className=" flex justify-center w-full mb-4">
                            <img src={`/gui/ui/rotate.svg`} className="h-8 block"></img>
                        </div>

                        <div className="flex w-full justify-between mb-6">
                            <div className="w-1/3 flex justify-start">
                                <img
                                    onClick={() => {
                                        if (back) {
                                            useRender.setState({ step: back.name })
                                        }
                                    }}
                                    className="mx-4 h-6"
                                    style={{ visibility: back ? `visible` : `hidden` }}
                                    src={`/gui/ui/left-arrow.svg`}></img>
                            </div>
                            <div className="w-1/3 text-center">{currentDisplayName}</div>
                            <div className="w-1/3 flex justify-end">
                                <img
                                    onClick={() => {
                                        if (forth) {
                                            useRender.setState({ step: forth.name })
                                        }
                                    }}
                                    className="mx-4 h-6"
                                    style={{ visibility: forth ? `visible` : `hidden` }}
                                    src={`/gui/ui/right-arrow.svg`}></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="border-t"
                    style={{
                        borderColor: `rgba(182, 150, 135, 1)`
                    }}>
                    <div className="h-8"></div>
                    <DesktopChoices isMobile={true}></DesktopChoices>
                    <div className="h-8"></div>
                </div>

                {forth && (
                    <button
                        className="fixed bottom-0 left-0 w-full"
                        style={{
                            color: ` rgba(255, 255, 255, 1)`,
                            height: `49px`,
                            background: `rgba(203, 129, 118, 1)`
                        }}
                        onClick={() => {
                            if (forth) {
                                useRender.setState({ step: forth.name })
                            } else {
                                //
                            }
                        }}>
                        Next
                    </button>
                )}
            </div>
        </>
    )
}

function Shard11GUIInside() {
    let [config, setConfig] = useState({
        ready: false
    })

    useEffect(() => {
        let resizer = () => {
            if (window.innerWidth <= 640) {
                setConfig({
                    ready: config.ready,
                    mobile: true
                    // parent: { height: `100%`, width: `calc(100%)` },
                    // left: { height: `60%`, width: `calc(100%)` },
                    // right: { height: `40%`, width: `calc(100%)` }
                })
            } else {
                setConfig({
                    ready: config.ready,
                    mobile: false
                })
            }
        }

        window.addEventListener('resize', resizer, false)
        dispatchEvent(new Event('resize'))
        return () => {
            window.removeEventListener('resize', resizer)
        }
    }, [config.ready])

    let [prog, setProg] = useState('0%')
    useEffect(() => {
        // if (useRender.getState().cached) {
        //     setConfig({
        //         ready: true
        //     })
        //     return
        // }

        // let preloadTasks = []

        // presets.forEach((r) => {
        //     preloadTasks.push(dynamicLoad(r.data.metalHDRURL, UVMapping))
        //     preloadTasks.push(dynamicLoad(r.data.metalMatCapURL, UVMapping))
        //     preloadTasks.push(dynamicLoad(r.data.diamondHDRURL, UVMapping))
        //     preloadTasks.push(dynamicLoad(r.data.diamondMatCapURL, UVMapping))
        // })

        // let set1 = rings.filter((r) => r.ct === useRender.getState().ct)
        // let set2 = rings.filter((r) => r.hh === useRender.getState().hh)

        // let all = [...set1, ...set2]

        // let arr = preloadRing(all)

        // preloadTasks = [...preloadTasks, ...arr]

        // preloadTasks.map((r) => {
        //     r.catch((r) => {
        //         console.log(r)
        //     })
        //     return r
        // })

        // let i = 0
        // preloadTasks.forEach((it, idx) => {
        //     it.then((r) => {
        //         setTimeout(() => {
        //             i++
        //             setProg(`${((i / (preloadTasks.length - 1)) * 100).toFixed(0)}%`)
        //         }, i * 5)
        //     })
        // })

        Promise.resolve().then((r) => {
            console.log(r)
            setConfig({
                ready: true
            })
            useRender.setState({
                cached: true
            })
        })
    }, [])

    if (!config.ready) {
        return (
            <>
                {!config.ready && (
                    <>
                        <div className="w-full h-full fixed top-0 left-0 bg-white bg-opacity-70 flex items-center justify-center">
                            <div className="py-5 px-12 flex flex-col items-center justify-center ">
                                <div>
                                    <img src={`/gui/logo/logo-desk.svg`} alt="Chow Sang Sang"></img>
                                </div>
                                <div className="mt-3">Loading... {prog}</div>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }
    return (
        <>
            {!config.mobile && <DesktopLayout key="desktop"></DesktopLayout>}
            {config.mobile && <MobileLayout key={'mobile'}></MobileLayout>}
            {/* <Preloader></Preloader> */}
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                        @font-face {
                        font-family: AlexBrush;
                        src: url('/font/alex-brush/AlexBrush-Regular.ttf');
                        }

                        @font-face {
                        font-family: Edwardian;
                        src: url('/font/itc-font/EdwardianScriptITC.ttf');
                        }

                        #preloadFont {
                        font-family: Edwardian;
                        }
                        

                `
                }}></style>

            <div id="preloadFont" style={{ display: 'none' }}>
                YOLO
            </div>

            {/* <div>
                <div className=" overflow-scroll">
                    <div className="w-full p-2">
                        <div className="">Ring Metal</div>

                        <SimpleMetalConf></SimpleMetalConf>
                        <div className="">Ring Dimensions</div>
                        <div className="">
                            <SimpleRingConf></SimpleRingConf>
                        </div>

                        <div className="">Engraving</div>
                        <div className="">
                            <SimpleEngraving></SimpleEngraving>
                        </div>
                        {!config.mobile && false && (
                            <>
                                <div className="">Environment</div>
                                <div className="">
                                    <EnvironmentConf></EnvironmentConf>
                                </div>

                                <div className="">Metal</div>
                                <div className="">
                                    <MetalConf></MetalConf>
                                </div>

                                <div className="">Diamond</div>
                                <div className="">
                                    <DiamondConf></DiamondConf>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div> */}
        </>
    )
}

function EnvironmentRunner() {
    let scene = useThree((state) => state.scene)
    useEffect(() => {
        scene.background = new Color(`#FEDACA`)
    })
    return <></>
}

function RenderContent() {
    // let s100 = useRender((r) => r.s100) || false
    let ringURL = useRender((r) => r.ringURL) || ''
    let r = '_' + (Math.random() * 10000000).toFixed(0)

    return (
        <>
            {/*  */}
            <Suspense fallback={null}>
                <group rotation={[Math.PI * 0.0, 0, 0]} castShadow>
                    <RingLoader key={r + ringURL}></RingLoader>
                </group>
                {/* <Caustics intensity={1} lightSource={[0, 5, 5]}>
        </Caustics> */}
            </Suspense>

            <EnvironmentRunner></EnvironmentRunner>
            <MetalEnv></MetalEnv>
            <DiamondEnv></DiamondEnv>
            <PP></PP>

            <PerspectiveCamera near={0.05} far={100} makeDefault></PerspectiveCamera>
            {/* <OrbitControls minDistance={4} object-position={[-3, 3, 3]} target={[0, 1.1, 0]}></OrbitControls> */}
            <OrbitCtrl></OrbitCtrl>
        </>
    )
}

function OrbitCtrl() {
    let step = useRender((r) => r.step)

    return (
        <>
            {step !== 'engrave' && (
                <OrbitControls
                    minDistance={0.5 + 4}
                    makeDefault
                    maxDistance={8}
                    object-position={[-3, 3, 3]}
                    enablePan={false}
                    target={[0, 1.1, 0]}></OrbitControls>
            )}

            {step === 'engrave' && (
                <OrbitControls
                    minDistance={0.2}
                    makeDefault
                    maxDistance={1.4}
                    enablePan={false}
                    object-position={[-0.3782187519173923, 0.30294158784580594 + 0.5 * 1.2, 0.004640271897898174 + 0.3 * 1.2]}
                    target={[-0.3782187519173923, 0.30294158784580594, 0.004640271897898174]}></OrbitControls>
            )}
        </>
    )
}
function DiamondEnv() {
    let [matcap, setMatCap] = useState(null)
    let [hdr, setHDR] = useState(null)

    let diamondMatCapURL = useRender((r) => r.diamondMatCapURL)
    useEffect(() => {
        dynamicLoad(diamondMatCapURL, UVMapping).then((texure) => {
            setMatCap(texure)
        })
    }, [diamondMatCapURL])

    let diamondHDRURL = useRender((r) => r.diamondHDRURL)
    useEffect(() => {
        dynamicLoad(diamondHDRURL, EquirectangularReflectionMapping).then((texure) => {
            setHDR(texure)
        })
    }, [diamondHDRURL])

    let diamondLighntess = useRender((r) => r.diamondLighntess)
    let diamondRainbow = useRender((r) => r.diamondRainbow)
    let diamondContrast = useRender((r) => r.diamondContrast)

    let diamondEnvMapIntensity = useRender((r) => r.diamondEnvMapIntensity)
    let diamondUniforms = useMemo(() => {
        return {
            time: { value: 0 },
            matcap: { value: matcap },
            hdrTexture: { value: hdr },
            diamondEnvMapIntensity: { value: diamondEnvMapIntensity }
        }
    }, [matcap, hdr])
    let { envMap: computedRainbowEnv } = useComputeEnvMap(
        `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  // float pattern (vec2 p, float time) {
  //   float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
  //   return abs(vout);
  // }


  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm4(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;
  uniform sampler2D matcap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }

  uniform float diamondEnvMapIntensity;
  vec4 mainImage ()  {


    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, uvMC );

    vec4 hdrV4 = matcapColor;// texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 3.3, 0.1), 2.0);

    outColor.rgb = vec3(
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 + ${diamondRainbow.toFixed(3)}, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 - ${diamondRainbow.toFixed(3)}, time * 0.05)
    );

    outColor.r = pow(outColor.r * 0.4, ${diamondContrast.toFixed(3)});
    outColor.g = pow(outColor.g * 0.4, ${diamondContrast.toFixed(3)});
    outColor.b = pow(outColor.b * 0.4, ${diamondContrast.toFixed(3)});

    outColor.rgb = mix(hdrV4.rgb , outColor.rgb, 0.3);
    outColor.rgb  = czm_saturation(outColor.rgb, 0.1);

    return outColor * diamondEnvMapIntensity;
  }

  `,
        diamondUniforms,
        128,
        true
    )

    useEffect(() => {
        if (computedRainbowEnv) {
            useRender.setState({ computedRainbowEnv: computedRainbowEnv })
        }
    }, [computedRainbowEnv])
}
let GCache = new Map()
function MetalEnv() {
    let [matcap, setMatCap] = useState(null)
    let [hdr, setHDR] = useState(null)

    let metalMatCapURL = useRender((r) => r.metalMatCapURL)
    useEffect(() => {
        if (!GCache.has(metalMatCapURL)) {
            GCache.set(metalMatCapURL, dynamicLoad(metalMatCapURL, UVMapping))
        }
        GCache.get(metalMatCapURL).then((texure) => {
            setMatCap(texure)
        })
    }, [metalMatCapURL])

    let metalHDRURL = useRender((r) => r.metalHDRURL)
    useEffect(() => {
        if (!GCache.has(metalHDRURL)) {
            GCache.set(metalHDRURL, dynamicLoad(metalHDRURL, EquirectangularReflectionMapping))
        }
        GCache.get(metalHDRURL).then((texure) => {
            setHDR(texure)
        })

        // dynamicLoad(metalHDRURL, EquirectangularReflectionMapping).then((texure) => {
        //     setHDR(texure)
        // })
    }, [metalHDRURL])

    let metalRotY = useRender((r) => r.metalRotY)
    let metalHdrMatCapRatio = useRender((r) => r.metalHdrMatCapRatio)

    let brightness = useRender((r) => r.brightness)
    let contrast = useRender((r) => r.contrast)
    let saturation = useRender((r) => r.saturation)

    let metalUniforms = useMemo(() => {
        return {
            metalHdrMatCapRatio: { value: metalHdrMatCapRatio },
            time: { value: 0 },
            matcap: { value: matcap },
            metalEnvMap: { value: hdr },
            envLightIntensity: { value: 1 },
            rotY: { value: metalRotY },
            brightness: { value: brightness },
            contrast: { value: contrast },
            saturation: { value: saturation }
        }
    }, [hdr, matcap])

    let { envMap: computedMetalEnv } = useComputeEnvMap(
        `

    const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

    float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
    }

    float fbm4( vec2 p ) {
        float f = 0.0;
        f += 0.5000 * noise( p ); p = m * p * 2.02;
        f += 0.2500 * noise( p ); p = m * p * 2.03;
        f += 0.1250 * noise( p ); p = m * p * 2.01;
        f += 0.0625 * noise( p );
        return f / 0.9375;
    }

    float fbm6( vec2 p ) {
        float f = 0.0;
        f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
        f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
        f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
        f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
        f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
        f += 0.015625*(0.5 + 0.5 * noise( p ));
        return f/0.96875;
    }

    float pattern (vec2 p, float time) {
        float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
        return abs(vout);
    }

    uniform sampler2D hdrTexture;
    uniform float envLightIntensity;
    varying vec3 vWorldDirection;
    varying vec3 vPos;
    #define RECIPROCAL_PI 0.31830988618
    #define RECIPROCAL_PI2 0.15915494

    uniform float time;
    uniform float rotY;
    uniform float brightness;
    uniform float contrast;
    uniform float saturation;

    uniform sampler2D matcap;

    uniform sampler2D metalEnvMap;

    mat3 rotateY(float rad) {
        float c = cos(rad);
        float s = sin(rad);
        return mat3(
            c, 0.0, -s,
            0.0, 1.0, 0.0,
            s, 0.0, c
        );
    }
    mat4 brightnessMatrix( float brightness )
    {
        return mat4( 1, 0, 0, 0,
                    0, 1, 0, 0,
                    0, 0, 1, 0,
                    brightness, brightness, brightness, 1 );
    }

    mat4 contrastMatrix( float contrast )
    {
        float t = ( 1.0 - contrast ) / 2.0;

        return mat4( contrast, 0, 0, 0,
                    0, contrast, 0, 0,
                    0, 0, contrast, 0,
                    t, t, t, 1 );

    }

    mat4 saturationMatrix( float saturation )
    {
        vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );

        float oneMinusSat = 1.0 - saturation;

        vec3 red = vec3( luminance.x * oneMinusSat );
        red+= vec3( saturation, 0, 0 );

        vec3 green = vec3( luminance.y * oneMinusSat );
        green += vec3( 0, saturation, 0 );

        vec3 blue = vec3( luminance.z * oneMinusSat );
        blue += vec3( 0, 0, saturation );

        return mat4( red,     0,
                    green,   0,
                    blue,    0,
                    0, 0, 0, 1 );
    }

    uniform float metalHdrMatCapRatio;
    /**
     * Adjusts the saturation of a color.
     *
     * @name czm_saturation
     * @glslFunction
     *
     * @param {vec3} rgb The color.
     * @param {float} adjustment The amount to adjust the saturation of the color.
     *
     * @returns {float} The color with the saturation adjusted.
     *
     * @example
     * vec3 greyScale = czm_saturation(color, 0.0);
     * vec3 doubleSaturation = czm_saturation(color, 2.0);
     */
    vec3 czm_saturation(vec3 rgb, float adjustment)
    {
        // Algorithm from Chapter 16 of OpenGL Shading Language
        const vec3 W = vec3(0.2125, 0.7154, 0.0721);
        vec3 intensity = vec3(dot(rgb, W));
        return mix(intensity, rgb, adjustment);
    }
    vec4 mainImage ()  {
        vec3 direction = normalize( vWorldDirection * rotateY(rotY));
        vec2 uv;
        uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
        uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;


        vec4 outColor;
        outColor.a = 1.0;

        vec3 viewDir = normalize( vViewPosition );
        vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
        vec3 y = cross( viewDir, x );
        vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

        vec4 matcapColor = texture2D( matcap, vec2(uvMC.x, uvMC.y) );
        // vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2( uvMC.y, 1.0 - uvMC.x));
        vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2(uv));


        outColor.rgb = mix(matcapColor.rgb, hdrTextureC4.rgb, metalHdrMatCapRatio);

        outColor = brightnessMatrix( brightness ) *
            contrastMatrix( contrast ) *
            saturationMatrix( saturation ) *
            outColor;

        return outColor;
    }

  `,
        metalUniforms,
        128,
        true
    )
    useEffect(() => {
        useRender.setState({ computedMetalEnv: computedMetalEnv })
    }, [computedMetalEnv])

    return null
}

let dynamicLoad = async (texURL, mapping = EquirectangularReflectionMapping) => {
    if (!texURL) {
        return
    }
    if (texURL.includes('.hdr')) {
        let hdr = new RGBELoader()
        let texture = await hdr.loadAsync(texURL)
        texture.mapping = mapping
        texture.flipY = true

        return texture
    } else if (texURL.includes('.exr')) {
        let exr = new EXRLoader()
        let texture = await exr.loadAsync(texURL)
        texture.mapping = mapping
        texture.flipY = true

        return texture
    } else {
        let load = new TextureLoader()
        let texture = await load.loadAsync(texURL)
        texture.encoding = sRGBEncoding
        texture.colorSpace = SRGBColorSpace
        texture.mapping = mapping
        texture.flipY = true

        return texture
    }
}

// function EnvironmentRunner() {
//     // let environmentMapURL = useRender((r) => r.environmentMapURL) || ''
//     // let scene = useThree((r) => r.scene)
//     // useEffect(() => {
//     //   dynamicLoad(environmentMapURL).then((texure) => {
//     //     if (texure) {
//     //       scene.environment = texure
//     //     }
//     //   })
//     // }, [scene, environmentMapURL])

//     return (
//         <>
//             <color attach="background" args={['#f0f0f0']}></color>
//         </>
//     )
// }

let RingCacheURL = new Map()
function RingLoader() {
    let ringURL = useRender((r) => r.ringURL) || ''
    let [url, setURL] = useState(false)

    useEffect(() => {
        //
        if (ringURL.includes('.zip')) {
            if (RingCacheURL.has(ringURL)) {
                setURL(RingCacheURL.get(ringURL))
                return
            }

            fetch(ringURL)
                .then((res) => {
                    return res.arrayBuffer()
                })
                .then((file) => {
                    //
                    return unzip(file).then(async (r) => {
                        let entries = r.entries
                        let values = Object.values(entries)
                        // let obj = values.find((it) => it.name.includes('.obj'))
                        let obj = values.find((it) => (it?.name || '').includes('.obj') || (it?.name || '').includes('.glb'))
                        let myblob = await obj.blob()
                        let link = URL.createObjectURL(myblob) + `#t=.obj`

                        RingCacheURL.set(ringURL, link)
                        setURL(link)
                    })
                })

            // setURL(false)
        } else {
            setURL(ringURL)
        }
    }, [ringURL])

    let [iPadFound, setDetect] = useState(true)

    useEffect(() => {
        if (window.innerWidth <= 640) {
            setDetect(false)
        }
        if ('ontouchstart' in window) {
        } else {
            setDetect(false)
        }
    }, [])

    return url ? (
        <>
            {iPadFound ? (
                <></>
            ) : (
                <>
                    <AccumulativeShadows frames={200} scale={10} position={[0, -0.01, 0]}>
                        <RandomizedLight frames={200} position={[0, 5, 5]}></RandomizedLight>
                    </AccumulativeShadows>
                </>
            )}
            <RingLoaderInside ringURL={url}></RingLoaderInside>
        </>
    ) : (
        <group rotation={[0, Math.PI * -0.25, 0]}>
            <LookAt>
                <Text color={'rgb(203, 129, 118)'} position={[0, 1.0, 0]} fontSize={0.25}>
                    Loading...
                </Text>
            </LookAt>
        </group>
    )
}

function LookAt({ children }) {
    let ref = useRef()
    useFrame(({ camera }) => {
        ref.current.lookAt(camera.position)
    })
    return <group ref={ref}>{children}</group>
}

function getRotationFromNormalVector(normal) {
    // Normalize the normal vector
    const surfaceNormal = normal.clone().normalize()

    // Calculate the rotation matrix to align the cube with the surface normal
    const targetVector = new Vector3(0, 0, 1) // The desired facing direction of the cube
    const axis = new Vector3().crossVectors(targetVector, surfaceNormal).normalize()
    const angle = Math.acos(targetVector.dot(surfaceNormal))

    // Create a Quaternion representing the rotation
    const quaternion = new Quaternion().setFromAxisAngle(axis, angle)

    // Convert the Quaternion to Euler angles
    const euler = new Euler().setFromQuaternion(quaternion)

    // Return the rotation as Euler angles
    return { euler, quaternion }
}

/*
`/gui/svg/svgs/c1svg.svg`
`/gui/svg/svgs/csssvg.svg`
`/gui/svg/svgs/emphasissvg.svg`
`/gui/svg/svgs/tide-circlesvg.svg`
`/gui/svg/svgs/tidesvg.svg`
*/

function RingLoaderInside({ ringURL }) {
    let sceneLoaded = false

    if (ringURL.includes('.obj')) {
        sceneLoaded = useLoader(OBJLoader, ringURL)
    } else if (ringURL.includes('.glb')) {
        let gltf = useGLTF(ringURL)
        sceneLoaded = gltf.scene.clone(true)
        sceneLoaded = clone(sceneLoaded)
        sceneLoaded.traverse((it) => {
            if (it.geometry) {
                it.geometry.computeVertexNormals()
            }
        })
    }

    let diamondName = false
    let others = []

    let computedRainbowEnv = useRender((r) => r.computedRainbowEnv)
    let computedMetalEnv = useRender((r) => r.computedMetalEnv)

    {
        sceneLoaded.traverse((it) => {
            if (it.isMesh) {
                if (!it.geometry.scaled) {
                    it.geometry.scale(0.1, 0.1, 0.1)
                    it.geometry.scaled = true
                }
                it.castShadow = true
                it.receiveShadow = true
                //Diamond
                if (it?.material?.name?.includes('Diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it?.material?.name?.toLowerCase()?.includes('diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it?.material?.name?.includes('Diamond') && !diamondName) {
                    diamondName = it.name
                } else if (it.name.toLowerCase().includes('diamond') && !diamondName) {
                    diamondName = it.name
                } else {
                    others.push(it)
                }
            }
        })
    }

    /*
        `/gui/svg/svgs/c1svg.svg`
        `/gui/svg/svgs/csssvg.svg`
        `/gui/svg/svgs/emphasissvg.svg`
        `/gui/svg/svgs/tide-circlesvg.svg`
        `/gui/svg/svgs/tidesvg.svg`
    */

    // console.log(diamondName)

    let items = []

    let metalEnvMapIntensity = useRender((r) => r.metalEnvMapIntensity)
    let metalTint = useRender((r) => r.metalTint)
    let metalRoughness = useRender((r) => r.metalRoughness)

    let addedEngrave = 0

    function MyDecal({}) {
        let [canvasTexture, setCT] = useState(false)
        let ref = useRef()
        let textColor = useRender((r) => r.textColor)
        let textContent = useRender((r) => r.textContent)
        let textContent2 = useRender((r) => r.textContent2)
        let textContent3 = useRender((r) => r.textContent3)
        let textFont = useRender((r) => r.textFont)
        let textSize = useRender((r) => r.textSize)
        let textLogoURL = useRender((r) => r.textLogoURL)
        useEffect(() => {
            //
            let htmlEl = document.createElement('div')
            let text = document.createElement('span')
            let image = document.createElement('img')
            let text2 = document.createElement('span')
            let text3 = document.createElement('span')

            htmlEl.style.width = '2048px'
            htmlEl.style.height = '256px'
            htmlEl.style.position = 'fixed'
            htmlEl.style.display = 'flex'
            htmlEl.style.justifyContent = 'center'
            htmlEl.style.alignItems = 'center'
            htmlEl.style.position = 'fixed'
            htmlEl.style.left = '-2048px'
            htmlEl.style.top = '-2048px'
            htmlEl.style.zIndex = `-1`

            text.style.color = 'white'
            text.style.fontSize = `${textSize * 1.5}px`
            text.style.fontFamily = `${textFont}`
            text.style.margin = `0px 15px`

            text2.style.color = 'white'
            text2.style.fontSize = `${textSize * 1.5}px`
            text2.style.fontFamily = `${textFont}`
            text2.style.margin = `0px 15px`

            text3.style.color = 'white'
            text3.style.fontSize = `${textSize * 1.5}px`
            text3.style.fontFamily = `${textFont}`
            text3.style.margin = `0px 15px`

            image.style.width = `100px`
            image.style.height = `100px`
            image.style.objectFit = 'contain'
            image.style.transform = 'translate(0px , 45px)'

            document.body.appendChild(htmlEl)

            let render = async () => {
                // let canvas = document.createElement('canvas')
                // canvas.width = 1024
                // canvas.height = 128
                // let ctx = canvas.getContext('2d')
                // ctx.fillStyle = 'rgba(0,0,0,0.0)'

                // ctx.fillRect(0, 0, 1024, 128)
                // ctx.font = `${textSize}px ${textFont}`
                // ctx.fillStyle = 'white'
                // ctx.textBaseline = 'middle'
                // ctx.textAlign = 'center'
                // ctx.fillText(textContent, 1024 / 2, 128 / 2, 1024)

                //    let ct = new CanvasTexture(canvas)
                //    ct.anisotropy = 16
                //    ct.colorSpace = SRGBColorSpace
                //    ct.needsUpdate = true
                //    setCT(ct)

                {
                    /*
                        `/gui/svg/svgs/c1svg.svg`
                        `/gui/svg/svgs/csssvg.svg`
                        `/gui/svg/svgs/emphasissvg.svg`
                        `/gui/svg/svgs/tide-circlesvg.svg`
                        `/gui/svg/svgs/tidesvg.svg`
                    */
                }

                await new Promise((resolve) => {
                    image.onload = () => {
                        resolve()
                    }

                    text.innerText = textContent
                    text2.innerText = textContent2
                    text3.innerText = textContent3

                    if (textLogoURL === 'hidden') {
                        resolve()
                        htmlEl.appendChild(text)
                        htmlEl.appendChild(text2)
                        htmlEl.appendChild(text3)
                    } else {
                        image.src = textLogoURL
                        htmlEl.appendChild(text)
                        htmlEl.appendChild(image)
                        htmlEl.appendChild(text2)
                        htmlEl.appendChild(text3)
                    }
                })

                let canvas = await html2canvas(htmlEl, {
                    foreignObjectRendering: false,
                    removeContainer: true,
                    backgroundColor: 'transparent'
                }).catch((r) => {
                    console.log(r)
                    return false
                })
                if (!canvas) {
                    return
                }

                let ct = new CanvasTexture(canvas)
                ct.anisotropy = 16
                ct.colorSpace = SRGBColorSpace
                ct.needsUpdate = true
                setCT(ct)
            }

            Promise.resolve().then(async () => {
                if (window.loadedFont) {
                    render()
                    return
                }
                await new FontFace('Edwardian', 'url(/font/itc-font/EdwardianScriptITC.ttf)').load().then(() => {
                    return new Promise((r) => setTimeout(r, 350))
                })
                window.loadedFont = true
                render()
            })

            return () => {
                document.body.removeChild(htmlEl)
            }
        }, [textColor, textSize, textContent, textContent2, textContent3, textLogoURL, textFont])

        useEffect(() => {
            if (ref.current && ref.current.geometry && !ref.current.geometry.translated) {
                ref.current.geometry.translated = true
                ref.current.geometry.translate(0, 0.005, 0)
            }
        }, [canvasTexture])

        let noiseTexture = useTexture(`/T_NoiseNormal_76.png`)
        noiseTexture.wrapS = RepeatWrapping
        noiseTexture.wrapT = RepeatWrapping
        noiseTexture.repeat.set(1 * 50, 0.1 * 50)
        noiseTexture.needsUpdate = true

        return canvasTexture ? (
            <Decal
                ref={ref}
                debug={false}
                scale={[(canvasTexture.image.width / canvasTexture.image.height) * 0.1, 0.1, 1]}
                // scale={[0.5, 0.06, 1]}
                position={[-0.3782187519173923 - 0.11, 0.13, 0.004640271897898174 + -0.02].map((r) => r)}
                rotation={[-Math.PI * 0.5, 0.5, 0.0]}>
                <meshStandardMaterial
                    color={textColor}
                    envMap={computedMetalEnv}
                    envMapIntensity={metalEnvMapIntensity}
                    roughness={metalRoughness}
                    metalness={1}
                    map={canvasTexture}
                    normalScale={[0.15, 0.15]}
                    normalMap={noiseTexture}
                    metalnessMap={noiseTexture}
                    transparent></meshStandardMaterial>
            </Decal>
        ) : null
    }
    let step = useRender((r) => r.step)

    {
        computedMetalEnv &&
            sceneLoaded.traverse((it) => {
                if (it.isMesh) {
                    if (it.name !== diamondName) {
                        // console.log(it.name)

                        if (it.geometry) {
                            console.log(addedEngrave)

                            //
                            items.push(
                                <group key={it.uuid}>
                                    <mesh
                                        onClick={(ev) => {
                                            if (ev.normal) {
                                                let { euler } = getRotationFromNormalVector(ev.normal)

                                                console.log(ev.point.toArray(), euler.toArray())
                                            }
                                        }}
                                        geometry={it.geometry}
                                        position={it.position.toArray()}
                                        scale={it.scale.toArray()}
                                        rotation={it.rotation}>
                                        <meshStandardMaterial
                                            // matcap={matcap || computedMetalEnv}
                                            envMap={computedMetalEnv}
                                            envMapIntensity={metalEnvMapIntensity}
                                            color={metalTint}
                                            roughness={metalRoughness}
                                            metalness={1}
                                        />

                                        {addedEngrave == 5 && step === 'engrave' && <MyDecal key={it.uuid}></MyDecal>}
                                    </mesh>

                                    {/* {createPortal(
                        <meshStandardMaterial
                          // matcap={matcap || computedMetalEnv}
                          envMap={computedMetalEnv}
                          envMapIntensity={metalEnvMapIntensity}
                          color={metalTint}
                          roughness={metalRoughness}
                          metalness={1}
                        />,
                        it
                      )} */}
                                </group>
                            )

                            addedEngrave++
                        }
                    }
                }
            })
    }

    const diamondConfig = {
        diamondColor: '#ffffff',
        lightColor: '#ffeecc',
        roughness: 0.1,
        aberrationStrength: 0.01,
        ior: 2.418,
        metalness: 1
    }

    let light1 = useRef()
    let light2 = useRef()

    let diamondTint = useRender((r) => r.diamondTint)
    useEffect(() => {
        useRender.setState({ meshes: [sceneLoaded.getObjectByName(diamondName)], lights: [light1.current, light2.current] })
    }, [])
    let r = useMemo(() => {
        return '_' + (Math.random() * 10000000).toFixed(0)
    }, [])

    // if (sceneLoaded.getObjectByName(diamondName)) {
    //   sceneLoaded.getObjectByName(diamondName).material = new MeshBasicMaterial({})
    // }

    return (
        <>
            {computedRainbowEnv &&
                createPortal(
                    <RefractionMaterialCustomCircle
                        uniq={ringURL + r}
                        key={DiamondMaterialCustomCircleKey + r + ringURL}
                        envMap={computedRainbowEnv}
                        {...diamondConfig}
                        color={diamondTint}
                        fastChroma={false}
                        bounces={1}
                        fresnel={0}
                        toneMapped={false}
                    />,
                    sceneLoaded.getObjectByName(diamondName)
                )}

            <directionalLight
                ref={light1}
                intensity={1}
                color={diamondConfig.lightColor}
                target-position={[0, 0, 0]}
                position={[5, 5, -5]}></directionalLight>
            <directionalLight
                ref={light2}
                intensity={1}
                color={diamondConfig.lightColor}
                target-position={[0, 0, 0]}
                position={[-5, 5, -5]}></directionalLight>
            {items}
            {sceneLoaded && <primitive key={sceneLoaded.uuid} object={sceneLoaded}></primitive>}
        </>
    )
}

let links = [
    `/Hdri_reflection/studio18b.png`,
    `/matcap2k/bw.png`,
    `/matcap2k/rose-gold@2x.png`,
    `/matcap2k/yellow@2x.png `,

    `/Hdri_reflection/059.hdr`,
    `/Hdri_reflection/background_D001.jpg`,
    `/Hdri_reflection/background_D002.jpg`,
    `/Hdri_reflection/background_D003.jpg`,
    `/Hdri_reflection/background_D004.jpg`,
    `/Hdri_reflection/Flourecent-Lights.hdr`,
    `/Hdri_reflection/hdri_1.jpg`,
    `/Hdri_reflection/hdri_2.jpg`,
    `/Hdri_reflection/hdri_3_1.jpg`,
    `/Hdri_reflection/hdri_3_2.jpg`,
    `/Hdri_reflection/hdri_3.jpg`,
    `/Hdri_reflection/hdri_4_1.jpg`,
    `/Hdri_reflection/hdri_4.jpg`,
    `/Hdri_reflection/hdri_5.jpg`,
    `/Hdri_reflection/hdri_6.jpg`,
    `/Hdri_reflection/hdri_7.jpg`,
    `/Hdri_reflection/hdri_8.jpg`,
    `/Hdri_reflection/hdri_9.jpg`,
    `/Hdri_reflection/hdri_10.jpg`,
    `/Hdri_reflection/hdri_11.jpg`,
    `/Hdri_reflection/hdri_12.jpg`,
    `/Hdri_reflection/hdri_13.jpg`,
    `/Hdri_reflection/hdri_14.jpg`,
    `/Hdri_reflection/hdri_15.jpg`,
    `/Hdri_reflection/hdri_16.jpg`,
    `/Hdri_reflection/hdri_17.jpg`,
    `/Hdri_reflection/hdri_18.jpg`,
    `/Hdri_reflection/hdri_19.jpg`,
    `/Hdri_reflection/hdri_20.jpg`,
    `/Hdri_reflection/hdri_21.jpg`,
    `/Hdri_reflection/hdri_22.jpg`,
    `/Hdri_reflection/hdri_23.jpg`,
    `/Hdri_reflection/hdri_24.jpg`,
    `/Hdri_reflection/hdri_25.jpg`,
    `/Hdri_reflection/hdri_26.jpg`,
    `/Hdri_reflection/hdri_27.jpg`,
    `/Hdri_reflection/hdri_28.jpg`,
    `/Hdri_reflection/hdri_29.jpg`,
    `/Hdri_reflection/hdri_30.jpg`,
    `/Hdri_reflection/hdri_31.jpg`,
    `/Hdri_reflection/hdri_32.jpg`,
    `/Hdri_reflection/hdri_33.jpg`,
    `/Hdri_reflection/hdri_slotroom01d_sml2.hdr`,
    `/Hdri_reflection/hdri-active.jpeg`,
    `/Hdri_reflection/sky0031.hdr`,
    `/Hdri_reflection/studio001.hdr`,
    `/Hdri_reflection/studio004.hdr`,
    `/Hdri_reflection/studio007.hdr`,
    `/Hdri_reflection/studio008.hdr`,
    `/Hdri_reflection/studio016.hdr`,
    `/Hdri_reflection/studio018.hdr`,
    `/Hdri_reflection/studio022.hdr`,
    `/Hdri_reflection/studio025.hdr`,
    `/Hdri_reflection/studio028.hdr`
]

let hdrOptions = {}
links.forEach((link) => {
    hdrOptions[link] = link
})

function EnvironmentConf() {
    let refDiv = useRef()

    /*

  let brightness = useRender((r) => r.brightness)
  let contrast = useRender((r) => r.contrast)

  let hue = useRender((r) => r.hue)
  let saturation = useRender((r) => r.saturation)
  */
    useEffect(() => {
        //
        const PARAMS = useRender.getState()
        //
        const pane = new Pane({
            container: refDiv.current
        })

        pane.addInput(PARAMS, 'smoothGlow', {}).on('change', (v) => {
            useRender.setState({
                smoothGlow: PARAMS.smoothGlow,
                //
                brightness: PARAMS.brightness,
                contrast: PARAMS.contrast,
                hue: PARAMS.hue,
                saturation: PARAMS.saturation
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'glowDetect', {
            min: 0,
            max: 1
        }).on('change', (v) => {
            useRender.setState({
                glowDetect: PARAMS.glowDetect
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'intensity', { min: 0, max: 5 }).on('change', (v) => {
            useRender.setState({
                intensity: PARAMS.intensity
            })
            pane.refresh()
        })

        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

function SimpleEngraving() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        const pane = new Pane({
            container: refDiv.current
        })

        let st = { ...useRender.getState() }

        pane.addInput(st, 'textColor', {}).on('change', (ev) => {
            useRender.setState({ textColor: st.textColor })
        })
        pane.addInput(st, 'textFont', {
            options: {
                'Arial / 楷書': `Arial, Times New Roman`,
                'Times New Roman / 楷書': `Times New Roman, "DFKai-sb"`,
                'Edwardian Script / 楷書': `Edwardian`
            }
        }).on('change', (ev) => {
            useRender.setState({ textFont: st.textFont })
        })
        // pane.addInput(st, 'textSize', {
        //   value: 128,
        //   max: 130,
        //   min: 100,
        // }).on('change', (ev) => {
        //   useRender.setState({ textSize: st.textSize })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])

    let textContent = useRender((r) => r.textContent)

    let inputRef = useRef()

    // function getCursor () {
    //   if ('selectionStart' in input) {
    //     // Standard-compliant browsers
    //     return input.selectionStart;
    // } else if (document.selection) {
    //     // IE
    //     input.focus();
    //     var sel = document.selection.createRange();
    //     var selLen = document.selection.createRange().text.length;
    //     sel.moveStart('character', -input.value.length);
    //     return sel.text.length - selLen;
    // }
    // }

    let [csr, setCSR] = useState({ start: 0, end: 0 })
    return (
        <>
            <div className=" m-2">
                <input
                    ref={inputRef}
                    className="text-center border rounded-lg p-2 w-full"
                    maxLength={10}
                    value={textContent}
                    onInput={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}
                    onChange={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}
                    onKeyDown={(event) => {
                        useRender.setState({ textContent: event.target.value })
                        let input = event.target
                        setCSR({ start: input.selectionStart + 1, end: input.selectionEnd })
                    }}></input>
            </div>

            <div>Add Symbol</div>
            <div className="mb-3">
                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white mb-3"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `♡` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    ♡
                </button>

                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white mb-3"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `♥` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    ♥
                </button>
                <button
                    className="p-2 m-1 px-4 rounded-xl bg-white"
                    onClick={() => {
                        useRender.setState((r) => {
                            let newText = r.textContent.slice(0, csr.start) + `&` + r.textContent.slice(csr.end)
                            return {
                                ...r,
                                textContent: newText
                            }
                        })
                        inputRef.current.focus()
                    }}>
                    &
                </button>
            </div>
            <div ref={refDiv}></div>
        </>
    )
}

function SimpleRingConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        const pane = new Pane({
            container: refDiv.current
        })

        let diamondSize = {}

        rings.forEach((ring) => {
            diamondSize[`${ring.ct}`] = ring.ct
        })

        pane.addInput(useRender.getState(), 'ct', {
            options: {
                ...diamondSize
            },
            label: 'DiamondSize'
        }).on('change', (v) => {
            useRender.setState({
                ['ct']: v
            })

            // pane.refresh()

            setTimeout(() => {
                useRender.setState({
                    ringURL: rings.find((r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`)?.url
                })
            })
        })

        //

        let fingerSize = {}
        rings.forEach((ring) => {
            fingerSize[`${ring.hh}`] = ring.hh
        })

        pane.addInput(useRender.getState(), 'hh', {
            options: {
                ...fingerSize
            },
            label: 'FingerSize'
        }).on('change', (v) => {
            useRender.setState({
                ['hh']: v
            })

            // pane.refresh()

            useRender.setState({
                ringURL: rings.find((r) => r.ct === `${useRender.getState().ct}` && r.hh === `${useRender.getState().hh}`)?.url
            })

            // console.log(v)

            // console.log(v)
            // useRender.setState({
            //   ['ringURL']: v
            // })
        })

        // rings.forEach((ring) => {
        //   pane
        //     .addButton({
        //       title: ring.name
        //     })
        //     .on('click', () => {
        //       useRender.setState({
        //         s100: ring.s100 || false,
        //         ringURL: ring.url
        //       })
        //     })
        // })

        // pane
        //   .addButton({
        //     title: ''
        //   })
        //   .on('click', () => {
        //     useRender.setState({})
        //   })
        // pane
        // .addButton({
        //   title: 'platinum'
        // })
        // .on('click', () => {
        //   useRender.setState({ metalTint: '#ffffff' })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

function SimpleMetalConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        //

        const pane = new Pane({
            container: refDiv.current
        })

        pane.addButton({
            title: 'Yellow Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[0].data, matID: presets[0]?.id })
        })

        pane.addButton({
            title: 'Rose Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[1].data, matID: presets[1]?.id })
        })

        pane.addButton({
            title: 'White Gold'
        }).on('click', () => {
            useRender.setState({ ...presets[2].data, matID: presets[2]?.id })
        })
        // pane
        // .addButton({
        //   title: 'platinum'
        // })
        // .on('click', () => {
        //   useRender.setState({ metalTint: '#ffffff' })
        // })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

function MetalConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)
    let ringURL = useRender((r) => r.ringURL)
    useEffect(() => {
        //
        const PARAMS = useRender.getState()
        // {
        //   metalMatCapURL: useRender.getState().metalMatCapURL,
        //   metalHDRURL: useRender.getState().metalHDRURL,
        //   metalHdrMatCapRatio: useRender.getState().metalHdrMatCapRatio,
        //   metalTint: useRender.getState().metalTint,
        //   metalEnvMapIntensity: useRender.getState().metalEnvMapIntensity,
        //   metalRotY: useRender.getState().metalRotY,
        //   //
        //   brightness: useRender.getState().brightness,
        //   contrast: useRender.getState().contrast,
        //   saturation: useRender.getState().saturation
        // }

        hdrOptions[PARAMS.metalHDRURL] = PARAMS.metalHDRURL
        hdrOptions[PARAMS.metalMatCapURL] = PARAMS.metalMatCapURL

        const pane = new Pane({
            container: refDiv.current
        })

        pane.addInput(PARAMS, 'brightness', {
            min: -1,
            max: 1
        }).on('change', (v) => {
            useRender.setState({
                brightness: PARAMS.brightness
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'contrast', {
            min: -5,
            max: 5
        }).on('change', (v) => {
            useRender.setState({
                contrast: PARAMS.contrast
            })
            pane.refresh()
        })

        pane.addInput(PARAMS, 'saturation', {
            min: -5,
            max: 5
        }).on('change', (v) => {
            useRender.setState({
                saturation: PARAMS.saturation
            })
            pane.refresh()
        })

        pane.addSeparator()

        // pane
        //   .addInput(PARAMS, 'hue', {
        //     min: -1,
        //     max: 1
        //   })
        //   .on('change', (v) => {
        //     useRender.setState({
        //       hue: PARAMS.hue
        //     })
        //     pane.refresh()
        //   })

        pane.addInput(PARAMS, 'metalMatCapURL', {
            options: hdrOptions
        }).on('change', () => {
            //
            useRender.setState({ metalMatCapURL: PARAMS.metalMatCapURL })
        })

        pane.addInput(PARAMS, 'metalHDRURL', {
            options: hdrOptions
        }).on('change', () => {
            //
            useRender.setState({ metalHDRURL: PARAMS.metalHDRURL })
        })

        pane.addInput(PARAMS, 'metalHdrMatCapRatio', {
            min: 0,
            max: 1
        }).on('change', () => {
            //
            useRender.setState({ metalHdrMatCapRatio: PARAMS.metalHdrMatCapRatio })
        })

        pane.addInput(PARAMS, 'metalTint', {}).on('change', () => {
            //
            useRender.setState({ metalTint: PARAMS.metalTint })
        })

        pane.addInput(PARAMS, 'metalEnvMapIntensity', {
            min: 0,
            max: 5
        }).on('change', () => {
            //
            useRender.setState({ metalEnvMapIntensity: PARAMS.metalEnvMapIntensity })
        })

        pane.addInput(PARAMS, 'metalRotY', {
            min: 0,
            max: 3.1415926 * 2
        }).on('change', () => {
            //
            useRender.setState({ metalRotY: PARAMS.metalRotY })
        })

        setInterval(() => {
            let st = useRender.getState()

            for (let kn in st) {
                if (typeof st[kn] !== 'object' && st[kn] !== null) {
                    PARAMS[kn] = st[kn]
                }
            }
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [ringURL])

    let metalMatCapURL = useRender((r) => r.metalMatCapURL)
    let metalHDRURL = useRender((r) => r.metalHDRURL)
    return (
        <>
            <div ref={refDiv}></div>
            Layer1
            {metalMatCapURL && <Preview url={metalMatCapURL} />}
            Layer2
            {metalHDRURL && <Preview url={metalHDRURL} />}
        </>
    )
}

function Preview({ url }) {
    return (
        <Canvas gl={{}}>
            <PreviewContnet url={url}></PreviewContnet>
        </Canvas>
    )
}
function PreviewContnet({ url }) {
    let scene = useThree((r) => r.scene)
    useEffect(() => {
        if (!url) {
            return
        }
        // console.log(url)
        dynamicLoad(url, EquirectangularReflectionMapping).then((texure) => {
            if (texure) {
                scene.environment = texure
                scene.background = texure
            }
        })
    }, [scene, url])

    return (
        <>
            <Sphere scale={2.5}>
                <meshStandardMaterial roughness={0} metalness={1}></meshStandardMaterial>
            </Sphere>
            <OrbitControls></OrbitControls>
        </>
    )
}

function DiamondConf() {
    let refDiv = useRef()
    // let diamond = useRender((r) => r.diamond)

    useEffect(() => {
        //
        const PARAMS = {
            diamondMatCapURL: useRender.getState().diamondMatCapURL || '/Hdri_reflection/hdri_15.jpg',
            diamondHDRURL: useRender.getState().diamondHDRURL || '/Hdri_reflection/hdri_15.jpg',
            diamondTint: useRender.getState().diamondTint,
            diamondEnvMapIntensity: useRender.getState().diamondEnvMapIntensity,
            diamondLighntess: useRender.getState().diamondLighntess,
            diamondRainbow: useRender.getState().diamondRainbow,
            diamondContrast: useRender.getState().diamondContrast
        }

        const pane = new Pane({
            container: refDiv.current
        })

        // pane
        //   .addInput(PARAMS, 'diamondMatCapURL', {
        //     options: hdrOptions
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondMatCapURL: PARAMS.diamondMatCapURL })
        //   })

        // pane
        //   .addInput(PARAMS, 'diamondHDRURL', {
        //     options: hdrOptions
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondHDRURL: PARAMS.diamondHDRURL })
        //   })

        pane.addInput(PARAMS, 'diamondTint', {}).on('change', () => {
            useRender.setState({ diamondTint: PARAMS.diamondTint })
        })

        pane.addInput(PARAMS, 'diamondEnvMapIntensity', {
            min: 0,
            max: 3
        }).on('change', () => {
            useRender.setState({ diamondEnvMapIntensity: PARAMS.diamondEnvMapIntensity })
        })

        pane.addInput(PARAMS, 'diamondLighntess', {
            min: 0,
            max: 5
        }).on('change', () => {
            useRender.setState({ diamondLighntess: PARAMS.diamondLighntess })
        })

        // pane
        //   .addInput(PARAMS, 'diamondRainbow', {
        //     min: 0,
        //     max: 0.1
        //   })
        //   .on('change', () => {
        //     //
        //     useRender.setState({ diamondRainbow: PARAMS.diamondRainbow })
        //   })

        pane.addInput(PARAMS, 'diamondContrast', {
            min: 0,
            max: 10
        }).on('change', () => {
            useRender.setState({ diamondContrast: PARAMS.diamondContrast })
        })

        setInterval(() => {
            pane.refresh()
        })
        return () => {
            pane.dispose()
        }
    }, [])
    return (
        <>
            <div ref={refDiv}></div>
        </>
    )
}

// const useFlow = (title, config) => {
//     let output = {}
//     for (let kn in config) {
//         output[kn] = config[kn]?.value || config[kn]
//     }
//     return {
//         ...output
//     }
// }

//

function PP() {
    //
    //
    // let { glowDetect, intensity, smoothGlow } = useFlow(
    //   'flare',
    //   {
    //     smoothGlow: true,
    //     glowDetect: { value: 0.95, min: 0, max: 1 },
    //     intensity: { value: 1.47, min: 0, max: 15 }
    //   },

    //   {
    //     // container: document.querySelector('#tweak')
    //   }
    // )

    let intensity = useRender((r) => r.intensity)
    let glowDetect = useRender((r) => r.glowDetect)
    let smoothGlow = useRender((r) => r.smoothGlow)

    let lights = useRender((r) => r.lights)
    let meshes = useRender((r) => r.meshes)

    // let brightness = useRender((r) => r.brightness)
    // let contrast = useRender((r) => r.contrast)

    // let hue = useRender((r) => r.hue)
    // let saturation = useRender((r) => r.saturation)

    return (
        <>
            <EffectComposer multisampling={4} disableNormalPass>
                <SelectiveBloom
                    lights={lights}
                    selection={meshes}
                    selectionLayer={10}
                    // luminanceSmoothing={0.2}
                    luminanceThreshold={glowDetect}
                    intensity={intensity}
                    mipmapBlur={smoothGlow}
                />

                {/* <BrightnessContrast brightness={brightness} contrast={contrast}></BrightnessContrast>
        <HueSaturation hue={hue} saturation={saturation}></HueSaturation> */}
                {/* <DepthOfField focusDistance={1} focalLength={0.3} bokehScale={1} height={480} /> */}
                {/* <Vignette></Vignette> */}
            </EffectComposer>
        </>
    )
}
