import { createRoot } from 'react-dom/client'
import { Chooser } from './index2'
import { useEffect, useRef, useState } from 'react'
import './styles.css'
function Gate({ children }) {
    let ref = useRef()
    let [ok, setOK] = useState(null)

    return ok ? (
        children
    ) : (
        <form
            onSubmit={(ev) => {
                ev.stopPropagation()
                ev.preventDefault()

                let pw = ref.current.value
                let url = `https://css.mydear.live/api/pw`

                fetch(`${url}`, {
                    mode: 'cors',
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify({ password: pw })
                })
                    .then((r) => r.json())
                    .then((r) => {
                        console.log(r)
                        if (r.isOkay === true) {
                            setOK(true)
                        } else {
                            alert('Wrong password')
                        }
                    })
                    .catch((r) => {
                        console.log(r)
                    })
            }}
            //
            className="w-full h-full flex flex-col items-center justify-center">
            <div>
                <input className="bg-gray-200 p-3 hidden" placeholder="usermame" value={'nouser'} type="text"></input>
                <input className="bg-gray-200 p-3" ref={ref} placeholder="Password" type="password"></input>
            </div>
            <div>
                <button
                    type="submit"
                    className="bg-gray-200 m-3 p-2  rounded-lg"
                    onClick={() => {
                        // console.log(ref.current.value)
                    }}>
                    Enter
                </button>
            </div>
        </form>
    )
}

if (process.env.NODE_ENV === 'development') {
    createRoot(document.getElementById('root')).render(<Chooser></Chooser>)
} else {
    createRoot(document.getElementById('root')).render(
        <Gate>
            <Chooser></Chooser>
        </Gate>
    )
}
