import { create } from 'zustand'

export const useRender = create(() => {})

let presets = [
    {
        id: 'gold',
        name: 'Gold',
        data: {
            // 2023-08-17
            ...{
                textColor: '#8c8c5e',

                metalRotY: 0,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/studio18b.png',
                metalMatCapURL: '/matcap2k/rose-gold@2x.png',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#fad26b',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 2,
                brightness: 0.05,
                contrast: 1.2,
                saturation: 1,
                hue: 0
            }
        }
    },
    {
        id: 'red',
        name: 'Red Gold',
        data: {
            // 2023-08-17
            ...{
                textColor: '#8c6e5e',

                metalRotY: 0,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/studio18b.png',
                metalMatCapURL: '/matcap2k/rose-gold@2x.png',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#f8b188',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 2,
                brightness: 0.05,
                contrast: 1.2,
                saturation: 1,
                hue: 0
            }
        }
    },
    {
        id: 'white',
        name: 'White Gold',
        data: {
            ...{
                textColor: '#8c8c8c',

                metalRotY: 3.5,
                metalEnvMapIntensity: 3,
                metalHDRURL: '/Hdri_reflection/hdri_29.jpg',
                metalMatCapURL: '/Hdri_reflection/hdri-active.jpeg',
                metalHdrMatCapRatio: 0.8,
                metalTint: '#ffffff',
                metalRoughness: 0.1,
                diamondHDRURL: '/brown_photostudio_07-bw.exr',
                diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
                diamondEnvMapIntensity: 1.2,
                diamondTint: '#ffffff',
                diamondLighntess: 3.75,
                diamondRainbow: 0.0043478261,
                diamondContrast: 3,
                smoothGlow: true,
                glowDetect: 0.95,
                intensity: 1.47,
                brightness: 0,
                contrast: 1,
                saturation: 1,
                hue: 0
            }
        }
    }
]

// let rings = [
//     { id: 'fa1', hh: `#11`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h11 1ct.zip` },
//     { id: 'fa2', hh: `#11`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h11 050ct.zip` },
//     { id: 'fa3', hh: `#11`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h11 035ct.zip` },
//     { id: 'fa4', hh: `#11`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h11 030ct.zip` },

//     { id: 'fa5', hh: `#13`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h13 1ct.zip` },
//     { id: 'fa6', hh: `#13`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h13 050ct.zip` },
//     { id: 'fa7', hh: `#13`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h13 035ct.zip` },
//     { id: 'fa8', hh: `#13`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h13 030ct.zip` },

//     { id: 'fa9', hh: `#15`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h15 1ct.zip` },
//     { id: 'fa10', hh: `#15`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h15 050ct.zip` },
//     { id: 'fa11', hh: `#15`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h15 035ct.zip` },
//     { id: 'fa12', hh: `#15`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h15 030ct.zip` },

//     { id: 'fa13', hh: `#17`, ct: `1.00ct`, url: `/2023-08-25-lok/94010R h17 1ct.zip` },
//     { id: 'fa14', hh: `#17`, ct: `0.50ct`, url: `/2023-08-25-lok/94010R h17 050ct.zip` },
//     { id: 'fa15', hh: `#17`, ct: `0.35ct`, url: `/2023-08-25-lok/94010R h17 035ct.zip` },
//     { id: 'fa16', hh: `#17`, ct: `0.30ct`, url: `/2023-08-25-lok/94010R h17 030ct.zip` }
// ]

function getFilesSpecific({ spec = '!!!!' }) {
    function requireAll(r) {
        let files = []
        r.keys().forEach((key) => {
            let data = r(key)

            if (!files.some((r) => r.uuid === data.uuid) && data.thumbURL.includes('.zip') && data.thumbURL.includes(spec)) {
                files.push(data)
            }

            // console.log(data)
        })

        files = files.slice().sort((a, b) => {
            if (b.ts > a.ts) {
                return 1
            } else if (b.ts < a.ts) {
                return -1
            }
            return 0
        })

        return files
    }

    let files = requireAll(require.context('../../../public/discover/', true, /meta\.json$/))

    // console.log(files)
    return files
}

let rings = []

rings = getFilesSpecific({ spec: `94050R` })
    .map((r) => {
        return {
            ...r,
            id: r.uuid,
            hh: `${r.fingerSize}`,
            url: r.thumbURL.replace(`/discover_dropbox_obj/`, `/discover/90450R_obj/`)
        }
    })
    .map((rr) => {
        // console.log(rr)
        return rr
    })

useRender.setState({
    //
    textColor: '#8c6e5e',
    textContent: 'Peter',
    textContent2: 'Mary',
    textContent3: '2023',

    textFont: `Edwardian`,
    textSize: 60.0,
    textLogoURL: `/gui/svg/svgs/webp-white/tide-circle_5x.webp`,
    textLogosChoices: [
        //
        // `/gui/svg/svgs/c1svg.svg`,
        // `/gui/svg/svgs/csssvg.svg`,
        // `/gui/svg/svgs/tide-circlesvg.svg`,
        // `/gui/svg/svgs/tidesvg.svg`,
        // `/gui/svg/svgs/emphasissvg.svg`

        // `/gui/svg/svgs/webp/c1_5x_webp.webp`,
        // `/gui/svg/svgs/webp/css_5x_webp.webp `,
        // `/gui/svg/svgs/webp/emphasis_5x_webp.webp`,
        // `/gui/svg/svgs/webp/tide_5x_webp.webp`,
        // `/gui/svg/svgs/webp/tide-circle_5x_webp.webp`,

        // `/gui/svg/svgs/webp-white/c1_5x.webp`,
        // `/gui/svg/svgs/webp-white/css_5x.webp`,
        // `/gui/svg/svgs/webp-white/emphasis_5x.webp`,
        // `/gui/svg/svgs/webp-white/tide_5x.webp`,
        // `/gui/svg/svgs/webp-white/tide-circle_5x.webp`,

        // {
        //     id: 'l0',
        //     buttonURL: `/gui/svg/svgs/webp/c1_5x_webp.webp`,
        //     renderURL: `/gui/svg/svgs/webp-white/c1_5x.webp`
        // },
        // {
        //     id: 'l1',
        //     buttonURL: `/gui/svg/svgs/webp/css_5x_webp.webp `,
        //     renderURL: `/gui/svg/svgs/webp-white/css_5x.webp`
        // },
        // {
        //     id: 'l2',
        //     buttonURL: `/gui/svg/svgs/webp/emphasis_5x_webp.webp`,
        //     renderURL: `/gui/svg/svgs/webp-white/emphasis_5x.webp`
        // },

        // //
        // {
        //     id: 'l3',
        //     buttonURL: `/gui/svg/svgs/webp/tide_5x_webp.webp`,
        //     renderURL: `/gui/svg/svgs/webp-white/tide_5x.webp`
        // },
        // {
        //     id: 'l4',
        //     buttonURL: `/gui/svg/svgs/webp/tide-circle_5x_webp.webp`,
        //     renderURL: `/gui/svg/svgs/webp-white/tide-circle_5x.webp`
        // },

        {
            id: 'l0',
            buttonURL: `/gui/svg/gen2/and_5x_webp.webp`,
            renderURL: `/gui/svg/gen2/and-white_5x_webp.webp`
        },

        // `/gui/svg/gen2/heart_5x_webp.webp`,
        // `/gui/svg/gen2/heart-white_5x_webp.webp`,
        {
            id: 'l1',
            buttonURL: `/gui/svg/gen2/heart_5x_webp.webp`,
            renderURL: `/gui/svg/gen2/heart-white_5x_webp.webp`
        },
        // `/gui/svg/gen2/heartoutline_5x_webp.webp`,
        // `/gui/svg/gen2/heartoutline-white_5x_webp.webp`,
        {
            id: 'l2',
            buttonURL: `/gui/svg/gen2/heartoutline_5x_webp.webp`,
            renderURL: `/gui/svg/gen2/heartoutline-white_5x_webp.webp`
        },
        // `/gui/svg/gen2/tide-circle_5x_webp.webp`,
        // `/gui/svg/gen2/tide-circle-white_5x_webp.webp`,
        {
            id: 'l3',
            buttonURL: `/gui/svg/gen2/tide-circle_5x_webp.webp`,
            renderURL: `/gui/svg/gen2/tide-circle-white_5x_webp.webp`
        },
        // `/gui/svg/gen2/tide_5x_webp.webp`,
        // `/gui/svg/gen2/tide-white_5x_webp.webp`,
        {
            id: 'l4',
            buttonURL: `/gui/svg/gen2/tide_5x_webp.webp`,
            renderURL: `/gui/svg/gen2/tide-white_5x_webp.webp`
        }
    ],
    textFontChoices: [
        {
            id: 'f0',
            displayName: 'Arial / 楷書',
            textFont: `Arial, Times New Roman`
        },
        {
            id: 'f1',
            displayName: 'Times New Roman / 楷書',
            textFont: `Times New Roman, "DFKai-sb"`
        },
        {
            id: 'f2',
            displayName: 'Edwardian Script / 楷書',
            textFont: `Edwardian`
        }
    ],

    // textLogos: [
    //     `/gui/svg/svgs/csssvg.svg`,
    //     `/gui/svg/svgs/tide-circlesvg.svg`,
    //     `/gui/svg/svgs/tidesvg.svg`
    // ],

    step: 'metal',
    steps: [
        // {
        //     id: 0,
        //     name: 'forms',
        //     displayName: 'Forms'
        // },
        {
            id: 1,
            name: 'metal',
            displayName: 'Metal'
        },
        {
            id: 3,
            name: 'ringSize',
            displayName: 'Ring Size'
        },
        {
            id: 4,
            name: 'diamondSize',
            displayName: 'Diamond Size'
        },
        {
            id: 5,
            name: 'engrave',
            displayName: 'Engrave'
        }
    ],

    ct: (
        rings
            .slice()
            .reverse()
            .find((r) => r.ct.includes('0.30ct')) || rings[rings.length - 1]
    )?.ct,
    hh: rings.slice().reverse()[0]?.hh,
    ringURL: `${rings[0]?.url}`,

    lights: [],
    meshes: [],

    ...{
        ...presets[1].data
    },
    matID: presets[1].id
})

export { presets, rings }
