import { create } from 'zustand'

export const useFiles = create((set, get) => {
    return {
        //

        aceeptedFiles: []
        //
    }
})

export const cacheURL = new Map()
