import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, createPortal, useLoader, useThree } from '@react-three/fiber'
import {
  OrbitControls,
  PerspectiveCamera,
  Sphere,
  useGLTF,
  useTexture,
  RandomizedLight,
  AccumulativeShadows,
  Environment
} from '@react-three/drei'
// import { RefractionMaterial } from './RefractionMaterial'
// import { useTweaks, makeButton } from 'use-tweaks'
import { Bloom, DepthOfField, EffectComposer, SSR, SelectiveBloom, Vignette } from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Color, EquirectangularReflectionMapping, HalfFloatType, SRGBColorSpace, UVMapping } from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'
// import { DiamondMaterialCustomKey, RefractionMaterialCustom } from './RefractionMaterialCustom'
import { create } from 'zustand'
import { DiamondMaterialCustomCircleKey, RefractionMaterialCustomCircle } from './RefractionMaterialCustomCircle'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { TextureLoader, sRGBEncoding } from 'three'
import { Leva, useControls } from 'leva'
import { useTweaks } from 'use-tweaks'
import Pane from 'tweakpane'
import copyToClipboard from 'copy-to-clipboard'

let presets = [
  {
    name: 'Gold',
    data: {
      metalRotY: 0,
      metalEnvMapIntensity: 3,
      metalHDRURL: `/Hdri_reflection/studio18b.png`,
      // metalHDRURL: '/Hdri_reflection/studio018.hdr',
      metalMatCapURL: '/matcap2k/rose-gold@2x.png',
      metalHdrMatCapRatio: 0.8,
      metalTint: '#fad26b',
      metalRoughness: 0.1,
      diamondHDRURL: '/brown_photostudio_07-bw.exr',
      diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
      diamondEnvMapIntensity: 1.2,
      diamondTint: '#ffffff',
      diamondLighntess: 3.75,
      diamondRainbow: 0.0043478261,
      diamondContrast: 3,
      smoothGlow: true,
      glowDetect: 0.95,
      intensity: 1.47
      // ringURL: `/ring100/1847467(2023-8-11)-100x.obj`
    }
  },
  {
    name: 'Red Gold',
    data: {
      metalRotY: 0,

      metalEnvMapIntensity: 3,
      metalHDRURL: '/Hdri_reflection/studio18b.png',
      metalMatCapURL: '/matcap2k/rose-gold@2x.png',
      metalHdrMatCapRatio: 0.8,
      metalTint: '#f8b188',
      metalRoughness: 0.1,
      diamondHDRURL: '/brown_photostudio_07-bw.exr',
      diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
      diamondEnvMapIntensity: 1.2,
      diamondTint: '#ffffff',
      diamondLighntess: 3.75,
      diamondRainbow: 0.0043478261,
      diamondContrast: 3,
      smoothGlow: true,
      glowDetect: 0.95,
      intensity: 1.47
      // ringURL: `/ring100/1847467(2023-8-11)-100x.obj`
    }
  },
  {
    name: 'White Gold',
    data: {
      metalRotY: 0,

      metalEnvMapIntensity: 3,
      metalHDRURL: '/Hdri_reflection/hdri_29.jpg',
      metalMatCapURL: '/Hdri_reflection/hdri-active.jpeg',
      metalHdrMatCapRatio: 0.8,
      metalTint: '#ffffff',
      metalRoughness: 0.1,
      diamondHDRURL: '/brown_photostudio_07-bw.exr',
      diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
      diamondEnvMapIntensity: 1.2,
      diamondTint: '#ffffff',
      diamondLighntess: 3.75,
      diamondRainbow: 0.0043478261,
      diamondContrast: 3,
      smoothGlow: true,
      glowDetect: 0.95,
      intensity: 1.47
      // ringURL: `/ring100/1847467(2023-8-11)-100x.obj`
    }
  }
]

let useRender = create((set, get) => {
  return {
    //
    lights: [],
    meshes: [],

    ///!SECTION
    metalRotY: 0,

    metalEnvMapIntensity: 1.79,
    metalHDRURL: `/Hdri_reflection/studio018.hdr`,
    metalMatCapURL: `/Hdri_reflection/studio018.hdr`,
    metalHdrMatCapRatio: 0.5,
    metalTint: '#ffda89',
    metalRoughness: 0.1,

    diamondHDRURL: '/brown_photostudio_07-bw.exr',
    diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',

    diamondEnvMapIntensity: 1.0,
    diamondTint: '#ffffff',
    diamondLighntess: 3.75,
    diamondRainbow: 0.0043478261,
    diamondContrast: 3.3152173913,

    smoothGlow: true,
    glowDetect: 0.95,
    intensity: 1.47,

    //
    //
    ...{
      metalRotY: 0,

      metalEnvMapIntensity: 3,
      metalHDRURL: '/Hdri_reflection/studio18b.png',
      metalMatCapURL: '/matcap2k/rose-gold@2x.png',
      metalHdrMatCapRatio: 0.8,
      metalTint: '#f8b188',
      metalRoughness: 0.1,
      diamondHDRURL: '/brown_photostudio_07-bw.exr',
      diamondMatCapURL: '/css-diamond-env/exr-diamond-512.exr',
      diamondEnvMapIntensity: 1.2,
      diamondTint: '#ffffff',
      diamondLighntess: 3.75,
      diamondRainbow: 0.0043478261,
      diamondContrast: 3,
      smoothGlow: true,
      glowDetect: 0.95,
      intensity: 1.47,
      ringURL: `/ring100/1847467(2023-8-11)-100x.obj`
    }
  }
})
/**
   lightness: { value: 3.75, min: 0, max: 5 },
    rainbow: { value: 0.0043478261, min: 0, max: 0.1 },
    contrast: { value: 3.3152173913, min: 0, max: 10 }
*/

export function Shard7DiamindShow() {
  let desktop = {
    mobile: false,
    parent: { height: `100%`, width: `calc(100%)`, display: 'flex' },
    left: { height: `100%`, width: `calc(100% - 300px)` },
    right: { height: `100%`, width: `calc(300px)` }
  }
  let [config, setConfig] = useState(desktop)

  useEffect(() => {
    let resizer = () => {
      if (window.innerWidth <= 500) {
        setConfig({
          mobile: true,
          parent: { height: `100%`, width: `calc(100%)` },
          left: { height: `60%`, width: `calc(100%)` },
          right: { height: `40%`, width: `calc(100%)` }
        })
      } else {
        setConfig(desktop)
      }
    }
    resizer()
  }, [])

  //
  return (
    <>
      <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />

      <div style={config.parent}>
        <Canvas gl={{ antialias: false }} style={config.left}>
          <RenderContent></RenderContent>
        </Canvas>

        <div style={config.right} className=" overflow-scroll">
          <div className="w-full p-2 bg-gray-100 h-full ">
            {!config.mobile && (
              <>
                <div
                  onClick={() => {
                    //

                    let dat = {
                      ...useRender.getState()
                    }

                    for (let key in dat) {
                      if (typeof dat[key] === 'object' && dat[key] !== null) {
                        delete dat[key]
                      }
                    }

                    copyToClipboard(JSON.stringify(dat, null, 2))
                  }}
                  className="text-center cursor-pointer underline mb-3 p-2 bg-blue-500 text-white">
                  Copy Settings To Clipboard
                </div>
              </>
            )}
            {/*
            <div className="">Ring</div>
            <div className="">
              <RingConf></RingConf>
            </div> */}

            <div className="">Ring Metal</div>
            <SimpleMetalConf></SimpleMetalConf>
            {/* {config.mobile && (
              <>
                <div className="">
                  <SimpleMetalConf></SimpleMetalConf>
                </div>
              </>
            )} */}
            {!config.mobile && (
              <>
                <div className="">Environment</div>
                <div className="">
                  <EnvironmentConf></EnvironmentConf>
                </div>

                <div className="">Metal</div>
                <div className="">
                  <MetalConf></MetalConf>
                </div>

                <div className="">Diamond</div>
                <div className="">
                  <DiamondConf></DiamondConf>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function RenderContent() {
  let ringURL = useRender((r) => r.ringURL) || ''

  return (
    <>
      {/*  */}
      <Suspense fallback={null}>
        <RingLoader key={ringURL}></RingLoader>
      </Suspense>

      <EnvironmentRunner></EnvironmentRunner>
      <MetalEnv></MetalEnv>
      <DiamondEnv></DiamondEnv>
      <PP></PP>

      <PerspectiveCamera near={0.05} far={100} makeDefault></PerspectiveCamera>
      <OrbitControls minDistance={2} object-position={[0, 0.045 * 100.0, 0.03 * 100.0]} target={[0, 0.02 * 100.0, 0]}></OrbitControls>

      {/*  */}
    </>
  )
}
function DiamondEnv() {
  let [matcap, setMatCap] = useState(null)
  let [hdr, setHDR] = useState(null)

  let diamondMatCapURL = useRender((r) => r.diamondMatCapURL)
  useEffect(() => {
    dynamicLoad(diamondMatCapURL, UVMapping).then((texure) => {
      setMatCap(texure)
    })
  }, [diamondMatCapURL])

  let diamondHDRURL = useRender((r) => r.diamondHDRURL)
  useEffect(() => {
    dynamicLoad(diamondHDRURL, EquirectangularReflectionMapping).then((texure) => {
      setHDR(texure)
    })
  }, [diamondHDRURL])

  let diamondLighntess = useRender((r) => r.diamondLighntess)
  let diamondRainbow = useRender((r) => r.diamondRainbow)
  let diamondContrast = useRender((r) => r.diamondContrast)

  let diamondEnvMapIntensity = useRender((r) => r.diamondEnvMapIntensity)
  let { envMap: computedRainbowEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  // float pattern (vec2 p, float time) {
  //   float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
  //   return abs(vout);
  // }


  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm4(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;
  uniform sampler2D matcap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }

  uniform float diamondEnvMapIntensity;
  vec4 mainImage ()  {


    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, uvMC );

    vec4 hdrV4 = matcapColor;// texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 3.3, 0.1), 2.0);

    outColor.rgb = vec3(
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 + ${diamondRainbow.toFixed(3)}, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0, time * 0.05),
      ${diamondLighntess.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 - ${diamondRainbow.toFixed(3)}, time * 0.05)
    );

    outColor.r = pow(outColor.r * 0.4, ${diamondContrast.toFixed(3)});
    outColor.g = pow(outColor.g * 0.4, ${diamondContrast.toFixed(3)});
    outColor.b = pow(outColor.b * 0.4, ${diamondContrast.toFixed(3)});

    outColor.rgb = mix(hdrV4.rgb , outColor.rgb, 0.3);
    outColor.rgb  = czm_saturation(outColor.rgb, 0.1);

    return outColor * diamondEnvMapIntensity;
  }

  `,
    {
      time: { value: 0 },
      matcap: { value: matcap },
      hdrTexture: { value: hdr },
      diamondEnvMapIntensity: { value: diamondEnvMapIntensity }
    },
    128,
    true
  )

  useEffect(() => {
    if (computedRainbowEnv) {
      useRender.setState({ computedRainbowEnv: computedRainbowEnv })
    }
  }, [computedRainbowEnv])

  return null
}

function MetalEnv() {
  let [matcap, setMatCap] = useState(null)
  let [hdr, setHDR] = useState(null)

  let metalMatCapURL = useRender((r) => r.metalMatCapURL)
  useEffect(() => {
    dynamicLoad(metalMatCapURL, UVMapping).then((texure) => {
      setMatCap(texure)
    })
  }, [metalMatCapURL])

  let metalHDRURL = useRender((r) => r.metalHDRURL)
  useEffect(() => {
    dynamicLoad(metalHDRURL, EquirectangularReflectionMapping).then((texure) => {
      setHDR(texure)
    })
  }, [metalHDRURL])

  let metalRotY = useRender((r) => r.metalRotY)
  let metalHdrMatCapRatio = useRender((r) => r.metalHdrMatCapRatio)
  let { envMap: computedMetalEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  uniform sampler2D matcap;

  uniform sampler2D metalEnvMap;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  uniform float metalHdrMatCapRatio;
  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }
  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;


    vec4 outColor;
    outColor.a = 1.0;

    vec3 viewDir = normalize( vViewPosition );
    vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 y = cross( viewDir, x );
    vec2 uvMC = vec2( dot( x, direction ), dot( y, direction ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

    vec4 matcapColor = texture2D( matcap, vec2(uvMC.x, uvMC.y) );
    // vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2( uvMC.y, 1.0 - uvMC.x));
    vec4 hdrTextureC4 = texture2D(metalEnvMap, vec2(uv));


    outColor.rgb = mix(matcapColor.rgb, hdrTextureC4.rgb, metalHdrMatCapRatio);

    return outColor;
  }

  `,
    {
      metalHdrMatCapRatio: { value: metalHdrMatCapRatio },
      time: { value: 0 },
      matcap: { value: matcap },
      metalEnvMap: { value: hdr },
      envLightIntensity: { value: 1 },
      rotY: { value: metalRotY }
    },
    128,
    true
  )
  useEffect(() => {
    useRender.setState({ computedMetalEnv: computedMetalEnv })
  }, [computedMetalEnv])

  return null
}

let dynamicLoad = async (texURL, mapping = EquirectangularReflectionMapping) => {
  if (!texURL) {
    return
  }
  if (texURL.includes('.hdr')) {
    let hdr = new RGBELoader()
    let texture = await hdr.loadAsync(texURL)
    texture.mapping = mapping
    texture.flipY = true

    return texture
  } else if (texURL.includes('.exr')) {
    let exr = new EXRLoader()
    let texture = await exr.loadAsync(texURL)
    texture.mapping = mapping
    texture.flipY = true

    return texture
  } else {
    let load = new TextureLoader()
    let texture = await load.loadAsync(texURL)
    texture.encoding = sRGBEncoding
    texture.colorSpace = SRGBColorSpace
    texture.mapping = mapping
    texture.flipY = true

    return texture
  }
}

function EnvironmentRunner() {
  // let environmentMapURL = useRender((r) => r.environmentMapURL) || ''
  // let scene = useThree((r) => r.scene)
  // useEffect(() => {
  //   dynamicLoad(environmentMapURL).then((texure) => {
  //     if (texure) {
  //       scene.environment = texure
  //     }
  //   })
  // }, [scene, environmentMapURL])

  return (
    <>
      <color attach="background" args={['#f0f0f0']}></color>
    </>
  )
}

function RingLoader() {
  let ringURL = useRender((r) => r.ringURL) || ''

  let sceneLoaded = false

  if (ringURL.includes('.obj')) {
    sceneLoaded = useLoader(OBJLoader, ringURL)
  } else if (ringURL.includes('.glb')) {
    let gltf = useGLTF(ringURL)
    sceneLoaded = gltf.scene
  }

  let diamondName = false
  let others = []

  let computedRainbowEnv = useRender((r) => r.computedRainbowEnv)
  let computedMetalEnv = useRender((r) => r.computedMetalEnv)

  {
    sceneLoaded.traverse((it) => {
      if (it.isMesh) {
        if (it.name.toLowerCase().includes('diamond') && !diamondName) {
          diamondName = it.name
        } else {
          others.push(it)
        }
      }
    })
  }

  let items = []

  let metalEnvMapIntensity = useRender((r) => r.metalEnvMapIntensity)
  let metalTint = useRender((r) => r.metalTint)
  let metalRoughness = useRender((r) => r.metalRoughness)
  {
    computedMetalEnv &&
      sceneLoaded.traverse((it) => {
        if (it.isMesh) {
          if (it.name !== diamondName) {
            items.push(
              <group key={it.uuid}>
                {createPortal(
                  <meshStandardMaterial
                    // matcap={matcap || computedMetalEnv}
                    envMap={computedMetalEnv}
                    envMapIntensity={metalEnvMapIntensity}
                    color={metalTint}
                    roughness={metalRoughness}
                    metalness={1}
                  />,
                  it
                )}
              </group>
            )
          }
        }
      })
  }

  const diamondConfig = {
    diamondColor: '#ffffff',
    lightColor: '#ffeecc',
    roughness: 0.1,
    aberrationStrength: 0.01,
    ior: 2.418,
    metalness: 1
  }

  let diamondTint = useRender((r) => r.diamondTint)
  useEffect(() => {
    useRender.setState({ meshes: [sceneLoaded.getObjectByName(diamondName)] })
  })

  return (
    <>
      {computedRainbowEnv &&
        createPortal(
          <RefractionMaterialCustomCircle
            key={DiamondMaterialCustomCircleKey}
            envMap={computedRainbowEnv}
            {...diamondConfig}
            color={diamondTint}
            fastChroma={false}
            bounces={1}
            fresnel={0}
            toneMapped={false}
          />,
          sceneLoaded.getObjectByName(diamondName)
        )}

      <directionalLight intensity={1} color={diamondConfig.lightColor} position={[2, 2, -2]}></directionalLight>
      <directionalLight intensity={1} color={diamondConfig.lightColor} position={[-2, 2, -2]}></directionalLight>
      {items}
      {sceneLoaded && <primitive object={sceneLoaded}></primitive>}
    </>
  )
}

let links = [
  `/Hdri_reflection/studio18b.png`,
  `/matcap2k/bw.png`,
  `/matcap2k/rose-gold@2x.png`,
  `/matcap2k/yellow@2x.png `,

  `/Hdri_reflection/059.hdr`,
  `/Hdri_reflection/background_D001.jpg`,
  `/Hdri_reflection/background_D002.jpg`,
  `/Hdri_reflection/background_D003.jpg`,
  `/Hdri_reflection/background_D004.jpg`,
  `/Hdri_reflection/Flourecent-Lights.hdr`,
  `/Hdri_reflection/hdri_1.jpg`,
  `/Hdri_reflection/hdri_2.jpg`,
  `/Hdri_reflection/hdri_3_1.jpg`,
  `/Hdri_reflection/hdri_3_2.jpg`,
  `/Hdri_reflection/hdri_3.jpg`,
  `/Hdri_reflection/hdri_4_1.jpg`,
  `/Hdri_reflection/hdri_4.jpg`,
  `/Hdri_reflection/hdri_5.jpg`,
  `/Hdri_reflection/hdri_6.jpg`,
  `/Hdri_reflection/hdri_7.jpg`,
  `/Hdri_reflection/hdri_8.jpg`,
  `/Hdri_reflection/hdri_9.jpg`,
  `/Hdri_reflection/hdri_10.jpg`,
  `/Hdri_reflection/hdri_11.jpg`,
  `/Hdri_reflection/hdri_12.jpg`,
  `/Hdri_reflection/hdri_13.jpg`,
  `/Hdri_reflection/hdri_14.jpg`,
  `/Hdri_reflection/hdri_15.jpg`,
  `/Hdri_reflection/hdri_16.jpg`,
  `/Hdri_reflection/hdri_17.jpg`,
  `/Hdri_reflection/hdri_18.jpg`,
  `/Hdri_reflection/hdri_19.jpg`,
  `/Hdri_reflection/hdri_20.jpg`,
  `/Hdri_reflection/hdri_21.jpg`,
  `/Hdri_reflection/hdri_22.jpg`,
  `/Hdri_reflection/hdri_23.jpg`,
  `/Hdri_reflection/hdri_24.jpg`,
  `/Hdri_reflection/hdri_25.jpg`,
  `/Hdri_reflection/hdri_26.jpg`,
  `/Hdri_reflection/hdri_27.jpg`,
  `/Hdri_reflection/hdri_28.jpg`,
  `/Hdri_reflection/hdri_29.jpg`,
  `/Hdri_reflection/hdri_30.jpg`,
  `/Hdri_reflection/hdri_31.jpg`,
  `/Hdri_reflection/hdri_32.jpg`,
  `/Hdri_reflection/hdri_33.jpg`,
  `/Hdri_reflection/hdri_slotroom01d_sml2.hdr`,
  `/Hdri_reflection/hdri-active.jpeg`,
  `/Hdri_reflection/sky0031.hdr`,
  `/Hdri_reflection/studio001.hdr`,
  `/Hdri_reflection/studio004.hdr`,
  `/Hdri_reflection/studio007.hdr`,
  `/Hdri_reflection/studio008.hdr`,
  `/Hdri_reflection/studio016.hdr`,
  `/Hdri_reflection/studio018.hdr`,
  `/Hdri_reflection/studio022.hdr`,
  `/Hdri_reflection/studio025.hdr`,
  `/Hdri_reflection/studio028.hdr`
]

let hdrOptions = {}
links.forEach((link) => {
  hdrOptions[link] = link
})

function EnvironmentConf() {
  let refDiv = useRef()

  useEffect(() => {
    //
    const PARAMS = useRender.getState()
    //
    const pane = new Pane({
      container: refDiv.current
    })

    pane.addInput(PARAMS, 'smoothGlow', {}).on('change', (v) => {
      useRender.setState({
        smoothGlow: PARAMS.smoothGlow
      })
      pane.refresh()
    })

    pane
      .addInput(PARAMS, 'glowDetect', {
        min: 0,
        max: 1
      })
      .on('change', (v) => {
        useRender.setState({
          glowDetect: PARAMS.glowDetect
        })
        pane.refresh()
      })

    pane.addInput(PARAMS, 'intensity', { min: 0, max: 5 }).on('change', (v) => {
      useRender.setState({
        intensity: PARAMS.intensity
      })
      pane.refresh()
    })

    return () => {
      pane.dispose()
    }
  }, [])
  return (
    <>
      <div ref={refDiv}></div>
    </>
  )
}

function RingConf() {
  let refDiv = useRef()
  let keyName = `ringURL`

  useEffect(() => {
    const PARAMS = useRender.getState()
    PARAMS[keyName] = PARAMS[keyName] || `/ring100/1847467(2023-8-11)-100x.obj`

    const pane = new Pane({
      container: refDiv.current
    })
    pane
      .addInput(PARAMS, keyName, {
        options: {
          [`94010R(11-8-2023)`]: `/ring100/1847467(2023-8-11)-100x.obj`,
          [`01257R(27-7-2023)`]: `/ring100/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring100/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring100/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring100/90257R(27-7-2023).obj`
        }
      })
      .on('change', (v) => {
        useRender.setState({
          [keyName]: v
        })
        pane.refresh()
      })

    return () => {
      pane.dispose()
    }
  }, [])
  return (
    <>
      <div ref={refDiv}></div>
    </>
  )
}

function SimpleMetalConf() {
  let refDiv = useRef()
  // let diamond = useRender((r) => r.diamond)

  useEffect(() => {
    //
    const PARAMS = {
      metalMatCapURL: useRender.getState().metalMatCapURL || '/Hdri_reflection/hdri_15.jpg',
      metalHDRURL: useRender.getState().metalHDRURL || '/Hdri_reflection/hdri_15.jpg',
      metalHdrMatCapRatio: useRender.getState().metalHdrMatCapRatio,
      metalTint: useRender.getState().metalTint,
      metalEnvMapIntensity: useRender.getState().metalEnvMapIntensity
    }

    const pane = new Pane({
      container: refDiv.current
    })

    pane
      .addButton({
        title: 'Yellow Gold'
      })
      .on('click', () => {
        useRender.setState({ ...presets[0].data })
      })

    pane
      .addButton({
        title: 'Rose Gold'
      })
      .on('click', () => {
        useRender.setState({ ...presets[1].data })
      })

    pane
      .addButton({
        title: 'White Gold'
      })
      .on('click', () => {
        useRender.setState({ ...presets[2].data })
      })
    // pane
    // .addButton({
    //   title: 'platinum'
    // })
    // .on('click', () => {
    //   useRender.setState({ metalTint: '#ffffff' })
    // })

    setInterval(() => {
      pane.refresh()
    })
    return () => {
      pane.dispose()
    }
  }, [])
  return (
    <>
      <div ref={refDiv}></div>
    </>
  )
}

function MetalConf() {
  let refDiv = useRef()
  // let diamond = useRender((r) => r.diamond)

  useEffect(() => {
    //
    const PARAMS = {
      metalMatCapURL: useRender.getState().metalMatCapURL,
      metalHDRURL: useRender.getState().metalHDRURL,
      metalHdrMatCapRatio: useRender.getState().metalHdrMatCapRatio,
      metalTint: useRender.getState().metalTint,
      metalEnvMapIntensity: useRender.getState().metalEnvMapIntensity,
      metalRotY: useRender.getState().metalRotY
    }

    hdrOptions[PARAMS.metalHDRURL] = PARAMS.metalHDRURL
    hdrOptions[PARAMS.metalMatCapURL] = PARAMS.metalMatCapURL

    const pane = new Pane({
      container: refDiv.current
    })

    pane
      .addInput(PARAMS, 'metalMatCapURL', {
        options: hdrOptions
      })
      .on('change', () => {
        //
        useRender.setState({ metalMatCapURL: PARAMS.metalMatCapURL })
      })

    pane
      .addInput(PARAMS, 'metalHDRURL', {
        options: hdrOptions
      })
      .on('change', () => {
        //
        useRender.setState({ metalHDRURL: PARAMS.metalHDRURL })
      })

    pane
      .addInput(PARAMS, 'metalHdrMatCapRatio', {
        min: 0,
        max: 1
      })
      .on('change', () => {
        //
        useRender.setState({ metalHdrMatCapRatio: PARAMS.metalHdrMatCapRatio })
      })

    pane.addInput(PARAMS, 'metalTint', {}).on('change', () => {
      //
      useRender.setState({ metalTint: PARAMS.metalTint })
    })

    pane
      .addInput(PARAMS, 'metalEnvMapIntensity', {
        min: 0,
        max: 5
      })
      .on('change', () => {
        //
        useRender.setState({ metalEnvMapIntensity: PARAMS.metalEnvMapIntensity })
      })

    pane
      .addInput(PARAMS, 'metalRotY', {
        min: 0,
        max: 3.1415926 * 2
      })
      .on('change', () => {
        //
        useRender.setState({ metalRotY: PARAMS.metalRotY })
      })

    setInterval(() => {
      pane.refresh()
    })
    return () => {
      pane.dispose()
    }
  }, [])

  let metalMatCapURL = useRender((r) => r.metalMatCapURL)
  let metalHDRURL = useRender((r) => r.metalHDRURL)
  return (
    <>
      <div ref={refDiv}></div>
      Layer1
      {metalMatCapURL && <Preview url={metalMatCapURL} />}
      Layer2
      {metalHDRURL && <Preview url={metalHDRURL} />}
    </>
  )
}

function Preview({ url }) {
  return (
    <Canvas>
      <PreviewContnet url={url}></PreviewContnet>
    </Canvas>
  )
}
function PreviewContnet({ url }) {
  let scene = useThree((r) => r.scene)
  useEffect(() => {
    if (!url) {
      return
    }
    // console.log(url)
    dynamicLoad(url, EquirectangularReflectionMapping).then((texure) => {
      if (texure) {
        scene.environment = texure
        scene.background = texure
      }
    })
  }, [scene, url])

  return (
    <>
      <Sphere scale={2.5}>
        <meshStandardMaterial roughness={0} metalness={1}></meshStandardMaterial>
      </Sphere>
      <OrbitControls></OrbitControls>
    </>
  )
}

function DiamondConf() {
  let refDiv = useRef()
  // let diamond = useRender((r) => r.diamond)

  useEffect(() => {
    //
    const PARAMS = {
      diamondMatCapURL: useRender.getState().diamondMatCapURL || '/Hdri_reflection/hdri_15.jpg',
      diamondHDRURL: useRender.getState().diamondHDRURL || '/Hdri_reflection/hdri_15.jpg',
      diamondTint: useRender.getState().diamondTint,
      diamondEnvMapIntensity: useRender.getState().diamondEnvMapIntensity,
      diamondLighntess: useRender.getState().diamondLighntess,
      diamondRainbow: useRender.getState().diamondRainbow,
      diamondContrast: useRender.getState().diamondContrast
    }

    const pane = new Pane({
      container: refDiv.current
    })

    // pane
    //   .addInput(PARAMS, 'diamondMatCapURL', {
    //     options: hdrOptions
    //   })
    //   .on('change', () => {
    //     //
    //     useRender.setState({ diamondMatCapURL: PARAMS.diamondMatCapURL })
    //   })

    // pane
    //   .addInput(PARAMS, 'diamondHDRURL', {
    //     options: hdrOptions
    //   })
    //   .on('change', () => {
    //     //
    //     useRender.setState({ diamondHDRURL: PARAMS.diamondHDRURL })
    //   })

    pane.addInput(PARAMS, 'diamondTint', {}).on('change', () => {
      useRender.setState({ diamondTint: PARAMS.diamondTint })
    })

    pane
      .addInput(PARAMS, 'diamondEnvMapIntensity', {
        min: 0,
        max: 3
      })
      .on('change', () => {
        //
        useRender.setState({ diamondEnvMapIntensity: PARAMS.diamondEnvMapIntensity })
      })

    pane
      .addInput(PARAMS, 'diamondLighntess', {
        min: 0,
        max: 5
      })
      .on('change', () => {
        //
        useRender.setState({ diamondLighntess: PARAMS.diamondLighntess })
      })

    // pane
    //   .addInput(PARAMS, 'diamondRainbow', {
    //     min: 0,
    //     max: 0.1
    //   })
    //   .on('change', () => {
    //     //
    //     useRender.setState({ diamondRainbow: PARAMS.diamondRainbow })
    //   })

    pane
      .addInput(PARAMS, 'diamondContrast', {
        min: 0,
        max: 10
      })
      .on('change', () => {
        //
        useRender.setState({ diamondContrast: PARAMS.diamondContrast })
      })

    setInterval(() => {
      pane.refresh()
    })
    return () => {
      pane.dispose()
    }
  }, [])
  return (
    <>
      <div ref={refDiv}></div>
    </>
  )
}

const useFlow = (title, config) => {
  let output = {}
  for (let kn in config) {
    output[kn] = config[kn]?.value || config[kn]
  }
  return {
    ...output
  }
}

//

function PP() {
  // let { glowDetect, intensity, smoothGlow } = useFlow(
  //   'flare',
  //   {
  //     smoothGlow: true,
  //     glowDetect: { value: 0.95, min: 0, max: 1 },
  //     intensity: { value: 1.47, min: 0, max: 15 }
  //   },

  //   {
  //     // container: document.querySelector('#tweak')
  //   }
  // )

  let intensity = useRender((r) => r.intensity)
  let glowDetect = useRender((r) => r.glowDetect)
  let smoothGlow = useRender((r) => r.smoothGlow)

  let lights = useRender((r) => r.lights)
  let meshes = useRender((r) => r.meshes)
  return (
    <>
      <EffectComposer multisampling={4} disableNormalPass>
        <SelectiveBloom
          lights={lights}
          selection={meshes}
          selectionLayer={10}
          luminanceThreshold={glowDetect}
          intensity={intensity}
          mipmapBlur={smoothGlow}
        />
        {/* <DepthOfField focusDistance={1} focalLength={0.3} bokehScale={1} height={480} /> */}
        {/* <Vignette></Vignette> */}
      </EffectComposer>
    </>
  )
}
