import { Canvas, useThree } from '@react-three/fiber'
import { useCallback, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import tunnel from 'tunnel-rat'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { DynamicDrawUsage, Object3D } from 'three'
import { useFiles } from './useFiles'
import { OrbitControls } from '@react-three/drei'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const myTunnel = tunnel()

export function Generator() {
    let acceptedFiles = useFiles((r) => r.acceptedFiles)
    return (
        <>
            <MyDropzone />
            <div className="w-96 h-96">
                <Canvas>
                    {acceptedFiles && <Encoder acceptedFiles={acceptedFiles}></Encoder>}
                    <OrbitControls makeDefault></OrbitControls>

                    <directionalLight position={[10, 10, 10]}></directionalLight>
                </Canvas>
            </div>
        </>
    )
}

function Encoder({ acceptedFiles = [] }) {
    useEffect(() => {
        let cleanup = () => {}
        let loader = new OBJLoader()
        let exporter = new OBJExporter()

        Promise.resolve().then(async () => {
            if (acceptedFiles.length === 0) {
                return
            }
            for (let idx = 0; idx < acceptedFiles.length; idx += 1) {
                let file = acceptedFiles[idx]

                await file.text().then(async (r) => {
                    let parsed = loader.parse(r)

                    parsed.traverse((it) => {
                        if (it.geometry) {
                            let bufferAttr = it.geometry.attributes.position
                            bufferAttr.setUsage(DynamicDrawUsage)
                            let count = bufferAttr.count

                            for (let i = 0; i < count; i += 1) {
                                if (i % 2 === 0) {
                                    bufferAttr.setXYZ(i, 2.0 * bufferAttr.getX(i), 2.0 * bufferAttr.getY(i), 2.0 * bufferAttr.getZ(i))
                                }
                            }

                            it.geometry.needsUpdate = true
                        }
                    })

                    let str = exporter.parse(parsed)

                    var zip = new JSZip()
                    var ringFolder = zip.folder('ring')
                    ringFolder.file(file.name, str) //, { base64: true }
                    await zip.generateAsync({ type: 'blob' }).then((content) => {
                        // see FileSaver.js
                        let list = file.name.split('.')
                        list.pop()
                        saveAs(content, list.join('.') + '.zip')
                    })
                })
            }
        })

        return () => {
            cleanup()
        }
    }, [acceptedFiles])

    return <group></group>
}

function MyDropzone() {
    useEffect(() => {
        useFiles.setState({ acceptedFiles: [] })
    }, [])
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        console.log(acceptedFiles)
        useFiles.setState({ acceptedFiles })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className="dropzone w-96 h-96 bg-gray-300 flex items-center justify-center" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop to Generate Files</p> : <p>Drop to Generate Files</p>}
        </div>
    )
}
