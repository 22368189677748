import { Suspense, useRef } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import { OrbitControls, CubeCamera, Box, useEnvironment, PerspectiveCamera, Sphere } from '@react-three/drei'
import { RefractionMaterial } from './RefractionMaterial'
import { useTweaks } from 'use-tweaks'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Color, EquirectangularReflectionMapping, HalfFloatType } from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'

function Diamond({ url, computeEnvMap }) {
  // let envMap = useEnvironment({ files: `/hdr/modern_buildings_1k.hdr` })
  let exr = useLoader(EXRLoader, '/brown_photostudio_07-bw.exr', (loader) => {
    loader.setDataType(HalfFloatType)
  })
  exr.mapping = EquirectangularReflectionMapping

  let scene = useThree((r) => r.scene)
  scene.background = new Color('#f0f0f0')
  scene.environment = computeEnvMap

  const obj = useLoader(OBJLoader, url)

  const config = useTweaks({
    bounces: { value: 5, min: 0, max: 8, step: 1 },
    aberrationStrength: { value: 0.01, min: 0, max: 0.1, step: 0.01 },
    ior: { value: 3, min: 0, max: 10 },
    fresnel: { value: 0, min: 0, max: 1 },
    color: '#ffffff',
    fastChroma: false,
    metalColor: '#ffffff',
    roughness: { value: 0.15, min: 0, max: 1 },
    metalness: { value: 1, min: 0, max: 1 }
  })

  let diamondName = false

  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name.toLowerCase().includes('diamond') && !diamondName) {
        diamondName = it.name
      }
    }
  })

  let items = []

  return (
    <>
      {/* <Sphere>
        <meshStandardMaterial metalness={1} envMap={envMap} roughness={0} side={BackSide}></meshStandardMaterial>
      </Sphere> */}

      <CubeCamera resolution={256} position={[0, 0.02, 0]} envMap={computeEnvMap} frames={Infinity}>
        {(texture) => {
          obj.traverse((it) => {
            if (it.isMesh) {
              if (it.name !== diamondName) {
                items.push(
                  <group key={it.uuid}>
                    {createPortal(
                      <meshStandardMaterial
                        envMap={computeEnvMap}
                        color={config.metalColor}
                        roughness={config.roughness}
                        metalness={config.metalness}
                      />,
                      it
                    )}
                  </group>
                )
              }
            }
          })

          return (
            <>
              {/*  */}
              {createPortal(<RefractionMaterial envMap={computeEnvMap} {...config} toneMapped={false} />, obj.getObjectByName(diamondName))}
            </>
          )
        }}
      </CubeCamera>

      {items}

      {<primitive object={obj}></primitive>}
    </>
  )
}

function Picker() {
  let { ring } = useTweaks(
    'choose-ring',
    {
      ring: {
        value: `/ring/01257R(27-7-2023).obj`,
        options: {
          [`01257R(27-7-2023)`]: `/ring/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring/90257R(27-7-2023).obj`
        }
      }
    },
    {
      // container: document.querySelector('#tweak')
    }
  )

  let { envMap: computeEnvMap } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    // vec4 hdrTextureC4 = texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 2.3, 0.1), 2.0);

    outColor.rgb = vec3(
      0.2 + 3.5 * pLayout,
      0.2 + 3.5 * pLayout,
      0.2 + 3.5 * pLayout
    );

    return outColor;
  }

  `,
    { time: { value: 0 }, envLightIntensity: { value: 1 } },
    256,
    true
  )

  return (
    <>
      <Suspense fallback={null}>
        <group scale={1}>
          <Diamond computeEnvMap={computeEnvMap} url={ring} key={ring} />
        </group>
      </Suspense>
    </>
  )
}
function ShardContent() {
  return (
    <>
      <Picker></Picker>

      {/*  */}
      <ambientLight intensity={1} />
      <spotLight position={[1, 1, 1]} angle={0.15} penumbra={1} />
      <pointLight intensity={1} position={[1, 1, -1]} />
      <pointLight intensity={1} position={[-1, 1, -1]} />

      {/* <Environment files={} background></Environment> */}

      <EffectComposer multisampling={4} disableNormalPass>
        <Bloom luminanceThreshold={0.95} intensity={1} mipmapBlur={true} />
      </EffectComposer>

      <PerspectiveCamera near={0.005} far={10} makeDefault></PerspectiveCamera>

      <OrbitControls minDistance={0.015} object-position={[0, 0.045, 0.03]} target={[0, 0.02, 0]}></OrbitControls>
    </>
  )
}

export function Shard() {
  return (
    <>
      <Canvas>
        <ShardContent></ShardContent>
      </Canvas>
    </>
  )
}
