import { Canvas, useThree } from '@react-three/fiber'
import { useCallback, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import tunnel from 'tunnel-rat'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { DynamicDrawUsage, Object3D } from 'three'
import { useFiles } from './useFiles'
import { OrbitControls } from '@react-three/drei'
// import JSZip from 'jszip'
// import { saveAs } from 'file-saver'
// import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import slugify from 'slugify'
import { OBJExporterCustom } from './OBJExporterCustom'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const myTunnel = tunnel()

export function AutoPipeOBJ() {
    let acceptedFiles = useFiles((r) => r.acceptedFiles)
    return (
        <>
            <MyDropzone />
            <div className="w-96 h-96">
                <Canvas>
                    {acceptedFiles && <Encoder acceptedFiles={acceptedFiles}></Encoder>}
                    <OrbitControls makeDefault></OrbitControls>

                    <directionalLight position={[10, 10, 10]}></directionalLight>
                </Canvas>
            </div>
        </>
    )
}

//

export function restoreSceneOBJ({ scene }) {
    scene.traverse((it) => {
        if (it.geometry && !it.geometry.restored) {
            it.geometry.restored = true
            {
                let buffAttr = it.geometry.attributes.position
                buffAttr.setUsage(DynamicDrawUsage)
                let count = buffAttr.count

                for (let i = 0; i < count; i += 1) {
                    if (i % 2 === 0) {
                        buffAttr.setXYZ(
                            i,
                            //
                            (1.0 / 20.0) * buffAttr.getX(i),
                            (1.0 / -10.0) * buffAttr.getY(i),
                            (1.0 / -30.0) * buffAttr.getZ(i)
                        )
                    }
                }
            }

            {
                let buffAttr = it.geometry.attributes.normal
                buffAttr.setUsage(DynamicDrawUsage)
                let count = buffAttr.count

                for (let i = 0; i < count; i += 1) {
                    if (i % 2 === 0) {
                        buffAttr.setXYZ(
                            i,
                            //
                            (1.0 / 20.0) * buffAttr.getX(i),
                            (1.0 / -10.0) * buffAttr.getY(i),
                            (1.0 / -30.0) * buffAttr.getZ(i)
                        )
                    }
                }
            }

            it.geometry.needsUpdate = true
        }
    })

    return scene
}

export function encodeSceneOBJ({ scene }) {
    scene.traverse((it) => {
        if (it.geometry && !it.geometry.restored) {
            it.geometry.restored = true
            {
                let buffAttr = it.geometry.attributes.position
                buffAttr.setUsage(DynamicDrawUsage)
                let count = buffAttr.count

                for (let i = 0; i < count; i += 1) {
                    if (i % 2 === 0) {
                        buffAttr.setXYZ(
                            i,
                            //
                            20 * buffAttr.getX(i),
                            -10 * buffAttr.getY(i),
                            -30 * buffAttr.getZ(i)
                        )
                    }
                }
            }

            {
                let buffAttr = it.geometry.attributes.normal
                buffAttr.setUsage(DynamicDrawUsage)
                let count = buffAttr.count

                for (let i = 0; i < count; i += 1) {
                    if (i % 2 === 0) {
                        buffAttr.setXYZ(
                            i,
                            //
                            20 * buffAttr.getX(i),
                            -10 * buffAttr.getY(i),
                            -30 * buffAttr.getZ(i)
                        )
                    }
                }
            }

            it.geometry.needsUpdate = true
        }
    })

    return scene
}

function Encoder({ acceptedFiles = [] }) {
    let scene = useThree((r) => r.scene)

    useEffect(() => {
        let cleanup = () => {}
        let loader = new OBJLoader()
        let exporter = new OBJExporter()
        // let gltfLoader = new GLTFLoader()

        Promise.resolve().then(async () => {
            if (acceptedFiles.length === 0) {
                return
            }
            for (let idx = 0; idx < acceptedFiles.length; idx += 1) {
                let file = acceptedFiles[idx]

                await file.text().then(async (r) => {
                    let objData = r.split('\n').reverse().join('\n')
                    // let parsed = loader.parse(r)

                    // // let beforeSync = await exporter.parseAsync(parsed, {
                    // //     binary: true,
                    // //     truncateDrawRange: false,
                    // //     includeCustomExtensions: true,
                    // //     forceIndices: false
                    // // })

                    // // let glb = await gltfLoader.parseAsync(beforeSync, '/')
                    // let encoded = encodeSceneOBJ({ scene: parsed })
                    // // let decoded = restoreScene({ scene: encoded })
                    // // scene.add(decoded)

                    // let objData = await exporter.parse(encoded)

                    let fileName = file.name

                    fileName = fileName.replace('#', '__hh__')
                    fileName = fileName.replace(')(', '__sp__')
                    fileName = slugify(fileName, {})

                    let fileNameFull = fileName.split('.obj').join('') + '.zip'
                    let fd = new FormData()
                    fd.append('file', new Blob([objData], { type: 'application/octet-stream' }), fileNameFull)
                    fd.append('slug', fileNameFull)

                    // // (Bday St) 92589R
                    // let extractFingerName = fileNameFull.split('__hh__')
                    // let exFigerLastPart = extractFingerName.pop()
                    // let exFigerLastPartList = exFigerLastPart.split('__sp__')
                    // let fingerSize = exFigerLastPartList.shift()
                    // let extractTypeName = file.name.split('(#').shift()

                    // // (Bday St) 90585R
                    // let srcName = file.name
                    // let diamondSeries = srcName.split('(#')[0]
                    // let fingerSize = srcName.split('(#')[1].split(')')[0]

                    // // Fancy cut 90450R OBJ & Mtl
                    //FancyCut-90450R
                    let srcName = file.name.trim()
                    let diamondSeries = srcName.split('(')[0].trim()
                    let fingerSize = srcName.split('#')[1].split(').obj')[0].trim()
                    let ct = srcName.split('(')[1].split(' #')[0].trim()

                    // // Fancy cut 94052R OBJ & Mtl
                    // let srcName = file.name.trim()
                    // let diamondSeries = srcName.split('(')[0].trim()
                    // let fingerSize = srcName.split('#')[1].split(').obj')[0].trim()
                    // let ct = srcName.split('(')[1].split(' #')[0].trim()

                    fd.append('fingerSize', fingerSize)
                    fd.append('diamondSeries', diamondSeries)
                    fd.append('ct', ct)

                    await fetch(`http://localhost:3001/file-obj`, {
                        // headers: {
                        //     filename: 'screencapture.png'
                        // },
                        mode: 'cors',
                        method: 'POST',
                        body: fd
                    })
                        .then((r) => {
                            return r.ok && r.json()
                        })
                        .then((r) => {
                            console.log(r)
                            // onClose()
                        })
                        .catch((r) => {
                            console.log(r)
                        })

                    // fd.append('origin', window.location.origin)
                    // fd.append('pathname', window.location.pathname)

                    // let str = exporter.parse(parsed)

                    // var zip = new JSZip()
                    // var ringFolder = zip.folder('ring')
                    // ringFolder.file(file.name, str) //, { base64: true }
                    // await zip.generateAsync({ type: 'blob' }).then((content) => {
                    //     // see FileSaver.js
                    //     let list = file.name.split('.')
                    //     list.pop()
                    //     saveAs(content, list.join('.') + '.zip')
                    // })
                })
            }
        })

        return () => {
            cleanup()
        }
    }, [acceptedFiles])

    return <group></group>
}

function MyDropzone() {
    useEffect(() => {
        useFiles.setState({ acceptedFiles: [] })
    }, [])
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        console.log(acceptedFiles)

        acceptedFiles = acceptedFiles.filter((r) => r.name.endsWith('.obj'))
        useFiles.setState({ acceptedFiles })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className="dropzone w-96 h-96 bg-gray-300 flex items-center justify-center" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop to Generate Files</p> : <p>Drop to Generate Files</p>}
        </div>
    )
}
