import {
  Box,
  Dodecahedron,
  OrbitControls,
  PerspectiveCamera,
  RenderTexture,
  ScreenQuad,
  Text,
  useGLTF,
  useTexture
} from '@react-three/drei'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { Suspense } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { SRGBColorSpace, EquirectangularReflectionMapping, MeshPhysicalMaterial, Vector2 } from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
function Background() {
  const brown = useLoader(RGBELoader, '/golden/christmas_photo_studio_07_1k.hdr')
  brown.mapping = EquirectangularReflectionMapping

  let scene = useThree((r) => r.scene)
  scene.backgroundIntensity = 1
  scene.backgroundBlurriness = 0
  // scene.background = new Color('#ffffff')
  // scene.background = brown
  scene.environment = brown

  useEffect(() => {
    setTimeout(() => {
      brown.needsPMREMUpdate = true
      brown.needsUpdate = true
    }, 1)
  }, [])
  return null
}

export function GoldApp() {
  return (
    <>
      <Canvas shadows camera={{ fov: 45, near: 0.1, far: 50 }} gl={{ outputColorSpace: SRGBColorSpace }}>
        <color attach="background" args={['#f0f0f0']} />
        <Background></Background>
        <Suspense fallback={null}>
          <GoldenBagGLB></GoldenBagGLB>
        </Suspense>

        <OrbitControls target={[0, 2, 0]} object-position={[0, 5, 15]}></OrbitControls>
      </Canvas>
    </>
  )
}

function GoldenBagGLB() {
  let glb = useGLTF(`/golden/bag--811150967.glb`)

  let textures = useTexture({
    map: '/golden/bag2k/color.jpg',
    normalMap: '/golden/bag2k/normal.jpg',
    roughnessMap: '/golden/bag2k/roughnessmetalic.jpg'
  })

  textures.map.colorSpace = SRGBColorSpace
  textures.map.generateMipmaps = true
  textures.normalMap.generateMipmaps = true
  textures.roughnessMap.generateMipmaps = true
  // textures.roughnessMap.colorSpace = SRGBColorSpace
  //!SECTION

  //
  glb.scene.traverse((it) => {
    if (it.material?.name === 'Yellow_gold_18K') {
      console.log(it.material)
      it.material.map = textures.map
      it.material.normalMap = textures.normalMap
      it.material.metalnessMap = textures.roughnessMap
      it.material.roughnessMap = textures.roughnessMap
    }
  })

  return (
    <>
      <primitive object={glb.scene} />
    </>
  )
}

// function GoldenBag() {
//   let objStuff = useLoader(OBJLoader, `/ring/89197C.obj`)

//   objStuff.traverse((it) => {
//     console.log(it)
//     if (it.isMesh) {
//       let mat = new MeshPhysicalMaterial({
//         color: '#ffffff',
//         roughness: 0,
//         metalness: 1
//       })

//       mat.onBeforeCompile = (shader) => {
//         shader.fragmentShader = shader.fragmentShader.replace(``, ``)
//       }
//       it.material = mat
//     }
//   })

//   return (
//     <group scale={200}>
//       <primitive object={objStuff}></primitive>
//     </group>
//   )
// }
