import { Canvas } from '@react-three/fiber'
import { useEffect } from 'react'

export function Rhino() {
  return (
    <div className="w-full h-full">
      <Canvas></Canvas>
    </div>
  )
}

function RLoader() {
  useEffect(() => {
    // import rhino3dm from
    // import('https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/rhino3dm.module.js').then((r) => {
    //   console.log(r)
    // })
    // // Instantiate a loader
    // const loader = new Rhino3dmLoader()
    // // Specify path to a folder containing WASM/JS libraries or a CDN.
    // loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/')
    // rhino3dm().then(async (m) => {
    //   console.log('Loaded rhino3dm.')
    //   const rhino = m // global
    //   // create Rhino Document and add a point to it
    //   const doc = new rhino.File3dm()
    //   const ptA = [0, 0, 0]
    //   const point = new rhino.Point(ptA)
    //   doc.objects().add(point, null)
    //   // create a copy of the doc.toByteArray data to get an ArrayBuffer
    //   const buffer = new Uint8Array(doc.toByteArray()).buffer
    //   loader.parse(buffer, function (object) {
    //     scene.add(object)
    //   })
    // })
  }, [])
  return <></>
}
