import { Suspense, useEffect, useRef } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import { OrbitControls, CubeCamera, Box, useEnvironment, PerspectiveCamera, Sphere } from '@react-three/drei'
import { RefractionMaterial } from './RefractionMaterial'
import { useTweaks } from 'use-tweaks'
import { Bloom, DepthOfField, EffectComposer, SSR, SelectiveBloom, Vignette } from '@react-three/postprocessing'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Color, EquirectangularReflectionMapping, HalfFloatType } from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { useComputeEnvMap } from 'src/useComputeEnvMap'
import { DiamondMaterialCustomKey, RefractionMaterialCustom } from './RefractionMaterialCustom'
import { create } from 'zustand'
function Diamond({ url, computedMetalEnv, computedRainbowEnv }) {
  let scene = useThree((r) => r.scene)
  scene.environment = computedMetalEnv

  const obj = useLoader(OBJLoader, url)

  const config = useTweaks('raytrace', {
    bounces: { value: 5, min: 0, max: 8, step: 1 },
    aberrationStrength: { value: 0.01, min: 0, max: 0.1, step: 0.01 },
    ior: { value: 2.418, min: 0, max: 10 },
    fresnel: { value: 0, min: 0, max: 1 },
    color: '#ffffff',
    fastChroma: false,
    metalColor: '#ffffff',
    roughness: { value: 0.15, min: 0, max: 1 },
    metalness: { value: 1, min: 0, max: 1 }
  })

  let diamondName = false

  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name.toLowerCase().includes('diamond') && !diamondName) {
        diamondName = it.name
      }
    }
  })

  let items = []

  useEffect(() => {
    useRender.setState({ meshes: [obj.getObjectByName(diamondName)] })
  })

  return (
    <>
      {/* <Sphere>
        <meshStandardMaterial metalness={1} envMap={envMap} roughness={0} side={BackSide}></meshStandardMaterial>
      </Sphere> */}

      <CubeCamera resolution={64} position={[0, 0.02, 0]} frames={Infinity}>
        {(texture) => {
          obj.traverse((it) => {
            if (it.isMesh) {
              if (it.name !== diamondName) {
                items.push(
                  <group key={it.uuid}>
                    {createPortal(
                      <meshStandardMaterial
                        envMap={computedMetalEnv}
                        color={config.metalColor}
                        roughness={config.roughness}
                        metalness={config.metalness}
                      />,
                      it
                    )}
                  </group>
                )
              }
            }
          })

          return (
            <>
              {/*  */}
              {createPortal(
                <RefractionMaterialCustom key={DiamondMaterialCustomKey} envMap={computedRainbowEnv} {...config} toneMapped={false} />,
                obj.getObjectByName(diamondName)
              )}
            </>
          )
        }}
      </CubeCamera>

      {items}

      {<primitive object={obj}></primitive>}
    </>
  )
}

function Picker() {
  let { ring } = useTweaks(
    'choose-ring',
    {
      ring: {
        value: `/ring/01257R(27-7-2023).obj`,
        options: {
          [`01257R(27-7-2023)`]: `/ring/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring/90257R(27-7-2023).obj`
        }
      }
    },
    {
      // container: document.querySelector('#tweak')
    }
  )

  let { envMap: computedMetalEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    // vec4 hdrTextureC4 = texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 2.3, 0.1), 2.0);

    outColor.rgb = vec3(
      0.2 + 3.5 * pLayout,
      0.2 + 3.5 * pLayout,
      0.2 + 3.5 * pLayout
    );

    return outColor;
  }

  `,
    { time: { value: 0 }, envLightIntensity: { value: 1 } },
    32,
    true
  )

  // let exr = useEnvironment({ files: `/hdr/modern_buildings_1k.hdr` })

  let exr = useLoader(EXRLoader, '/brown_photostudio_07-bw.exr', (loader) => {
    loader.setDataType(HalfFloatType)
  })
  exr.mapping = EquirectangularReflectionMapping

  let { lightness, rainbow, contrast } = useTweaks('diamond hdr', {
    lightness: { value: 3.75, min: 0, max: 5 },
    rainbow: { value: 0.0043478261, min: 0, max: 0.1 },
    contrast: { value: 3.3152173913, min: 0, max: 10 }
  })
  let { envMap: computedRainbowEnv } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  // float pattern (vec2 p, float time) {
  //   float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
  //   return abs(vout);
  // }


  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm4(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  /**
   * Adjusts the saturation of a color.
   *
   * @name czm_saturation
   * @glslFunction
   *
   * @param {vec3} rgb The color.
   * @param {float} adjustment The amount to adjust the saturation of the color.
   *
   * @returns {float} The color with the saturation adjusted.
   *
   * @example
   * vec3 greyScale = czm_saturation(color, 0.0);
   * vec3 doubleSaturation = czm_saturation(color, 2.0);
   */
  vec3 czm_saturation(vec3 rgb, float adjustment)
  {
      // Algorithm from Chapter 16 of OpenGL Shading Language
      const vec3 W = vec3(0.2125, 0.7154, 0.0721);
      vec3 intensity = vec3(dot(rgb, W));
      return mix(intensity, rgb, adjustment);
  }

  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    vec4 hdrV4 = texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout = pow(pattern(direction.xz * 2.3, 0.1), 2.0);

    outColor.rgb = vec3(
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 + ${rainbow.toFixed(3)}, time * 0.05),
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0, time * 0.05),
      ${lightness.toFixed(3)} * pattern((direction.xz * 0.5 + 0.5) * 15.0 - ${rainbow.toFixed(3)}, time * 0.05)
    );

    outColor.r = pow(outColor.r, ${contrast.toFixed(3)});
    outColor.g = pow(outColor.g, ${contrast.toFixed(3)});
    outColor.b = pow(outColor.b, ${contrast.toFixed(3)});

    outColor.rgb = mix(hdrV4.rgb * 1.3, outColor.rgb, 0.1 / 2.0);

    outColor.rgb  = czm_saturation(outColor.rgb, 0.1);
    return outColor;
  }

  `,
    { time: { value: 0 }, hdrTexture: { value: exr }, envLightIntensity: { value: 1 } },
    512,
    true
  )

  let scene = useThree((r) => r.scene)
  scene.background = new Color('#f0f0f0')
  // scene.background = computedRainbowEnv // new Color('#f0f0f0')

  useEffect(() => {
    return () => {
      computedRainbowEnv.dispose()
    }
  }, [computedRainbowEnv])

  return (
    <>
      <Suspense fallback={null}>
        <group scale={1}>
          <Diamond computedRainbowEnv={computedRainbowEnv} computedMetalEnv={computedMetalEnv} url={ring} key={ring} />
        </group>
      </Suspense>
    </>
  )
}
function ShardContent() {
  return (
    <>
      <Picker></Picker>

      {/*  */}
      <ambientLight intensity={1} />
      <spotLight position={[1, 1, 1]} angle={0.15} penumbra={1} />
      <pointLight intensity={1} position={[1, 1, -1]} />
      <pointLight intensity={1} position={[-1, 1, -1]} />

      {/* <Environment files={} background></Environment> */}
      <PP></PP>

      <PerspectiveCamera near={0.005} far={10} makeDefault></PerspectiveCamera>

      <OrbitControls minDistance={0.015} object-position={[0, 0.045, 0.03]} target={[0, 0.02, 0]}></OrbitControls>
    </>
  )
}

export function Shard2() {
  return (
    <>
      <Canvas>
        <ShardContent></ShardContent>
      </Canvas>
    </>
  )
}

let useRender = create((set, get) => {
  return {
    //
    lights: [],
    meshes: []
    //
  }
})

function PP() {
  let { glowDetect, intensity, smoothGlow } = useTweaks(
    'flare',
    {
      smoothGlow: true,
      glowDetect: { value: 0.98, min: 0, max: 1 },
      intensity: { value: 1.47, min: 0, max: 15 }
    },

    {
      // container: document.querySelector('#tweak')
    }
  )

  let lights = useRender((r) => r.lights)
  let meshes = useRender((r) => r.meshes)
  return (
    <EffectComposer multisampling={8} disableNormalPass>
      <SelectiveBloom
        lights={lights}
        selection={meshes}
        selectionLayer={10}
        luminanceThreshold={glowDetect}
        intensity={intensity}
        mipmapBlur={smoothGlow}
      />
      <DepthOfField focusDistance={0.003} focalLength={0.02} bokehScale={2} height={480} />
      <Vignette></Vignette>
    </EffectComposer>
  )
}
