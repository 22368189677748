import './styles.css'
import App from './App'
import AppV2 from './AppV2'
import AppV3 from './AppV3'
import { GoldApp } from './GoldApp'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { TestLab } from './TestLab.js'
import { Shard } from './shard/Shard'
import { Shard2 } from './shard/Shard2'
import { Shard3 } from './shard/Shard3'
import { Shard4 } from './shard/Shard4'
import { Shard5 } from './shard/Shard5'
import { Shard6 } from './shard/Shard6'
import { Shard7DiamindShow } from './shard/Shard7DiamindShow'
import { Shard7DiamindShowMT } from './shard/Shard7DiamindShowMT'
// import { Shard8Contrast } from './shard/Shard8Contrast_Backup'
import { Shard9Shadow } from './shard/Shard9Shadow'
import { Rhino } from './shard/Rhino'
import { Shard10Geometry } from './shard/Shard10Geometry'
import { Generator } from './Generator/Generator'
import { Loader } from './Generator/Loader'
import { Engraving } from './shard/Engraving'
import { AutoPipe } from './auto-load/AutoPipe'
import { LandGUI } from './auto-load/LandGUI'
// import { MainGUI } from './auto-load/MainGUI'
import { Shard11GUI } from './shard/Shard11GUI'
import { Shard12GUI } from './shard/gui12/Shard12GUI'
import { Shard13GUI } from './shard/gui13/Shard13GUI'
import { Shard14GUI } from './shard/gui14/Shard14GUI'
import { AutoPipeOBJ } from './auto-load-obj/AutoPipeOBJ'
import { Shard15GUI } from './shard/gui15/Shard15GUI'
function Home() {
    return (
        <>
            <nav style={{ padding: `20px` }}>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/diamondrings">
                    Diamond Ring Render
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/diamondrings-v2">
                    Diamond Ring Render Version 2
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/diamondrings-v3">
                    Diamond Ring Render Version 3
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/golden">
                    Golden Jewellery Render
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab">
                    Test Lab 1
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-2">
                    Test Lab 2
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-3">
                    Test Lab 3
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-4">
                    Test Lab 4
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-5">
                    Test Lab 5
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-6">
                    Test Lab 6
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/test-lab-7">
                    Test Lab 7
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R">
                    94010R
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R-MT">
                    94010R-MT
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R-contrast">
                    94010R-contrast
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R-shadow">
                    94010R-shadow
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R-v10-geometry">
                    94010R-v10-geometry
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/94010R-rhino">
                    94010R-rhino
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/generator">
                    OBJ_Generator
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/loader">
                    OBJ_Loader
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/engraving">
                    Engraving
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/autopipe">
                    AutoPipe
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/autopipe-OBJ">
                    AutoPipe OBJ
                </Link>
                {/* <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/gui">
                    Landing GUI
                </Link> */}
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/main-gui">
                    Main GUI
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/main-gui-12">
                    Main GUI - v12
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/main-gui-13">
                    Main GUI - v13
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/main-gui-14">
                    Main GUI - v14 (for meeting)
                </Link>
                <Link style={{ padding: '3px 2px', display: 'block', margin: '5px 5px 0px 0px' }} to="/main-gui-15-previewer">
                    Main GUI - v15 (preview ring)
                </Link>
            </nav>
        </>
    )
}
export function Chooser() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/diamondrings">
                        <App />
                    </Route>
                    <Route path="/diamondrings-v2">
                        <AppV2 />
                    </Route>
                    <Route path="/diamondrings-v3">
                        <AppV3 />
                    </Route>
                    <Route path="/test-lab">
                        <TestLab />
                    </Route>
                    <Route path="/test-lab-2">
                        <Shard />
                    </Route>
                    <Route path="/test-lab-3">
                        <Shard2 />
                    </Route>
                    <Route path="/test-lab-4">
                        <Shard3 />
                    </Route>
                    <Route path="/test-lab-5">
                        <Shard4 />
                    </Route>
                    <Route path="/test-lab-6">
                        <Shard5 />
                    </Route>
                    <Route path="/test-lab-7">
                        <Shard6 />
                    </Route>

                    <Route path="/94010R">
                        <Shard7DiamindShow />
                    </Route>

                    <Route path="/94010R-MT">
                        <Shard7DiamindShowMT />
                    </Route>

                    <Route path="/94010R-contrast">
                        {/* <Shard8Contrast /> */}
                        <Shard9Shadow />
                    </Route>

                    <Route path="/94010R-shadow">
                        <Shard9Shadow />
                    </Route>

                    <Route path="/94010R-v10-geometry">
                        <Shard10Geometry></Shard10Geometry>
                    </Route>

                    <Route path="/94010R-rhino">
                        <Rhino />
                    </Route>

                    <Route path="/engraving">
                        <Engraving></Engraving>
                    </Route>

                    <Route path="/generator">
                        <Generator></Generator>
                    </Route>

                    <Route path="/autopipe">
                        <AutoPipe></AutoPipe>
                    </Route>
                    <Route path="/autopipe-OBJ">
                        <AutoPipeOBJ></AutoPipeOBJ>
                    </Route>
                    <Route path="/loader">
                        <Loader></Loader>
                    </Route>

                    <Route path="/golden">
                        <GoldApp />
                    </Route>
                    <Route path="/gui">
                        <LandGUI />
                    </Route>
                    <Route path="/main-gui">
                        <Shard11GUI />
                    </Route>
                    <Route path="/main-gui-12">
                        <Shard12GUI />
                    </Route>
                    <Route path="/main-gui-13">
                        <Shard13GUI />
                    </Route>
                    <Route path="/main-gui-14">
                        {/* <Shard13GUI /> */}
                        <Shard14GUI />
                    </Route>

                    <Route path="/main-gui-15-previewer">
                        <Shard15GUI />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </>
    )
}
