import { Canvas, useThree } from '@react-three/fiber'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import tunnel from 'tunnel-rat'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { DynamicDrawUsage, Object3D } from 'three'
import { cacheURL, useFiles } from './useFiles'
import { OrbitControls } from '@react-three/drei'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { unzip } from 'unzipit'

const myTunnel = tunnel()

export function Loader() {
    let acceptedFiles = useFiles((r) => r.acceptedFiles)
    return (
        <>
            <MyDropzone />
            <div className="w-96 h-96">
                <Canvas>
                    {acceptedFiles && <Decoder acceptedFiles={acceptedFiles}></Decoder>}
                    <OrbitControls makeDefault></OrbitControls>

                    <directionalLight position={[10, 10, 10]}></directionalLight>
                </Canvas>
            </div>
        </>
    )
}

function Decoder({ acceptedFiles = [] }) {
    let [display, setDisplay] = useState(null)
    useEffect(() => {
        let cleanup = () => {}
        let loader = new OBJLoader()

        Promise.resolve().then(async () => {
            if (acceptedFiles.length === 0) {
                return
            }
            for (let idx = 0; idx < acceptedFiles.length; idx += 1) {
                let file = acceptedFiles[idx]

                let bURL = URL.createObjectURL(file)

                let objURL = await unzip(bURL).then(async (r) => {
                    let entries = r.entries
                    let values = Object.values(entries)
                    let obj = values.find((it) => it.name.includes('.obj') || it.name.includes('.glb'))
                    let myblob = await obj.blob()
                    let link = URL.createObjectURL(myblob)

                    return link
                })

                let text = await fetch(`${objURL}`).then((r) => r.text())

                Promise.resolve(text).then(async (r) => {
                    let parsed = loader.parse(r)

                    parsed.traverse((it) => {
                        if (it.geometry) {
                            let bufferAttr = it.geometry.attributes.position
                            bufferAttr.setUsage(DynamicDrawUsage)
                            let count = bufferAttr.count

                            for (let i = 0; i < count; i += 1) {
                                if (i % 2 === 0) {
                                    bufferAttr.setXYZ(
                                        i,
                                        (1 / 2.0) * bufferAttr.getX(i),
                                        (1 / 2.0) * bufferAttr.getY(i),
                                        (1 / 2.0) * bufferAttr.getZ(i)
                                    )
                                }
                            }

                            it.geometry.needsUpdate = true
                        }
                    })

                    setDisplay(<primitive object={parsed}></primitive>)
                })
            }
        })

        return () => {
            cleanup()
        }
    }, [acceptedFiles])

    return <group>{display}</group>
}
function MyDropzone() {
    useEffect(() => {
        useFiles.setState({ acceptedFiles: [] })
    }, [])
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        console.log(acceptedFiles)
        useFiles.setState({ acceptedFiles })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className="dropzone w-96 h-96 bg-gray-300 flex items-center justify-center" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop to Decode Files</p> : <p>Drop to Decode Files</p>}
        </div>
    )
}
