import { Suspense, useEffect, useRef } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
  useGLTF,
  Caustics,
  CubeCamera,
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  MeshRefractionMaterial,
  MeshTransmissionMaterial,
  Text
} from '@react-three/drei'
import { EffectComposer, Bloom, DepthOfField } from '@react-three/postprocessing'
// import { useControls } from 'leva'
import { RGBELoader } from 'three-stdlib'
import { EquirectangularReflectionMapping, SRGBColorSpace } from 'three'
// import { useEnvLightImg } from './useEnvLightImg'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useTweaks } from 'use-tweaks'

function Diamond({ url, ...props }) {
  let obj = useLoader(OBJLoader, url)

  let diamondName = false

  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name.toLowerCase().includes('diamond') && !diamondName) {
        diamondName = it.name
      }
    }
  })

  // const { nodes, scene: yo } = useGLTF('/ring-90147R(27-7-2023).glb')
  // Use a custom envmap/scene-backdrop for the diamond material
  // This way we can have a clear BG while cube-cam can still film other objects
  // const paris = useLoader(RGBELoader, '/little_paris_eiffel_tower_1k.hdr')
  // paris.mapping = EquirectangularReflectionMapping
  // const office = useLoader(RGBELoader, '/unfinished_office_1k.hdr')
  // office.mapping = EquirectangularReflectionMapping

  // let { envMap } = useEnvLightImg({ src: `/brown_photostudio_07.webp` })

  const brown = useLoader(RGBELoader, '/brown_photostudio_02_1k.hdr')
  //public/studio_small_09_1k.hdr
  brown.mapping = EquirectangularReflectionMapping

  let scene = useThree((r) => r.scene)
  scene.backgroundIntensity = 1
  scene.backgroundBlurriness = 0
  // scene.background = new Color('#ffffff')
  // scene.background = brown
  scene.environment = brown

  useEffect(() => {
    setTimeout(() => {
      brown.needsPMREMUpdate = true
      brown.needsUpdate = true
    }, 1000)
  }, [])

  // Optional config
  const config = useTweaks('reflection', {
    bounces: { value: 5, min: 0, max: 8, step: 1 },
    aberrationStrength: { value: 0.01, min: 0, max: 0.1, step: 0.01 },
    ior: { value: 3.9, min: 0, max: 10 },
    fresnel: { value: 1, min: 0, max: 1 },
    color: '#ffffff',
    metalColor: '#ffffff',
    fastChroma: true,
    roughness: { value: 0.1333, min: 0, max: 1 },
    metalness: { value: 1, min: 0, max: 1 }
  })

  let items = []
  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name !== diamondName) {
        items.push(
          <group key={it.uuid}>
            {createPortal(<meshStandardMaterial color={config.metalColor} roughness={config.roughness} metalness={config.metalness} />, it)}
          </group>
        )
      }
    }
  })

  return (
    <>
      <group {...props}>
        <>
          <CubeCamera resolution={64} position={[0, 4, 0]} envMap={brown} frames={Infinity}>
            {(texture) => {
              // texture.mapping = EquirectangularReflectionMapping
              // texture.needsPMREMUpdate = true
              return (
                <>
                  {createPortal(
                    <MeshRefractionMaterial key={`yoyo1diamond`} envMap={texture} {...config} toneMapped={false} />,
                    obj.getObjectByName(diamondName)
                  )}

                  {/* {createPortal(<meshStandardMaterial roughness={0} metalness={1} />, obj.getObjectByName('Ring'))} */}
                  {/* {createPortal(<meshStandardMaterial roughness={0} metalness={1} />, obj.getObjectByName('Ring'))} */}
                  {items}
                </>
              )
            }}
          </CubeCamera>
        </>
        {/* {createPortal(<MeshTransmissionMaterial transmission={1} envMap={brown}></MeshTransmissionMaterial>, obj.getObjectByName('Ring'))} */}

        <group scale={1}>
          <primitive object={obj}></primitive>
        </group>
      </group>
    </>
  )
}
function Cam() {
  let camera = useThree((r) => r.camera)
  camera.near = 0.05
  camera.far = 50
  camera.updateProjectionMatrix()

  return (
    <group>
      {/*  */}

      {/*  */}
    </group>
  )
}

function Picker() {
  let { ring } = useTweaks(
    'choose-ring',
    {
      ring: {
        value: `/ring/01257R(27-7-2023).obj`,
        options: {
          [`01257R(27-7-2023)`]: `/ring/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring/90257R(27-7-2023).obj`
          // none: '',
          // dark: 'dark-theme.json',
          // light: 'light-theme.json'
        }
      }
    },
    {
      // container: document.querySelector('#tweak')
    }
  )

  // let { urls } = useTweak('', {
  //   urls: [`12321321`]
  // })

  // console.log(urls)
  /*
  `/ring/01257R(27-7-2023).obj`
  `/ring/79426R(27-7-2023).obj`
  `/ring/90147R(27-7-2023).obj`
  `/ring/90257R(27-7-2023).obj`
  */

  return (
    <>
      <Suspense fallback={null}>
        <Diamond key={ring} url={ring} rotation={[3.141592 * 0.0, 0, 0]} position={[0, -0.175 + 0.5, 0]} scale={20} />
      </Suspense>
    </>
  )
}
export default function App() {
  return (
    <>
      <Canvas shadows camera={{ fov: 45, near: 0.1, far: 50 }} gl={{ outputColorSpace: SRGBColorSpace }}>
        <Cam></Cam>
        <Picker></Picker>
        <color attach="background" args={['#f0f0f0']} />

        {/*  */}
        {/* <ambientLight intensity={0.5} />
        <spotLight position={[5, 5, -10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} /> */}

        {/* <Caustics color="#FF8F20" position={[0, -0.5, 0]} lightSource={[5, 5, -10]} worldRadius={0.01} ior={1.2} intensity={0.005}>
        <mesh castShadow receiveShadow position={[-2, 0.5, -1]} scale={0.5}>
          <sphereGeometry args={[1, 64, 64]} />
          <MeshTransmissionMaterial resolution={1024} distortion={0.25} color="#FF8F20" thickness={1} anisotropy={1} />
        </mesh>
      </Caustics> */}
        {/* <mesh castShadow receiveShadow position={[1.75, 0.25, 1]} scale={0.75}>
        <sphereGeometry args={[1, 64, 64]} />
        <meshStandardMaterial color="hotpink" />
      </mesh> */}

        {/* <AccumulativeShadows
          temporal
          frames={100}
          color="orange"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.8}
          opacity={1}
          scale={12}
          position={[0, 15, 0]}>
          <RandomizedLight amount={8} radius={10} ambient={0.5} intensity={1} position={[5, 5, -5]} bias={0.001} />
        </AccumulativeShadows> */}
        {/* <Environment files="https://d.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" /> */}
        {/*  */}
        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.01}
          minPolarAngle={0}
          object-position={[0, 1.3, 1]}
          target={[0, 0.7, 0]}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer disableNormalPass>
          <Bloom luminanceThreshold={1} intensity={1.5} mipmapBlur />
        </EffectComposer>
      </Canvas>
      <div id="tweak"></div>
    </>
  )
}
