import { Suspense, useEffect, useRef } from 'react'
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
  useGLTF,
  Caustics,
  CubeCamera,
  Environment,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  MeshRefractionMaterial,
  MeshTransmissionMaterial,
  Text,
  useSelect,
  Select
} from '@react-three/drei'
import { EffectComposer, Bloom, DepthOfField, SelectiveBloom } from '@react-three/postprocessing'
// import { useControls } from 'leva'
import { RGBELoader } from 'three-stdlib'
import { EquirectangularReflectionMapping, SRGBColorSpace } from 'three'
// import { useEnvLightImg } from './useEnvLightImg'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useTweaks } from 'use-tweaks'
import { useComputeEnvMap } from './useComputeEnvMap'
import { create } from 'zustand'

let useRender = create((set, get) => {
  return {
    //
    lights: [],
    meshes: []
    //
  }
})

function Diamond({ url, computeEnvMapGlass, computeEnvMapMetal, ...props }) {
  let obj = useLoader(OBJLoader, url)

  let diamondName = false

  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name.toLowerCase().includes('diamond') && !diamondName) {
        diamondName = it.name
      }
    }
  })

  // const { nodes, scene: yo } = useGLTF('/ring-90147R(27-7-2023).glb')
  // Use a custom envmap/scene-backdrop for the diamond material
  // This way we can have a clear BG while cube-cam can still film other objects
  // const paris = useLoader(RGBELoader, '/little_paris_eiffel_tower_1k.hdr')
  // paris.mapping = EquirectangularReflectionMapping
  // const office = useLoader(RGBELoader, '/unfinished_office_1k.hdr')
  // office.mapping = EquirectangularReflectionMapping

  // let { envMap } = useEnvLightImg({ src: `/brown_photostudio_07.webp` })

  const brown = useLoader(RGBELoader, '/brown_photostudio_02_1k.hdr')
  //public/studio_small_09_1k.hdr
  brown.mapping = EquirectangularReflectionMapping

  let scene = useThree((r) => r.scene)
  scene.backgroundIntensity = 1
  scene.backgroundBlurriness = 0
  // scene.background = new Color('#ffffff')
  // scene.background = computeEnvMapGlass
  //

  scene.environment = brown

  useEffect(() => {
    setTimeout(() => {
      brown.needsPMREMUpdate = true
      brown.needsUpdate = true
    }, 1000)
  }, [])

  // Optional config
  const config = useTweaks('reflection', {
    bounces: { value: 5, min: 0, max: 8, step: 1 },
    aberrationStrength: { value: 0.04, min: 0, max: 0.1, step: 0.01 },
    ior: { value: 4, min: 0, max: 10 },
    fresnel: { value: 1, min: 0, max: 1 },
    color: '#ffffff',
    metalColor: '#ffffff',
    fastChroma: false,
    roughness: { value: 0.1333, min: 0, max: 1 },
    metalness: { value: 1, min: 0, max: 1 }
  })

  let items = []
  obj.traverse((it) => {
    if (it.isMesh) {
      if (it.name !== diamondName) {
        items.push(
          <group key={it.uuid}>
            {createPortal(
              <meshStandardMaterial
                envMap={computeEnvMapMetal}
                color={config.metalColor}
                roughness={config.roughness}
                metalness={config.metalness}
              />,
              it
            )}
          </group>
        )
      }
    }
  })

  useRender.setState({ meshes: [obj.getObjectByName(diamondName)] })
  return (
    <>
      <group {...props}>
        <>
          <CubeCamera resolution={64} position={[0, 0.05, 0]} envMap={computeEnvMapGlass} frames={Infinity}>
            {(texture) => {
              // texture.mapping = EquirectangularReflectionMapping
              // texture.needsPMREMUpdate = true
              return (
                <>
                  {createPortal(
                    <MeshRefractionMaterial key={`yoyo1diamond`} envMap={computeEnvMapGlass} {...config} toneMapped={false} />,
                    obj.getObjectByName(diamondName)
                  )}

                  {/* {createPortal(<meshStandardMaterial roughness={0} metalness={1} />, obj.getObjectByName('Ring'))} */}
                  {/* {createPortal(<meshStandardMaterial roughness={0} metalness={1} />, obj.getObjectByName('Ring'))} */}
                  {items}
                </>
              )
            }}
          </CubeCamera>
        </>
        {/* {createPortal(<MeshTransmissionMaterial transmission={1} envMap={brown}></MeshTransmissionMaterial>, obj.getObjectByName('Ring'))} */}

        <group scale={1}>
          <primitive object={obj}></primitive>
        </group>
      </group>
    </>
  )
}
function Cam() {
  let camera = useThree((r) => r.camera)
  camera.near = 0.05
  camera.far = 50
  camera.updateProjectionMatrix()

  return (
    <group>
      {/*  */}

      {/*  */}
    </group>
  )
}

function Picker() {
  let timeBox = { value: 0 }
  useFrame((st, dt) => {
    timeBox.value += dt
  })

  let { envMap: computeEnvMapGlass } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    // vec4 hdrTextureC4 = texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout1 = pow(pattern(uv.xy * 25.0 + 0.01, 3.0 * uv.y), 1.0);
    float pLayout2 = pow(pattern(uv.xy * 25.0 + 0.0, 3.0 * uv.y), 1.0);
    float pLayout3 = pow(pattern(uv.xy * 25.0 - 0.01, 3.0 * uv.y), 1.0);

    outColor.rgb = vec3(
      0.05 + pLayout1,
      0.05 + pLayout2,
      0.05 + pLayout3
    );

    outColor *= 1.5;

    return outColor;
  }

  `,
    { time: timeBox, envLightIntensity: { value: 1 } },
    256,
    true
  )

  let { envMap: computeEnvMapMetal } = useComputeEnvMap(
    `

  const mat2 m = mat2( 0.80,  0.60, -0.60,  0.80 );

  float noise( in vec2 p ) {
    return sin(p.x)*sin(p.y);
  }

  float fbm4( vec2 p ) {
      float f = 0.0;
      f += 0.5000 * noise( p ); p = m * p * 2.02;
      f += 0.2500 * noise( p ); p = m * p * 2.03;
      f += 0.1250 * noise( p ); p = m * p * 2.01;
      f += 0.0625 * noise( p );
      return f / 0.9375;
  }

  float fbm6( vec2 p ) {
      float f = 0.0;
      f += 0.500000*(0.5 + 0.5 * noise( p )); p = m*p*2.02;
      f += 0.250000*(0.5 + 0.5 * noise( p )); p = m*p*2.03;
      f += 0.125000*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.062500*(0.5 + 0.5 * noise( p )); p = m*p*2.04;
      f += 0.031250*(0.5 + 0.5 * noise( p )); p = m*p*2.01;
      f += 0.015625*(0.5 + 0.5 * noise( p ));
      return f/0.96875;
  }

  float pattern (vec2 p, float time) {
    float vout = fbm4( p + time + fbm6(  p + fbm4( p + time )) );
    return abs(vout);
  }

  uniform sampler2D hdrTexture;
  uniform float envLightIntensity;
  varying vec3 vWorldDirection;
  varying vec3 vPos;
  #define RECIPROCAL_PI 0.31830988618
  #define RECIPROCAL_PI2 0.15915494

  uniform float time;
  uniform float rotY;

  mat3 rotateY(float rad) {
      float c = cos(rad);
      float s = sin(rad);
      return mat3(
          c, 0.0, -s,
          0.0, 1.0, 0.0,
          s, 0.0, c
      );
  }

  vec4 mainImage ()  {
    vec3 direction = normalize( vWorldDirection * rotateY(rotY));
    vec2 uv;
    uv.y = asin( clamp( direction.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
    uv.x = atan( direction.z, direction.x ) * RECIPROCAL_PI2 + 0.5;

    // vec4 hdrTextureC4 = texture2D(hdrTexture, uv);

    vec4 outColor;
    outColor.a = 1.0;

    float pLayout1 = pow(pattern(direction.xz * 2.3, 0.1), 2.0);
    float pLayout2 = pow(pattern(direction.xz * 2.3, 0.1), 2.0);
    float pLayout3 = pow(pattern(direction.xz * 2.3, 0.1), 2.0);

    outColor.rgb = vec3(
      0.1 + 3.6 * pLayout1,
      0.1 + 3.6 * pLayout2,
      0.1 + 3.6 * pLayout3
    );


    return outColor;
  }

  `,
    { time: timeBox, envLightIntensity: { value: 1 } },
    256,
    false
  )

  let { ring } = useTweaks(
    'choose-ring',
    {
      ring: {
        value: `/ring/01257R(27-7-2023).obj`,
        options: {
          [`01257R(27-7-2023)`]: `/ring/01257R(27-7-2023).obj`,
          [`79426R(27-7-2023)`]: `/ring/79426R(27-7-2023).obj`,
          [`90147R(27-7-2023)`]: `/ring/90147R(27-7-2023).obj`,
          [`90257R(27-7-2023)`]: `/ring/90257R(27-7-2023).obj`
          // none: '',
          // dark: 'dark-theme.json',
          // light: 'light-theme.json'
        }
      }
    },
    {
      // container: document.querySelector('#tweak')
    }
  )

  // let { urls } = useTweak('', {
  //   urls: [`12321321`]
  // })

  // console.log(urls)
  /*
  `/ring/01257R(27-7-2023).obj`
  `/ring/79426R(27-7-2023).obj`
  `/ring/90147R(27-7-2023).obj`
  `/ring/90257R(27-7-2023).obj`
  */

  return (
    <>
      <Suspense fallback={null}>
        <Diamond
          key={ring}
          computeEnvMapGlass={computeEnvMapGlass}
          computeEnvMapMetal={computeEnvMapMetal}
          url={ring}
          rotation={[3.141592 * 0.0, 0, 0]}
          position={[0, -0.175 + 0.5, 0]}
          scale={20}
        />
      </Suspense>
    </>
  )
}
export default function AppV2() {
  return (
    <>
      <Canvas shadows camera={{ fov: 45, near: 0.1, far: 50 }} gl={{ outputColorSpace: SRGBColorSpace }}>
        <Cam></Cam>
        <Picker></Picker>
        <color attach="background" args={['#f0f0f0']} />

        {/*  */}
        <ambientLight intensity={0.5} />
        <spotLight position={[5, 5, -10]} angle={0.15} penumbra={1} />
        <pointLight intensity={5} position={[-3, 3, -3]} />
        <pointLight intensity={5} position={[-3, 3, 3]} />

        {/* <Caustics color="#FF8F20" position={[0, -0.5, 0]} lightSource={[5, 5, -10]} worldRadius={0.01} ior={1.2} intensity={0.005}>
        <mesh castShadow receiveShadow position={[-2, 0.5, -1]} scale={0.5}>
          <sphereGeometry args={[1, 64, 64]} />
          <MeshTransmissionMaterial resolution={1024} distortion={0.25} color="#FF8F20" thickness={1} anisotropy={1} />
        </mesh>
      </Caustics> */}
        {/* <mesh castShadow receiveShadow position={[1.75, 0.25, 1]} scale={0.75}>
        <sphereGeometry args={[1, 64, 64]} />
        <meshStandardMaterial color="hotpink" />
      </mesh> */}

        {/* <AccumulativeShadows
          temporal
          frames={100}
          color="orange"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.8}
          opacity={1}
          scale={12}
          position={[0, 15, 0]}>
          <RandomizedLight amount={8} radius={10} ambient={0.5} intensity={1} position={[5, 5, -5]} bias={0.001} />
        </AccumulativeShadows> */}
        {/* <Environment files="https://d.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" /> */}

        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.01}
          object-position={[0, 1.3, 1]}
          target={[0, 0.7, 0]}
          maxPolarAngle={Math.PI / 2}
        />
        <PP></PP>
      </Canvas>
      <div id="tweak"></div>
    </>
  )
}

function PP() {
  let { glowDetect, intensity, smoothGlow } = useTweaks(
    'flare',
    {
      smoothGlow: true,
      glowDetect: { value: 0.9, min: 0, max: 1 },
      intensity: { value: 2.5, min: 0, max: 15 }
    },

    {
      // container: document.querySelector('#tweak')
    }
  )

  let lights = useRender((r) => r.lights)
  let meshes = useRender((r) => r.meshes)
  return (
    <EffectComposer disableNormalPass>
      <SelectiveBloom
        lights={lights}
        selection={meshes}
        selectionLayer={10}
        luminanceThreshold={glowDetect}
        intensity={intensity}
        mipmapBlur={smoothGlow}
      />
    </EffectComposer>
  )
}
